import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonPage,
  IonRow,
  IonTitle,
  useIonAlert,
  useIonLoading,
  useIonRouter,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { EnvContext } from "../../lib/envcontext";
import GenericBackToolbar from "../../components/sitewide/GenericBackToolbar";
import Captcha from "../../components/sitewide/Captcha";
import { NavBarVisibilityContext } from "../../lib/navbarvisibilitycontext";
import { UserContext } from "../../lib/usercontext";
import axios from "axios";
import { useParams } from "react-router";
interface errors {
  email: boolean;
  username: boolean;
  password: boolean;
}
const SignUp = () => {
  const [present, dismiss] = useIonLoading();
  const { id } = useParams<{ id: string }>();
  const [show] = useIonAlert();
  const router = useIonRouter();
  const { environment } = useContext(EnvContext);
  const [errors, setErrors] = useState({} as errors);
  const [email, setEmail] = useState("");
  const [displayname, setDisplayname] = useState("");
  const [password, setPassword] = useState("");
  const [captcha, setCapcha] = useState("");
  const [captchaKey, setCaptchaKey] = useState(0);
  const { setNavbarVisibility } = useContext(NavBarVisibilityContext);
  const { setUser } = useContext(UserContext);
  useEffect(() => {
    fetch(`${environment.backendURL}/auth/authdetails`, {
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.user !== false) {
          router.push("/Home", "root");
        }
      });
  }, []);

  useEffect(() => {
    fetch(`${environment.backendURL}/auth/validateEmail/${id}`, {
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.email) {
          setEmail(data.email);
        } else {
          show({
            backdropDismiss: false,
            message: "Invalid email verification link",

            buttons: [
              {
                text: "Ok",
                handler: () => {
                  router.push("/login", "root");
                },
              },
            ],
          });
        }
      });
  }, []);
  useIonViewWillEnter(() => {
    setNavbarVisibility(false);
  }, [setNavbarVisibility]);
  useIonViewWillLeave(() => {
    setNavbarVisibility(true);
  }, [setNavbarVisibility]);
  return (
    <IonPage>
      <IonContent fullscreen>
        <GenericBackToolbar name="Sign up" />
        <div className="wrapper">
          <IonCard className="loginCard">
            <IonCardHeader>
              <IonRow>
                <IonTitle>Renos & Rebuilds</IonTitle>
              </IonRow>
            </IonCardHeader>
            <IonCardContent>
              <IonGrid>
                <IonRow>
                  <IonItem>
                    {/* <IonInput
                      label="Email"
                      debounce={500}
                      labelPlacement="floating"
                      type="email"
                      autocomplete="email"
                      name="email"
                      id="email"
                      className={`${!errors.email && "ion-valid"} ${
                        errors.email && "ion-invalid"
                      } ${"ion-touched"}`}
                      errorText="check the formatting"
                      onIonChange={(e: any) => {
                        if (
                          e.detail.value.match(
                            /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
                          ) ||
                          e.detail.value === ""
                        ) {
                          setErrors({ ...errors, email: false });
                          setEmail(e.detail.value.toLowerCase());
                        } else {
                          setErrors({ ...errors, email: true });
                          setEmail("");
                        }
                      }}
                    />{" "} */}
                    <h1>{email}</h1>
                  </IonItem>
                </IonRow>

                <IonRow>
                  <IonItem>
                    <IonInput
                      label="Username"
                      debounce={500}
                      labelPlacement="floating"
                      name="displayname"
                      autocomplete="username"
                      id="username"
                      className={`${!errors.username && "ion-valid"} ${
                        errors.username && "ion-invalid"
                      } ${"ion-touched"}`}
                      errorText="Requires > 5 characters and no special
                      characters or spaces 
                      "
                      onIonChange={(e: any) => {
                        if (
                          e.detail.value.match(
                            //Must have more than 5 characters and less than 100 and have no special characters or spaces other than . -
                            /^[a-zA-Z0-9._-]{6,99}$/
                          ) ||
                          e.detail.value === ""
                        ) {
                          setErrors({ ...errors, username: false });
                          setDisplayname(e.detail.value.toLowerCase());
                        } else {
                          setErrors({ ...errors, username: true });
                          setDisplayname("");
                        }
                      }}
                    />{" "}
                  </IonItem>
                </IonRow>
                <IonRow>
                  <IonItem>
                    <IonInput
                      style={{
                        maxWidth: "20em",
                      }}
                      label="Password"
                      debounce={500}
                      labelPlacement="floating"
                      autocomplete="new-password"
                      className={`${!errors.password && "ion-valid"} ${
                        errors.password && "ion-invalid"
                      } ${"ion-touched"}`}
                      errorText="Your password must be at least 8 characters"
                      type="password"
                      name="password"
                      onIonChange={(e: any) => {
                        if (
                          e.detail.value.match(/.{8,}/) ||
                          e.detail.value === ""
                        ) {
                          setErrors({ ...errors, password: false });
                          setPassword(e.detail.value);
                        } else {
                          setErrors({ ...errors, password: true });
                          setPassword("");
                        }
                      }}
                    />{" "}
                  </IonItem>
                </IonRow>
                <IonRow>
                  <IonItem>
                    <Captcha
                      key={captchaKey}
                      onsuccess={(token: string) => {
                        setCapcha(token);
                      }}
                    />
                  </IonItem>
                </IonRow>

                <IonRow>
                  <IonButton
                    style={{ width: "100%" }}
                    expand="block"
                    disabled={
                      email === "" ||
                      displayname === "" ||
                      displayname?.includes(" ") ||
                      captcha === "" ||
                      password === ""
                    }
                    type="submit"
                    onClick={() => {
                      present("Signing up...");
                      axios({
                        method: "POST",
                        data: {
                          email: email,
                          password: password,
                          displayname: displayname,
                          captcha: captcha,
                        },
                        withCredentials: true,
                        url: `
                        ${environment.backendURL}/auth/register
                        `,
                      })
                        .then((res) => res.data)
                        .then(() => {
                          setCapcha("");
                          setCaptchaKey(Math.random());
                          dismiss();
                          axios({
                            method: "POST",
                            data: {
                              email: email,
                              password: password,
                            },
                            withCredentials: true,
                            url: `${environment.backendURL}/auth/login`,
                          })
                            .then((data) => {
                              if (data.data.errors) {
                                show({
                                  message: `Error: ${data}`,
                                  buttons: ["OK"],
                                });
                                return;
                              }
                              setUser({
                                user: {
                                  displayname: data.data.displayname,
                                  email: data.data.email,
                                  photo: `${
                                    environment.storageURL
                                  }/photos/profile/${
                                    data?.data.email
                                  }.png?${Date.now()}`,
                                  type: "person",
                                  admin: false,
                                },
                              });
                              router.push("/Home");
                            })
                            .catch((err) => {
                              console.log(err);
                              show({
                                message: `Error: ${err}`,
                                buttons: ["OK"],
                              });
                            });
                        })
                        .catch((err) => {
                          dismiss();
                          show(
                            "Error signing up " + err?.response?.data?.errors
                              ? JSON.stringify(
                                  err?.response?.data?.errors.map(
                                    (error: any) => {
                                      console.log(error.msg);
                                      return error.msg;
                                    }
                                  )
                                )
                              : err.message
                          );
                          console.log(err);
                          setCapcha("");
                          setCaptchaKey(Math.random());
                        });
                    }}
                  >
                    Sign Up
                  </IonButton>
                </IonRow>
              </IonGrid>
            </IonCardContent>
          </IonCard>
          <IonCard className="loginCard">
            <p>Allready have an account?</p>
            <IonButton
              size="default"
              fill="clear"
              onClick={() => {
                router.push("/Authenticate");
              }}
            >
              <b>Sign In</b>
            </IonButton>
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default SignUp;
