import { IonCard } from "@ionic/react";
import { useState } from "react";
import TagSelector from "../TagSelector";
import "../../../theme/standard.css";

const SearchTag: React.FC = () => {
  const [tag, setTag] = useState([] as string[]);
  return (
    <IonCard
      className="mobileResize"
      style={{
        height: "60vh",
      }}
    >
      <TagSelector
        single={true}
        tags={tag}
        setTags={setTag}
        activeOnly={false}
      />
    </IonCard>
  );
};

export default SearchTag;
