import { IonTextarea } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { EnvContext } from "../../lib/envcontext";
import { Participant } from "../../lib/types";
import UserItem from "./UserItem";

interface SmartTextFieldProps {
  text: string;
  setText: (text: string) => void;
  setError?: (error: boolean) => void;
  rules?: RegExp;
  debounce?: number;
  placeholder?: string;
  name?: string;
  errorText?: string;
  error?: boolean;
}

const SmartTextField: React.FC<SmartTextFieldProps> = ({
  text,
  setText,
  setError,
  debounce,
  placeholder,
  name,
  errorText,
  error,
}) => {
  const [username, setUsername] = useState<string>("");
  const { environment } = useContext(EnvContext);
  const [searchRes, setSearchRes] = useState<Array<Participant>>();

  useEffect(() => {
    if (username.length > 1) {
      fetch(`${environment.backendURL}/open/user/search/${username}`, {
        credentials: "include",
      })
        .then((response) => response.json())
        .then((data) => {
          setSearchRes(data);
        });
    }
  }, [username]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <IonTextarea
        style={{
          minWidth: "30vw",
          maxHeight: "20vh",
          textAlign: "left",
        }}
        label={placeholder}
        labelPlacement="floating"
        value={text}
        errorText={errorText}
        name={name}
        debounce={debounce ? 500 : 0}
        className={`${!error && "ion-valid"} ${
          error && "ion-invalid"
        } ${"ion-touched"}`}
        onIonInput={(e: any) => {
          if (setError) {
            if (/^(?=.{4,999}$)([\s\S]*)|^$/.test(e.detail.value)) {
              setError(false);
              setText(e.detail.value!);
            } else {
              setError(true);
              setText(e.detail.value!);
            }
          } else {
            setText(e.detail.value!);
          }
          const matches = e.detail.value!.match(/@\S+$/);
          if (matches) {
            setUsername(matches[matches.length - 1].slice(1));
          } else {
            setUsername("");
            setSearchRes([]);
          }
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          maxHeight: "20vh",
          overflowY: "scroll",
        }}
      >
        {searchRes && searchRes.length > 0 && username.length > 1 ? (
          searchRes.map((user: Participant, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  console.log(user);
                  setText(text.replace("@" + username, "@" + user.displayname));
                  setUsername("");
                  setSearchRes([]);
                }}
              >
                <UserItem email={user.email} key={index} link={false} />
              </div>
            );
          })
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default SmartTextField;
