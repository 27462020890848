import {
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonChip,
  IonImg,
  IonList,
  IonText,
  useIonRouter,
} from "@ionic/react";
import React, { useContext, useEffect } from "react";
import { EnvContext } from "../../lib/envcontext";
import { PostData } from "../../lib/types";
import { retrievePost } from "../../lib/util";

interface PostMinProps {
  post: string;
}

const CampaignMin = ({ post }: PostMinProps) => {
  const router = useIonRouter();
  const { environment } = useContext(EnvContext);
  const [postData, setPostData] = React.useState({} as PostData);
  useEffect(() => {
    retrievePost({ id: post, backendURL: environment.backendURL }).then(
      (data: PostData) => {
        setPostData(data);
      }
    );
  }, [post, environment]);

  return (
    <div>
      <IonCard
        style={{
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          cursor: "pointer",
          width: "20em",
          height: "20em",
          justifyContent: "center",
          alignItems: "center",
        }}
        id={postData?.post?.postID}
        onClick={() => {
          router.push(`/Advertising/ManageCampaigns/${postData?.post?.postID}`);
        }}
      >
        {
          //make sure the post has loaded if so then render the post
          postData?.post?.photo &&
          JSON.stringify(postData?.post?.photo) !== "[]" ? (
            postData?.post?.photo?.[0].includes("mp4") ? (
              <video
                style={{
                  margin: "1rem",
                  maxHeight: "50%",
                  maxWidth: "100%",
                }}
                controls
                autoPlay
                loop
                muted
              >
                <source
                  src={`${environment.storageURL}/photos/${postData?.post?.photo[0]}`}
                  type="video/mp4"
                />
              </video>
            ) : (
              <IonImg
                style={{
                  maxHeight: "50%",
                }}
                src={`${environment.storageURL}/photos/${postData?.post?.photo[0]}`}
              />
            )
          ) : (
            <></>
          )
        }
        {postData?.post?.title ? (
          <>
            <div
              style={{
                maxWidth: "100%",
              }}
            >
              <IonCardContent>
                <IonCardTitle>{postData?.post?.title} </IonCardTitle>

                <IonText>{postData?.post?.description}</IonText>
                <IonList
                  style={{
                    display: "flex",
                    maxWidth: "100%",
                    flexWrap: "wrap",
                  }}
                >
                  {postData?.post?.tags?.map((tag) => {
                    return (
                      <IonChip key={tag}>
                        <IonText>#{tag}</IonText>
                      </IonChip>
                    );
                  })}
                </IonList>
              </IonCardContent>
            </div>
          </>
        ) : (
          <></>
        )}
      </IonCard>
    </div>
  );
};

export default CampaignMin;
