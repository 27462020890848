import {
  IonContent,
  IonPage,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import ExploreContainer from "../../components/sitewide/ExploreContainer";
import { useContext } from "react";
import { NavBarVisibilityContext } from "../../lib/navbarvisibilitycontext";

const Cookies: React.FC = () => {
  const { setNavbarVisibility } = useContext(NavBarVisibilityContext);

  useIonViewWillEnter(() => {
    setNavbarVisibility(false);
  }, [setNavbarVisibility]);
  useIonViewWillLeave(() => {
    setNavbarVisibility(true);
  }, [setNavbarVisibility]);
  return (
    <IonPage>
      <IonContent fullscreen>
        <div
          style={{
            //top padding 1, bottom padding 1, left padding 5, right padding 5
            padding: "1rem 10rem 1rem 10rem",
          }}
        >
          <h1>COOKIE POLICY</h1>

          <p>Last updated: 22nd March 2022</p>

          <p>
            This Website (referred to in these "terms of use" as the website) is
            owned and operated by Renos&amp;Rebuilds PTY LTD, who is referred to
            in this Cookie Policy as "we", "us", "our" and similar grammatical
            forms.
          </p>

          <p>
            Our Cookie Policy explains what cookies are, how we use cookies, how
            third-party partners may use cookies on our Websites and your
            choices regarding cookies.
          </p>

          <h2>What are cookies?</h2>

          <p>
            General information about visits to our Website is collected by our
            computer servers, with small files "cookies" that our Website
            transfers to your computer's hard drive through your Web browser (if
            you allow the delivery of "cookies").
          </p>

          <p>
            When you access our Website, these small files ("cookies"),
            containing a unique identification (ID) number, may be downloaded by
            your web browser and stored in the cache of your computer. The
            "cookies" that are shared with your computer can't be used to
            discover any personal information such as your name, address or
            email address; they merely identify your computer to our Website
            when you visit us.
          </p>

          <p>
            The anonymous non-personal information that we collect and analyse
            is not personal information as described in The Queensland
            Information Privacy Act 2009.
          </p>

          <h2>How we use cookies</h2>

          <p>
            The "cookies" are used to follow the pattern of movements of users
            by letting us know which pages on our Websites are visited, in what
            order and how often, the previous website visited. They are also
            used to process the items you select if you are making purchases
            from our Website.
          </p>

          <h2>
            Why do we use "cookies" and other web use tracking technologies?
          </h2>

          <p>
            The purpose of sending these files with a unique ID number is so
            that our Website can recognise your computer when you next visit our
            Website.
          </p>

          <p>
            We collect information using "cookies" and other tracking
            technologies for the following reasons:
          </p>

          <ul>
            <li>
              to help us monitor the performance of our Website so that we can
              improve the operation of the Website and the services we offer;
            </li>
            <li>
              to provide personalised services to each user of our Website to
              make their navigation through our Website easier and more
              rewarding to the user;
            </li>
            <li>
              to sell advertising on the Website in order to meet some of the
              costs of operating the Website and improve the content on the
              Website; and
            </li>
            <li>
              when we have permission from the user, to market the services we
              provide by sending emails that are personalised to what we
              understand are the interests of the user.
            </li>
          </ul>

          <p>
            In addition to our own cookies, we may also use various third-party
            cookies to report usage statistics of the Website, deliver
            advertisements on and through the Website, and so on.
          </p>

          <h2>What are your choices regarding cookies?</h2>

          <p>
            If you are unhappy about having a cookie sent to you, you can set
            your browser to refuse cookies or choose to have your computer warn
            you each time a cookie is being sent. However, if you turn your
            cookies off, some of our services may not function properly.
          </p>

          <p>
            Even if you have given us permission to send you emails, you can, at
            any time, decide not to receive further emails and will be able to
            "unsubscribe" from that service.
          </p>
        </div>{" "}
      </IonContent>
    </IonPage>
  );
};

export default Cookies;
