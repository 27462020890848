import React, { useContext, useEffect } from "react";
import { UserContext } from "../../lib/usercontext";
import { EnvContext } from "../../lib/envcontext";
import { UserContextInterface } from "../../lib/types";

interface component {
  component: Element | JSX.Element;
}

const PrivateRoute = ({ component }: component) => {
  const { setUser } = useContext(UserContext);
  const { environment } = useContext(EnvContext);
  const [render, setRender] = React.useState(false);
  useEffect(() => {
    fetch(`${environment.backendURL}/auth/authdetails`, {
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.user === false) {
          setUser({} as UserContextInterface);
          setRender(false);
          window.location.href = "/login";
        } else {
          setUser({
            user: {
              displayname: data.user.displayname,
              email: data.user.email,
              photo: `${environment.storageURL}/photos/profile/${
                data?.user.email
              }.png?${Date.now()}`,
              type: data.user.type,
              admin: data.user.admin,
            },
          });
          setRender(true);
        }
      });
  }, []);
  return <>{render ? component : <></>}</>;
};

export default PrivateRoute;
