import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCol,
  IonGrid,
  IonIcon,
  IonProgressBar,
  IonRow,
  IonSpinner,
  IonText,
  IonToolbar,
  useIonAlert,
  useIonRouter,
} from "@ionic/react";
import {
  closeSharp,
  heartSharp,
  arrowRedoSharp,
  bookmarkSharp,
} from "ionicons/icons";
import { useContext, useEffect, useState } from "react";
import { EnvContext } from "../../lib/envcontext";

import { UserContext } from "../../lib/usercontext";
import RichComment from "./RichComment";
import { SavedPostContext } from "../../lib/savedpostcontext";
import { CommentData, repliedtoCommentData, replyingTo } from "../../lib/types";
import SmartTextField from "../sitewide/SmartTextField";
import { useLocation } from "react-router";
import PhotoUpload from "../sitewide/PhotoUpload";
import axios from "axios";
import { retrievePost } from "../../lib/util";

interface PostInteractionProps {
  postID: string;
}

const PostInteractions = ({ postID }: PostInteractionProps) => {
  const location = useLocation();
  const { user } = useContext(UserContext);
  const [liked, setLiked] = useState(false);
  const [comments, setComments] = useState([]);
  const { environment } = useContext(EnvContext);
  const [replyingTo, setReplyingTo] = useState({} as replyingTo);
  const [repliedtoCommentData, setRepliedtoCommentData] = useState(
    {} as repliedtoCommentData
  );
  const [showComments, setShowComments] = useState(false);
  const [textInput, setTextInput] = useState("");
  const [refetch, setRefetch] = useState(false);
  const [commentsMapLength, setCommentsMapLength] = useState(5);
  const { savedPosts, setSavedPosts } = useContext(SavedPostContext);
  const [photo, setPhoto] = useState([]);
  const [progress, setProgress] = useState<number>();
  const [present] = useIonAlert();
  const router = useIonRouter();

  useEffect(() => {
    setComments([]);
    retrievePost({
      id: postID,
      backendURL: environment.backendURL,
    }).then((data) => {
      setLiked(data.likes.includes(user?.user?.email) ? true : false);
      setComments(data.comments);
      if (data.comments.length > 0 && location.pathname === `/post/${postID}`) {
        setShowComments(true);
      }
    });
  }, [refetch, environment, postID, user?.user?.email]);

  return (
    <>
      <IonToolbar color={"light"}>
        <IonButtons>
          <IonButton
            id="like"
            onClick={() => {
              fetch(
                `${
                  environment.backendURL
                }/open/socialmedia/postinteractions/${postID}/${
                  liked ? "unlike" : "like"
                }`,
                {
                  method: "POST",
                  credentials: "include",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    email: user?.user?.email,
                  }),
                }
              ).then(() => {
                setLiked(!liked);
              });
            }}
          >
            <IonIcon
              color={liked ? "primary" : "dark"}
              slot="icon-only"
              icon={heartSharp}
            />
          </IonButton>
          <IonButton
            id="share"
            onClick={() => {
              router.push(`/post/${postID}/share`);
            }}
            color="dark"
          >
            <IonIcon slot="icon-only" icon={arrowRedoSharp} />
          </IonButton>
          <IonButton
            id="save"
            onClick={() => {
              fetch(
                `${
                  environment.backendURL
                }/open/socialmedia/postinteractions/${postID}/${
                  savedPosts.includes(postID) ? "unsave" : "save"
                }`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  credentials: "include",
                  body: JSON.stringify({
                    email: user?.user?.email,
                  }),
                }
              ).then(() => {
                if (savedPosts.includes(postID)) {
                  setSavedPosts(
                    savedPosts.filter((post: string) => {
                      return post !== postID;
                    })
                  );
                } else {
                  setSavedPosts([...savedPosts, postID]);
                }
              });
            }}
            color="dark"
          >
            <IonIcon
              color={savedPosts.includes(postID) ? "primary" : "dark"}
              slot="icon-only"
              icon={bookmarkSharp}
            />
          </IonButton>
        </IonButtons>
      </IonToolbar>
      <IonCardHeader></IonCardHeader>

      <IonCardContent>
        <IonCard>
          <IonGrid>
            <IonRow class="ion-align-items-center ion-justify-content-start">
              <IonCol>
                {replyingTo.email ? (
                  <IonText
                    style={{
                      fontSize: "0.7em",
                    }}
                  >
                    {"Replying to @" + replyingTo?.email}{" "}
                    <IonButton
                      fill="clear"
                      onClick={() => {
                        setReplyingTo({} as replyingTo);
                      }}
                    >
                      <IonIcon icon={closeSharp} slot="icon-only" />
                    </IonButton>
                  </IonText>
                ) : null}
                <SmartTextField
                  name="leaveComment"
                  placeholder="Leave a comment"
                  text={textInput}
                  setText={setTextInput}
                />
              </IonCol>

              {(textInput?.length > 0 || photo?.length > 0) && (
                <IonButton
                  onClick={() => {
                    const formData = new FormData();
                    formData.append("email", user?.user?.email);
                    formData.append("message", textInput);
                    formData.append(
                      "targetpostID",
                      replyingTo?.postID ? replyingTo.postID : postID
                    );
                    photo.forEach((photo) => {
                      formData.append("sampleFile", photo);
                    });
                    axios
                      .post(
                        `${environment.backendURL}/open/socialmedia/publishcomment`,
                        formData,
                        {
                          withCredentials: true,
                          headers: {
                            "Content-Type": "multipart/form-data",
                          },

                          onUploadProgress: (progressEvent: any) => {
                            setProgress(
                              Math.round(
                                (progressEvent.loaded / progressEvent.total) *
                                  100
                              )
                            );
                          },
                        }
                      )
                      .then((data) => {
                        setPhoto([]);
                        setProgress(undefined);
                        setTextInput("");
                        setReplyingTo({} as replyingTo);
                        if (!data.data.errors) {
                          setShowComments(true);
                          //if replying to main post, refetch to update likes
                          if (!replyingTo?.postID) {
                            console.log("refetching");
                            setRefetch(!refetch);
                            setRepliedtoCommentData({} as repliedtoCommentData);
                          } else {
                            console.log("not refetching", replyingTo);
                            setRepliedtoCommentData({
                              parentID: replyingTo?.postID,
                              newID: data.data.commentID,
                            });
                          }
                        }
                      })
                      .catch((err) => {
                        console.log(err.response);
                        present({
                          message: `Error: ${err}}`,
                          buttons: [
                            {
                              text: "close",
                            },
                          ],
                        });
                      });
                  }}
                >
                  Post
                </IonButton>
              )}
            </IonRow>

            <IonRow>
              <PhotoUpload
                photo={photo}
                setPhoto={setPhoto}
                mode="anyMedia"
                multiple={true}
                iconOnly={true}
              />
            </IonRow>
            {progress && (
              <>
                <br />
                <IonProgressBar value={progress / 100}></IonProgressBar>
              </>
            )}
          </IonGrid>
        </IonCard>
        {/*comments */}
        <IonButton
          id={showComments ? "hideReplies" : "showReplies"}
          color={"dark"}
          size={"small"}
          style={{
            fontSize: "0.7em",
            fontWeight: "normal",
          }}
          fill="clear"
          onClick={() => {
            setShowComments(!showComments);
            setReplyingTo({} as replyingTo);
          }}
        >
          {comments?.length > 0 &&
            (showComments ? "hide replies" : "show replies")}
        </IonButton>
        {showComments && comments !== undefined ? (
          <>
            {comments.length === 0 ? (
              <IonSpinner name="dots"></IonSpinner>
            ) : (
              <>
                {comments
                  .slice(0, commentsMapLength)
                  .map((comment: CommentData, index: number) => {
                    return (
                      <RichComment
                        key={index}
                        comment={comment}
                        repliedtoCommentData={repliedtoCommentData}
                        setReplyingTo={setReplyingTo}
                        children={comment.children}
                      />
                    );
                  })}
                {comments.length > commentsMapLength ? (
                  <IonButton
                    color={"dark"}
                    size={"small"}
                    style={{
                      fontSize: "0.7em",
                      fontWeight: "normal",
                    }}
                    fill="clear"
                    onClick={() => {
                      setCommentsMapLength(commentsMapLength + 5);
                    }}
                  >
                    show more
                  </IonButton>
                ) : null}
              </>
            )}
          </>
        ) : (
          <></>
        )}
      </IonCardContent>
    </>
  );
};

export default PostInteractions;
