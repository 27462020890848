import {
  IonAvatar,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonMenu,
  IonMenuToggle,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonSplitPane,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import { filterSharp } from "ionicons/icons";
import { useContext, useEffect, useRef, useState } from "react";
import "../../theme/standard.css";
import { EnvContext } from "../../lib/envcontext";
import { JobProfileInterface } from "../../lib/types";
import LocationSearch from "../../components/sitewide/LocationSearch";
import { location } from "../../lib/types";
import { ToolBarVisibilityContext } from "../../lib/toolbarvisibilitycontext";
const JobBoard: React.FC = () => {
  const [filterButtonVisible, setFilterButtonVisible] = useState(false);
  const { environment } = useContext(EnvContext);
  const textSearch = useRef<string>("");
  const [location, setLocation] = useState({} as location);
  const [distance, setDistance] = useState(50);
  const [profiles, setProfiles] = useState<JobProfileInterface[]>([]);
  const { setToolbarVisibility } = useContext(ToolBarVisibilityContext);
  useIonViewWillEnter(() => {
    setToolbarVisibility(true);
  });
  useIonViewWillLeave(() => {
    setToolbarVisibility(false);
  });
  useEffect(() => {
    fetch(`${environment.backendURL}/open//jobs/searchJobProfiles`, {
      credentials: "include",
      method: "POST",
    })
      .then((res) => res.json())
      .then((data) => {
        setProfiles(data);
      });
  }, [environment.backendURL]);

  return (
    <IonPage
      style={{
        marginTop: "9vh",
      }}
    >
      <IonContent fullscreen>
        <IonSplitPane
          onIonSplitPaneVisible={
            (e) => setFilterButtonVisible(!e.detail.visible) // This is the line that makes the filter button visible
          }
          when="lg"
          contentId="main"
        >
          <IonMenu contentId="main">
            <IonHeader
              style={{
                height: "7em",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "var(--ion-color-light-shade)",
                textAlign: "center",
              }}
            >
              <svg
                width="100%"
                height="9vh"
                viewBox="0 0 532 298"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M211 40V31L306.75 31C377.501 31 377.999 131 306.75 131H280L356 231H332.064L256 131.5L256 107H301.645C347.612 107 347.291 55 301.645 55L236 55V231H211V71.5L180 31L149 71.5V231H124V55L58.3546 55C12.7093 55 12.3879 107 58.3546 107H104L104 131.5L27.9357 231H4L80 131H53.2496C-17.9988 131 -17.5005 31 53.2496 31L149 31V40L156 31L180 0L204 31L211 40Z"
                  fill="#F4F5F8"
                />
                <path d="M163 98.8929H176.852V73H163V98.8929Z" fill="#F4F5F8" />
                <path d="M197 73H183.148V98.8929H197V73Z" fill="#F4F5F8" />
                <path
                  d="M163 104.071H176.852V131H163V104.071Z"
                  fill="#F4F5F8"
                />
                <path
                  d="M183.148 131V104.071H197V131H183.148Z"
                  fill="#F4F5F8"
                />
              </svg>
            </IonHeader>
            <IonContent className="ion-padding">
              <IonRow>
                <IonItem
                  style={{
                    width: "100%",
                  }}
                >
                  <IonInput
                    label="Text Search"
                    onIonChange={(e) => {
                      textSearch.current = e.detail.value!.toString();
                    }}
                    labelPlacement="floating"
                    helperText="
                      This searches everything on a tradie's profile, including their name, description, and skills and education.
                    "
                  ></IonInput>
                </IonItem>
              </IonRow>
              <br />
              <LocationSearch location={location} setLocation={setLocation} />
              <br />
              {location.lat ? (
                <IonItem>
                  {/* <IonRange
                  value={distance}
                  onIonChange={(e) => setDistance(e.detail.value as number)}
                  min={0}
                  max={500}
                /> */}
                  <IonSelect
                    style={{
                      overflow: "hidden",
                    }}
                    value={distance}
                    onIonChange={(e) => setDistance(e.detail.value as any)}
                  >
                    <IonSelectOption value={30}>30km</IonSelectOption>
                    <IonSelectOption value={50}>50km</IonSelectOption>
                    <IonSelectOption value={100}>100km</IonSelectOption>
                    <IonSelectOption value={250}>250km</IonSelectOption>
                  </IonSelect>
                  {/* <IonLabel slot="start">Distance</IonLabel> */}

                  {/* <IonLabel slot="end">{distance}km</IonLabel> */}
                </IonItem>
              ) : null}

              <br />
              <IonRow>
                <IonButton
                  onClick={() => {
                    const body: { [key: string]: any } = {};
                    if (textSearch.current) {
                      body["searchString"] = textSearch.current;
                    }
                    if (location.lat) {
                      body["lat"] = location.lat;
                      body["lon"] = location.lon;
                      body["radius"] = distance;
                    }
                    fetch(
                      `${environment.backendURL}/open/jobs/searchJobProfiles`,
                      {
                        method: "POST",
                        credentials: "include",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify(body),
                      }
                    )
                      .then((res) => res.json())
                      .then((data) => {
                        console.log(data);
                        setProfiles(data);
                      });
                  }}
                  color="primary"
                  expand="block"
                >
                  Search
                </IonButton>
                <IonButton
                  onClick={() => {
                    setLocation({} as location);
                    setDistance(50);

                    fetch(
                      `${environment.backendURL}/open/jobs/searchJobProfiles`,
                      {
                        method: "POST",
                        credentials: "include",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify({}),
                      }
                    )
                      .then((res) => res.json())
                      .then((data) => {
                        setProfiles(data);
                      });
                  }}
                  color="light"
                  expand="block"
                >
                  Reset
                </IonButton>
              </IonRow>
            </IonContent>
          </IonMenu>

          <div
            style={{
              marginBottom: "3em",
              overflowY: "scroll",
              height: "100%",
            }}
            id="main"
          >
            {filterButtonVisible ? (
              <IonHeader>
                <IonToolbar>
                  <IonTitle
                    style={{
                      maxWidth: "80%",
                    }}
                  >
                    Renos Jobs
                  </IonTitle>
                  <IonMenuToggle slot="end">
                    <IonButton color={"dark"} fill="clear">
                      <IonIcon icon={filterSharp}></IonIcon>
                    </IonButton>
                  </IonMenuToggle>
                </IonToolbar>
              </IonHeader>
            ) : (
              <></>
            )}
            <IonHeader
              style={{
                backgroundColor: "var(--ion-color-light-tint)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "1em",
              }}
            >
              <h1
                style={{
                  textAlign: "center",
                }}
              >
                The Reverse Job Board for tradesmen
              </h1>
              <p
                style={{
                  textAlign: "center",
                  width: "80%",
                }}
              >
                Renos Jobs is a platform designed specifically for skilled
                tradesmen like you, providing you with the power to find your
                next gig effortlessly. Say goodbye to endlessly searching job
                boards and waiting for opportunities to come your way.
              </p>

              <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonButton
                      href="
                    /JobBoard/createprofile"
                      color="primary"
                      expand="block"
                    >
                      Put Yourself Out There
                    </IonButton>
                  </IonCol>
                  <IonCol>
                    <IonButton
                      color="secondary"
                      onClick={() => {
                        alert(
                          "Coming Soon!!: Intructions for employers once we figure out how people are going to pay for this"
                        );
                      }}
                      expand="block"
                    >
                      I'm Hiring
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonHeader>
            <div
              style={{
                padding: "1em",
              }}
            >
              <IonHeader>Explore Tradies on Renos</IonHeader>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "1em",
                }}
              >
                {/* <IonItem
                  button
                  onClick={() => {
                    router.push("/JobBoard/profile/aAS3213");
                  }}
                >
                  <IonAvatar
                    style={{
                      height: "7em",
                      width: "7em",
                    }}
                    slot="start"
                  >
                    <img src="https://picsum.photos/150/150" />
                  </IonAvatar>
                  <IonLabel>
                    <h2>John Smith</h2>
                    <h3>Plumber</h3>
                    <h3>Sydney, NSW</h3>
                    <br />
                    <p
                      style={{
                        whiteSpace: "normal",
                        overflowWrap: "break-word",
                        color: "var(--ion-color-dark)",
                      }}
                    >
                      {" "}
                      Hi, I'm John. I'm a plumber with 10 years experience, and
                      I'm looking for my next gig. I'm available to start
                      immediately. If you're looking for a plumber, please get
                      in touch.
                    </p>
                  </IonLabel>
                </IonItem> */}
                {
                  //map through all the profiles
                  profiles.length > 0 ? (
                    profiles.map(
                      (profile: JobProfileInterface, index: number) => {
                        return (
                          <IonItem
                            key={index}
                            button
                            href={`/JobBoard/profile/${profile.jobprofileid}`}
                          >
                            <IonAvatar
                              style={{
                                height: "7em",
                                width: "7em",
                              }}
                              slot="start"
                            >
                              <img
                                src={`${
                                  environment.storageURL
                                }/photos/profile/${
                                  profile.ownerEmail
                                }.png?${Date.now()}`}
                                alt="profile"
                              />
                            </IonAvatar>
                            <IonLabel>
                              <h2>{profile.name}</h2>
                              <h3>{profile.occupation}</h3>
                              <h3>{profile.location}</h3>
                              <br />
                              <p
                                style={{
                                  whiteSpace: "normal",
                                  overflowWrap: "break-word",
                                  color: "var(--ion-color-dark)",
                                }}
                              >
                                {" "}
                                {
                                  //truncate the description if it's too long
                                  profile.about.length > 300
                                    ? profile.about.substring(0, 300) + "..."
                                    : profile.about
                                }
                              </p>
                            </IonLabel>
                          </IonItem>
                        );
                      }
                    )
                  ) : (
                    <IonItem>
                      <IonLabel>
                        <h2>No Profiles Found, try broadening your filters</h2>
                      </IonLabel>
                    </IonItem>
                  )
                }
              </div>
            </div>
            <IonContent className="ion-padding"></IonContent>
          </div>
        </IonSplitPane>{" "}
      </IonContent>
    </IonPage>
  );
};

export default JobBoard;
