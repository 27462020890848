import {
  IonAvatar,
  IonItem,
  IonLabel,
  IonSearchbar,
  useIonViewWillEnter,
} from "@ionic/react";
import { personCircleSharp } from "ionicons/icons";
import { useContext, useState } from "react";
import { EnvContext } from "../../lib/envcontext";
import { Participant } from "../../lib/types";

interface UserSearchProps {
  users: Array<Participant>;
  setUsers: React.Dispatch<React.SetStateAction<Array<Participant>>>;
}

const UserSearch: React.FC<UserSearchProps> = ({ users, setUsers }) => {
  const [searchRes, setSearchRes] = useState<Array<Participant>>();
  const [userSearchQuery, setuserSearchQuery] = useState<string>("");
  const { environment } = useContext(EnvContext);

  useIonViewWillEnter(() => {
    setuserSearchQuery("");
    setSearchRes([]);
  }, [setuserSearchQuery, setSearchRes]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <IonSearchbar
        value={userSearchQuery}
        id="searchbar"
        placeholder="Search..."
        searchIcon={personCircleSharp}
        debounce={500}
        onIonChange={(e) => {
          setuserSearchQuery(e.detail.value!);
          if (e.detail.value !== "") {
            fetch(
              `${environment.backendURL}/open/user/search/${e.detail.value}`,
              {
                credentials: "include",
              }
            )
              .then((response) => response.json())
              .then((data) => {
                setSearchRes(data);
              });
          }
        }}
      ></IonSearchbar>
      {searchRes && searchRes.length > 0 ? (
        searchRes.map((user: Participant, index) => {
          return (
            <IonItem
              style={{ width: "100%" }}
              key={index}
              button
              onClick={() => {
                setuserSearchQuery("");
                if (users) {
                  if (
                    users.filter((r: Participant) => r.email === user.email)
                      .length === 0
                  ) {
                    setUsers([...users, user]);
                  }
                } else {
                  setUsers([user]);
                }
              }}
            >
              <IonAvatar slot="start">
                <img
                  src={`${environment.storageURL}/photos/profile/${user.email}.png`}
                  alt="avatar"
                />
              </IonAvatar>
              <IonLabel>
                <h2>{user.displayname}</h2>
              </IonLabel>
            </IonItem>
          );
        })
      ) : (
        <IonLabel>No results found</IonLabel>
      )}
    </div>
  );
};

export default UserSearch;
