import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonImg,
  IonModal,
  IonToolbar,
} from "@ionic/react";
import { chevronBackSharp } from "ionicons/icons";
import { useState } from "react";

interface MediaZoomProps {
  src: string;
  small?: boolean;
}

const MediaZoom: React.FC<MediaZoomProps> = ({ src, small }) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={() => setShowModal(false)}>
                {" "}
                <IonIcon icon={chevronBackSharp} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        {src.includes("mp4") || src.includes("quicktime") ? (
          <video
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
            controls
            autoPlay
            src={src}
          />
        ) : (
          <IonImg
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
            src={src}
          />
        )}
      </IonModal>

      {src.includes("mp4") || src.includes("quicktime") ? (
        <video
          onClick={() => setShowModal(true)}
          disablePictureInPicture
          autoPlay
          loop
          muted
          style={
            small
              ? {
                  maxWidth: "100%",
                  cursor: "zoom-in",
                  maxHeight: "20em",
                }
              : {
                  marginBottom: "0rem",
                  padding: "0.5rem",
                  maxWidth: "100%",
                  cursor: "zoom-in",
                  maxHeight: "20em",
                }
          }
          src={src}
        />
      ) : (
        <img
          onClick={() => setShowModal(true)}
          style={
            small
              ? {
                  maxWidth: "100%",
                  cursor: "zoom-in",
                }
              : {
                  marginBottom: "0rem",
                  padding: "0.5rem",
                  maxWidth: "100%",
                  cursor: "zoom-in",
                }
          }
          src={src}
        />
      )}
    </>
  );
};

export default MediaZoom;
