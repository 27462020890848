import {
  IonButton,
  IonButtons,
  IonContent,
  IonIcon,
  IonItem,
  IonModal,
  IonText,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";
import { chevronBackSharp } from "ionicons/icons";
import { EnvContext } from "../../../lib/envcontext";
import { useContext } from "react";
import { CommentData } from "../../../lib/types";
interface CommentOwnerModalProps {
  ownerModal: boolean;
  setOwnerModal: React.Dispatch<React.SetStateAction<boolean>>;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  comment: CommentData;
}

const CommentOwnerModal: React.FC<CommentOwnerModalProps> = ({
  ownerModal,
  setOwnerModal,
  setVisible,
  comment,
}) => {
  const [presentAlert] = useIonAlert();
  const { environment } = useContext(EnvContext);
  const [show] = useIonAlert();
  return (
    <IonModal
      onDidDismiss={() => {
        setOwnerModal(false);
      }}
      isOpen={ownerModal}
    >
      <IonToolbar>
        <IonButtons slot="start">
          <IonButton
            onClick={() => {
              setOwnerModal(false);
            }}
          >
            <IonIcon icon={chevronBackSharp} />
          </IonButton>
        </IonButtons>
      </IonToolbar>
      <IonContent className="ion-padding">
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <IonItem
            id="deleteComment"
            button
            onClick={() => {
              presentAlert({
                header: "Delete post",
                message: "Are you sure you want to delete this post?",

                buttons: [
                  {
                    text: "Cancel",
                    role: "cancel",
                    cssClass: "secondary",
                  },

                  {
                    text: "Okay",
                    handler: (data) => {
                      fetch(
                        `${environment.backendURL}/open/socialmedia/deletecomment/${comment.postID}`,
                        {
                          method: "DELETE",
                          credentials: "include",
                        }
                      )
                        .then((res) => res.json())
                        .then((res) => {
                          if (res.errors) {
                            show({
                              message: `ERROR: ${res.errors[0].msg}`,
                              buttons: ["OK"],
                            });
                          }
                          console.log(res);
                          setVisible(false);
                          setOwnerModal(false);
                        });
                    },
                  },
                ],
              });
            }}
          >
            <IonText color={"danger"}>Delete</IonText>
          </IonItem>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default CommentOwnerModal;
