import {
  IonContent,
  IonPage,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import { useContext } from "react";
import { NavBarVisibilityContext } from "../../lib/navbarvisibilitycontext";

const Terms: React.FC = () => {
  const { setNavbarVisibility } = useContext(NavBarVisibilityContext);

  useIonViewWillEnter(() => {
    setNavbarVisibility(false);
  }, [setNavbarVisibility]);
  useIonViewWillLeave(() => {
    setNavbarVisibility(true);
  }, [setNavbarVisibility]);
  return (
    <IonPage>
      <IonContent fullscreen>
        <div
          style={{
            //top padding 1, bottom padding 1, left padding 5, right padding 5
            padding: "1rem 10rem 1rem 10rem",
          }}
        >
          <div>
            <h1>WEBSITE TERMS AND CONDITIONS OF USE</h1>
            <h2>1. About the Website</h2>
            <p>
              Welcome to{" "}
              <a href="http://www.renosandrebuiilds.com">
                www.renosandrebuiilds.com
              </a>{" "}
              (the 'Website'). The Website facilitates interactions between:
            </p>
            <ol>
              <li>Member (the 'Receiver');</li>
              <li>Member (the 'Provider')</li>
            </ol>
            <p>
              enabling the Receiver and the Provider to locate, communicate
              regarding, arrange payment for, and deliver the services in a fast
              and secure manner (the 'Services').
            </p>
            <p>
              The Website is operated by Renos&Rebuilds PTY. LTD. (ACN
              654428412). Access to and use of the Website, or any of its
              associated products or Services, is provided by Renos&Rebuilds.
              Please read these terms and conditions (the 'Terms') carefully.
            </p>
            <h2>2. Acceptance of the Terms</h2>
            <p>
              By using, browsing and/or reading the Website, this signifies that
              you have read, understood and agree to be bound by the Terms. If
              you do not agree with the Terms, you must cease usage of the
              Website, or any of its products or Services, immediately.
            </p>
            <p>
              You accept the Terms by registering for the Services and/or making
              any payment as required under the Terms for use of the Services.
              You may also accept the Terms by clicking to accept or agree to
              the Terms where and if this option is made available to you by
              Renos&Rebuilds in the user interface.
            </p>
            <h2>3. The Services</h2>
            <p>
              In order to access the Services, both the Receiver and the
              Provider are required to register for an account through the
              Website (the 'Account').
            </p>
            <p>
              As part of the registration process, or as part of your continued
              use of the Services, you may be required to provide personal
              information about yourself (such as identification or contact
              details), including:
            </p>
            <ol>
              <li>Email address</li>
              <li>Preferred username</li>
              <li>Telephone number</li>
              <li>Password</li>
              <li>Location, post code</li>
            </ol>
            <p>
              You warrant that any information you give to Renos&Rebuilds in the
              course of completing the registration process will always be
              accurate, correct and up to date.
            </p>
            <p>
              Once you have completed the registration process, you will be a
              registered member of the Website ('Member') and agree to be bound
              by the Terms.
            </p>
            <p>You may not use the Services and may not accept the Terms if:</p>
            <ol>
              <li>
                you are not of legal age to form a binding contract with
                Renos&Rebuilds; or
              </li>
              <li>
                you are a person barred from receiving the Services under the
                laws of Australia or other countries including the country in
                which you are resident or from which you use the Services.
              </li>
            </ol>
            <h2>4. Your obligations as a Member</h2>
            <p>As a Member, you agree to comply with the following:</p>
            <ol>
              <li>You will not share your profile with any other person.</li>
              <li>
                You will use the Services only for purposes that are permitted
                by:
                <ol>
                  <li>the Terms; and</li>
                  <li>
                    any applicable law, regulation or generally accepted
                    practices or guidelines in the relevant jurisdictions.
                  </li>
                </ol>
              </li>
              <li>
                You have sole responsibility for protecting the confidentiality
                of your password and/or email address. Use of your password by
                any other person may result in the immediate cancellation of
                your access to the Services.
              </li>
              <li>
                Any use of your registration information by any other person, or
                third parties, is strictly prohibited. You agree to immediately
                notify Renos&Rebuilds of any unauthorized use of your password
                or email address or any breach of security of which you have
                become aware.
              </li>
              <li>
                You must not expressly or impliedly impersonate another Member
                or use the profile or password of another Member at any time.
              </li>
              <li>
                Any content that you broadcast, publish, upload, transmit, post
                or distribute on the Website ('Your Content') will always be
                accurate, correct and up to date, and you will maintain
                reasonable records of Your Content.
              </li>
              <li>
                You agree not to harass, impersonate, stalk, threaten another
                Member of the Website (where interaction with other Members is
                made available to you).
              </li>
              <li>
                Access and use of the Website is limited, non-transferable and
                allows for the sole use of the Website by you for the purposes
                of providing the Services.
              </li>
              <li>
                You will not use the Services or the Website in connection with
                any commercial endeavors except those that are specifically
                endorsed or approved by the management of Renos&Rebuilds.
              </li>
              <li>
                You will not use the Services or Website for any illegal and/or
                unauthorized use, which includes collecting email addresses of
                Members by electronic or other means for the purpose of sending
                unsolicited email or unauthorized framing of, or linking to, the
                Website.
              </li>
              <li>
                You agree that commercial advertisements, affiliate links and
                other forms of solicitation may be removed from Member profiles
                without notice and may result in termination of the Services.
                Appropriate legal action will be taken by Renos&Rebuilds for any
                illegal or unauthorized use of the Website.
              </li>
              <li>
                You acknowledge and agree that any automated use of the Website
                or its Services is prohibited.
              </li>
            </ol>
            <h2>5. Using the Website as the Receiver</h2>
            <p>
              Contact the Providing member by practical means, in App message,
              text message or phone.
            </p>
            <h2>6. Using the Website as the Provider</h2>
            <p>
              Member is to ensure all information is accurate and correct for
              the item/s that are provided, with a detailed message.
            </p>
            <h2>7. Refund Policy</h2>
            <p>
              Since Renos&Rebuilds acts as a facilitator in introducing the
              Receiver to the Provider, the Receiver acknowledges that it is a
              buyer beware and Renos and Rebuilds does not provide a system to
              make safe payments or any guarantees of any items. Therefore,
              Renos&Rebuilds will not hold any liability to the Receiver
              directly and will not personally refund them any payments made in
              the use of Services.
            </p>
            <p>
              Notwithstanding the above clause, if a Receiver is unsatisfied
              with the services provided by the Provider or believes that they
              may be entitled to a refund, then Renos&Rebuilds requires the
              Receiver to:
            </p>
            <ol>
              <li>contact the Provider directly to request a refund; and</li>
              <li>
                if contacting the Provider is not successful after fourteen (14)
                days, contact Renos&Rebuilds through the 'Contact Us' or email
                admin@renosandrebuilds.com section of the Website outlining why
                you believe you are entitled to a refund so we are able to
                determine if the Provider should be removed from the Services.
              </li>
            </ol>
            <p>
              If contacted by a Receiver who is requesting a refund pursuant to
              the above clause, the Provider agrees that it will immediately:
            </p>
            <ol>
              <li>
                respond to the Receiver in a timely manner, within the 14 days
                as stated above;
              </li>
              <li>
                provide a refund to the Receiver or a plausible reasoning as to
                why a refund should not be granted.
              </li>
            </ol>
            <p>
              If the Provider agrees to a refund, it is acknowledged that the
              Provider will notify Renos&Rebuilds that a refund has been granted
              and made, to refund all or part of the payments made to the
              Receiver directly, unless dealt with before the expiration of the
              14 days, then Renos&Rebuilds does not need to be notified.
            </p>
            <p>
              Both the Receiver and Provider agree that they will comply with
              the Refund Policy contained in this Clause of these Terms as far
              as reasonably practical.
            </p>
            <h2>8. Copyright and Intellectual Property</h2>
            <p>
              The Website, the Services, and all of the related products of
              Renos&Rebuilds are subject to copyright. The material on the
              Website is protected by copyright under the laws of Australia and
              through international treaties. Unless otherwise indicated, all
              rights (including copyright) in the Services and compilation of
              the Website (including but not limited to text, graphics, logos,
              button icons, video images, audio clips, Website code, scripts,
              design elements, and interactive features) or the Services are
              owned or controlled for these purposes and are reserved by
              Renos&Rebuilds or its contributors.
            </p>
            <p>
              All trademarks, service marks, and trade names are owned,
              registered, and/or licensed by Renos&Rebuilds, who grants you a
              worldwide, non-exclusive, royalty-free, revocable license while
              you are a Member to:
            </p>
            <ol>
              <li>use the Website pursuant to the Terms;</li>
              <li>
                copy and store the Website and the material contained on the
                Website in your device's cache memory; and
              </li>
              <li>
                print pages from the Website for your own personal and
                non-commercial use.
              </li>
            </ol>
            <p>
              Renos&Rebuilds does not grant you any other rights whatsoever in
              relation to the Website or the Services. All other rights are
              expressly reserved by Renos&Rebuilds.
            </p>
            <p>
              Renos&Rebuilds retains all rights, title, and interest in and to
              the Website and all related Services. Nothing you do on or in
              relation to the Website will transfer any:
            </p>
            <ol>
              <li>
                business name, trading name, domain name, trademark, industrial
                design, patent, registered design, or copyright; or
              </li>
              <li>
                a right to use or exploit a business name, trading name, domain
                name, trademark, or industrial design; or
              </li>
              <li>
                a thing, system, or process that is the subject of a patent,
                registered design, or copyright (or an adaptation or
                modification of such a thing, system, or process),
              </li>
            </ol>
            <p>to you.</p>
            <p>
              You may not, without the prior written permission of
              Renos&Rebuilds and the permission of any other relevant rights
              owners:
            </p>
            <ul>
              <li>
                broadcast, republish, upload to a third party, transmit, post,
                distribute, show, or play in public, adapt, or change in any way
                the Services or third-party Services for any purpose unless
                otherwise provided by these Terms. This prohibition does not
                extend to materials on the Website, which are freely available
                for re-use or are in the public domain.
              </li>
            </ul>
            <p>
              Where you broadcast, publish, upload, transmit, post, or
              distribute Your Content on the Website, then you grant to
              Renos&Rebuilds a non-exclusive, transferable, perpetual,
              royalty-free, irrevocable, worldwide license to broadcast,
              republish, upload to a third party, transmit, post, distribute,
              show, or play in public, adapt, or change Your Content.
            </p>
            <h2>9. Privacy</h2>
            <p>
              Renos&Rebuilds takes your privacy seriously, and any information
              provided through your use of the Website and/or Services is
              subject to Renos&Rebuilds Privacy Policy, which is available on
              the Website.
            </p>
            <h1>10. General Disclaimer</h1>
            <p>
              0. Nothing in the Terms limits or excludes any guarantees,
              warranties, representations or conditions implied or imposed by
              law, including the Australian Consumer Law (or any liability under
              them) which by law may not be limited or excluded.
            </p>
            <p>
              1. Subject to this clause, and to the extent permitted by law:
            </p>
            <p>
              0. all terms, guarantees, warranties, representations or
              conditions which are not expressly stated in the Terms are
              excluded; and
            </p>
            <p>
              1. Renos&amp;Rebuilds will not be liable for any special, indirect
              or consequential loss or damage (unless such loss or damage is
              reasonably foreseeable resulting from our failure to meet an
              applicable Consumer Guarantee), loss of profit or opportunity, or
              damage to goodwill arising out of or in connection with the
              Services or these Terms (including as a result of not being able
              to use the Services or the late supply of the Services), whether
              at common law, under contract, tort (including negligence), in
              equity, pursuant to statute or otherwise.
            </p>
            <p>
              2. Use of the Website and the Services is at your own risk.
              Everything on the Website and the Services is provided to you "as
              is" and "as available" without warranty or condition of any kind.
              None of the affiliates, directors, officers, employees, agents,
              contributors and licensors of Renos&amp;Rebuilds make any express
              or implied representation or warranty about the Services or any
              products or Services (including the products or Services of
              Renos&amp;Rebuilds) referred to on the Website. This includes (but
              is not restricted to) loss or damage you might suffer as a result
              of any of the following:
            </p>
            <p>
              0. failure of performance, error, omission, interruption,
              deletion, defect, failure to correct defects, delay in operation
              or transmission, computer virus or other harmful component, loss
              of data, communication line failure, unlawful third party conduct,
              or theft, destruction, alteration or unauthorised access to
              records;
            </p>
            <p>
              1. the accuracy, suitability or currency of any information on the
              Website, the Services, or any of its Services related products
              (including third party material and advertisements on the
              Website);
            </p>
            <p>
              2. costs incurred as a result of you using the Website, the
              Services or any of the products of Renos&amp;Rebuilds; and
            </p>
            <p>
              3. the Services or operation in respect to links which are
              provided for your convenience.
            </p>
            <p>
              3. You acknowledge that Renos&amp;Rebuilds Website and the
              Services are only intended to facilitate the interactions between
              the Receiver and the Provider and does not offer any services
              other than the Services and Renos&amp;Rebuilds holds no liability
              to you as a result of any conduct of the Members or the misuse of
              Your Content by any party (including other Members).
            </p>
            <h1>11. Limitation of Liability</h1>
            <p>
              0. Renos&amp;Rebuilds total liability arising out of or in
              connection with the Services or these Terms, however arising,
              including under contract, tort (including negligence), in equity,
              under statute or otherwise, will not exceed the resupply of the
              Services to you.
            </p>
            <p>
              1. You expressly understand and agree that Renos&amp;Rebuilds, its
              affiliates, employees, agents, contributors and licensors shall
              not be liable to you for any direct, indirect, incidental, special
              consequential or exemplary damages which may be incurred by you,
              however caused and under any theory of liability. This shall
              include, but is not limited to, any loss of profit (whether
              incurred directly or indirectly), any loss of goodwill or business
              reputation and any other intangible loss.
            </p>
            <p>
              2. You acknowledge and agree that Renos&amp;Rebuilds holds no
              liability for any direct, indirect, incidental, special
              consequential or exemplary damages which may be incurred by you as
              a result of providing Your Content to the Website.
            </p>
            <h1>12. Termination of Contract</h1>
            <p>
              0. If you want to terminate the Terms, you may do so by providing
              Renos&amp;Rebuilds with 7 days' notice of your intention to
              terminate by sending notice of your intention to terminate to
              Renos&amp;Rebuilds via the 'Contact Us' link on our homepage.
            </p>
            <p>
              1. Renos&amp;Rebuilds may at any time, terminate the Terms with
              you if:
            </p>
            <p>
              0. you have breached any provision of the Terms or intend to
              breach any provision;
            </p>
            <p>1. Renos&amp;Rebuilds is required to do so by law;</p>
            <p>
              2. Renos&amp;Rebuilds is transitioning to no longer providing the
              Services to Members in the country in which you are resident or
              from which you use the service; or
            </p>
            <p>
              3. the provision of the Services to you by Renos&amp;Rebuilds is,
              in the opinion of Renos&amp;Rebuilds, no longer commercially
              viable.
            </p>
            <p>
              2. Subject to local applicable laws, Renos&amp;Rebuilds reserves
              the right to discontinue or cancel your membership at any time and
              may suspend or deny, in its sole discretion, your access to all or
              any portion of the Website or the Services without notice if you
              breach any provision of the Terms or any applicable law or if your
              conduct impacts Renos&amp;Rebuilds's name or reputation or
              violates the rights of those of another party.
            </p>
            <p>
              3. When the Terms come to an end, all of the legal rights,
              obligations and liabilities that you and Renos&amp;Rebuilds have
              benefited from, been subject to (or which have accrued over time
              whilst the Terms have been in force) or which are expressed to
              continue indefinitely, shall be unaffected by this cessation, and
              the provisions of this clause shall continue to apply to such
              rights, obligations and liabilities indefinitely.
            </p>
            <h1>13. Indemnity</h1>
            <p>
              0. You agree to indemnify Renos&amp;Rebuilds, its affiliates,
              employees, agents, contributors, third party content providers and
              licensors from and against:
            </p>
            <p>
              0. all actions, suits, claims, demands, liabilities, costs,
              expenses, loss and damage (including legal fees on a full
              indemnity basis) incurred, suffered or arising out of or in
              connection with Your Content;
            </p>
            <p>
              1. any direct or indirect consequences of you accessing, using or
              transacting on the Website or attempts to do so; and/or
            </p>
            <p>2. any breach of the Terms.</p>
            <h1>14. Dispute Resolution</h1>
            <p>
              0. Compulsory: If a dispute arises out of or relates to the Terms,
              either party may not commence any Tribunal or Court proceedings in
              relation to the dispute, unless the following clauses have been
              complied with (except where urgent interlocutory relief is
              sought).
            </p>
            <p>
              1. Notice: A party to the Terms claiming a dispute ('Dispute') has
              arisen under the Terms, must give written notice to the other
              party detailing the nature of the dispute, the desired outcome and
              the action required to settle the Dispute.
            </p>
            <p>
              2. Resolution: On receipt of that notice ('Notice') by that other
              party, the parties to the Terms ('Parties') must:
            </p>
            <p>
              0. Within 28 days of the Notice endeavour in good faith to resolve
              the Dispute expeditiously by negotiation or such other means upon
              which they may mutually agree;
            </p>
            <p>
              1. If for any reason whatsoever, 28 days after the date of the
              Notice, the Dispute has not been resolved, the Parties must either
              agree upon selection of a mediator or request that an appropriate
              mediator be appointed by the President of the 'Australian
              Mediation Association' or his or her nominee;
            </p>
            <p>
              2. The Parties are equally liable for the fees and reasonable
              expenses of a mediator and the cost of the venue of the mediation
              and without limiting the foregoing undertake to pay any amounts
              requested by the mediator as a pre-condition to the mediation
              commencing. The Parties must each pay their own costs associated
              with the mediation;
            </p>
            <p>3. The mediation will be held in Brisbane, Australia.</p>
            <p>
              3. Confidential All communications concerning negotiations made by
              the Parties arising out of and in connection with this dispute
              resolution clause are confidential and to the extent possible,
              must be treated as "without prejudice" negotiations for the
              purpose of applicable laws of evidence.
            </p>
            <p>
              4. Termination of Mediation: If 1 month has elapsed after the
              start of a mediation of the Dispute and the Dispute has not been
              resolved, either Party may ask the mediator to terminate the
              mediation and the mediator must do so.
            </p>
            <h1>15. Venue and Jurisdiction</h1>
            <p>
              The Services offered by Renos&amp;Rebuilds are intended to be
              viewed by residents of Australia. In the event of any dispute
              arising out of or in relation to the Website, you agree that the
              exclusive venue for resolving any dispute shall be in the courts
              of Queensland, Australia.
            </p>
            <h1>16. Governing Law</h1>
            <p>
              The Terms are governed by the laws of Queensland, Australia. Any
              dispute, controversy, proceeding or claim of whatever nature
              arising out of or in any way relating to the Terms and the rights
              created hereby shall be governed, interpreted and construed by,
              under and pursuant to the laws of Queensland, Australia, without
              reference to conflict of law principles, notwithstanding mandatory
              rules. The validity of this governing law clause is not contested.
              The Terms shall be binding to the benefit of the parties hereto
              and their successors and assigns.
            </p>
            <h1>17. Independent Legal Advice</h1>
            <p>
              Both parties confirm and declare that the provisions of the Terms
              are fair and reasonable and both parties having taken the
              opportunity to obtain independent legal advice and declare the
              Terms are not against public policy on the grounds of inequality
              or bargaining power or general grounds of restraint of trade.
            </p>
            <h1>18. Severance</h1>
            <p>
              If any part of these Terms is found to be void or unenforceable by
              a Court of competent jurisdiction, that part shall be severed and
              the rest of the Terms shall remain in force.
            </p>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Terms;
