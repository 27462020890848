import {
  IonAccordion,
  IonAccordionGroup,
  IonAvatar,
  IonButton,
  IonButtons,
  IonContent,
  IonIcon,
  IonImg,
  IonItem,
  IonList,
  IonMenu,
  IonText,
  IonToolbar,
  useIonRouter,
  useIonViewWillLeave,
} from "@ionic/react";
import {
  personCircle,
  addSharp,
  logOutSharp,
  chatbubbleSharp,
  bookmarkSharp,
  briefcaseSharp,
  analyticsSharp,
  personCircleSharp,
  barChartSharp,
  easelSharp,
  handLeftSharp,
  pricetagSharp,
  settingsSharp,
  buildSharp,
  documentSharp,
} from "ionicons/icons";
import { useContext, useRef } from "react";
import { EnvContext } from "../../lib/envcontext";
import { UserContext } from "../../lib/usercontext";
import NotificationsBar from "./NotificationsBar";

const Toolbar = () => {
  const { user } = useContext(UserContext);
  const firstMenuRef = useRef<HTMLIonMenuElement | null>(null);
  const router = useIonRouter();
  const { environment } = useContext(EnvContext);

  useIonViewWillLeave(() => {
    firstMenuRef.current?.close();
  });
  return (
    <>
      <IonMenu
        swipeGesture={false}
        side="end"
        type="overlay"
        contentId="main"
        ref={firstMenuRef}
      >
        <IonContent className="ion-padding">
          <IonList>
            <IonItem
              id="profile"
              onClick={() => {
                firstMenuRef.current?.toggle();

                router.push(`/Home/profile/${user?.user?.displayname}`);
              }}
              button
            >
              <IonIcon slot="start" icon={personCircleSharp} />

              <IonText>Profile</IonText>
            </IonItem>
            <IonItem
              id="messages"
              onClick={() => {
                firstMenuRef.current?.toggle();
                router.push("/Home/messages");
              }}
              button
            >
              <IonIcon slot="start" icon={chatbubbleSharp} />
              <IonText>Messages</IonText>
            </IonItem>

            <IonItem
              id="savedPosts"
              onClick={() => {
                firstMenuRef.current?.toggle();
                router.push(`/Home/profile/${user.user.displayname}/saved`);
              }}
              button
            >
              <IonIcon slot="start" icon={bookmarkSharp} />
              <IonText>Saved</IonText>
            </IonItem>

            {/* <IonItem
              id="settings"
              onClick={() => {
                firstMenuRef.current?.toggle();
                alert("not implemented");
              }}
              button
            >
              <IonIcon slot="start" icon={settingsSharp} />
              <IonText>Settings</IonText>
            </IonItem> */}
            {user?.user?.type !== "business" ? (
              <>
                {" "}
                <IonItem
                  onClick={() => {
                    firstMenuRef.current?.toggle();
                    router.push(
                      `/Home/profile/${user?.user?.displayname}/myaccounts`
                    );
                  }}
                  id="accounts"
                  button
                >
                  <IonIcon slot="start" icon={briefcaseSharp} />
                  <IonText>My Businesses</IonText>
                </IonItem>
                {user?.user?.admin ? (
                  <>
                    {" "}
                    <IonItem
                      onClick={() => {
                        firstMenuRef.current?.toggle();
                        router.push("/Moderation");
                      }}
                      button
                    >
                      <IonIcon slot="start" icon={handLeftSharp} />
                      <IonText>Moderation Tools</IonText>
                    </IonItem>
                    <IonItem
                      onClick={() => {
                        firstMenuRef.current?.toggle();
                        router.push("/TagManagement");
                      }}
                      button
                    >
                      <IonIcon slot="start" icon={pricetagSharp} />
                      <IonText>Tag Management</IonText>
                    </IonItem>
                  </>
                ) : null}
              </>
            ) : (
              <>
                <IonAccordionGroup>
                  <IonAccordion>
                    <IonItem slot="header">
                      <IonIcon slot="start" icon={easelSharp} />
                      <IonText>Advertising</IonText>
                    </IonItem>
                    <IonItem
                      color="light"
                      slot="content"
                      onClick={() => {
                        firstMenuRef.current?.toggle();
                        router.push("/Advertising/onboard");
                      }}
                      button
                    >
                      <IonIcon slot="start" icon={analyticsSharp} />
                      <IonText>New Campaign</IonText>
                    </IonItem>
                    <IonItem
                      color="light"
                      slot="content"
                      onClick={() => {
                        firstMenuRef.current?.toggle();
                        router.push("/Advertising/ManageCampaigns");
                      }}
                      button
                    >
                      <IonIcon slot="start" icon={barChartSharp} />
                      <IonText>Manage Campaigns</IonText>
                    </IonItem>
                  </IonAccordion>
                </IonAccordionGroup>
              </>
            )}

            <IonAccordionGroup>
              <IonAccordion>
                <IonItem slot="header" id="settings" button>
                  <IonIcon slot="start" icon={settingsSharp} />
                  <IonText>Settings</IonText>
                </IonItem>
                <IonItem
                  color="light"
                  slot="content"
                  onClick={() => {
                    firstMenuRef.current?.toggle();
                    user.user.type === "person"
                      ? router.push("/account/editProfile")
                      : router.push("/account/editBusiness");
                  }}
                  button
                >
                  <IonIcon slot="start" icon={buildSharp} />
                  <IonText>Edit Profile</IonText>
                </IonItem>
                <IonItem
                  color="light"
                  slot="content"
                  href="/Terms"
                  target="_blank"
                  button
                >
                  <IonIcon slot="start" icon={documentSharp} />
                  <IonText>Terms</IonText>
                </IonItem>
                <IonItem
                  color="light"
                  slot="content"
                  href="/Privacy"
                  target="_blank"
                  button
                >
                  <IonIcon slot="start" icon={documentSharp} />
                  <IonText>Pricvacy Policy</IonText>
                </IonItem>
                <IonItem
                  color="light"
                  slot="content"
                  href="/AppPolicy"
                  target="_blank"
                  button
                >
                  <IonIcon slot="start" icon={documentSharp} />
                  <IonText>App Policy</IonText>
                </IonItem>
              </IonAccordion>
            </IonAccordionGroup>
            <IonItem
              id="logout"
              onClick={() => {
                firstMenuRef.current?.toggle();
                fetch(`${environment.backendURL}/auth/logout`, {
                  credentials: "include",
                  method: "POST",
                }).then(() => {
                  window.location.href = "/login";
                });
              }}
              button
            >
              <IonIcon slot="start" icon={logOutSharp} />
              <IonText>Log Out</IonText>
            </IonItem>
          </IonList>
        </IonContent>
      </IonMenu>
      <IonToolbar id="main" color="light">
        <IonButtons slot="start">
          <svg
            width="100%"
            height="9vh"
            viewBox="0 0 532 298"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M211 40V31L306.75 31C377.501 31 377.999 131 306.75 131H280L356 231H332.064L256 131.5L256 107H301.645C347.612 107 347.291 55 301.645 55L236 55V231H211V71.5L180 31L149 71.5V231H124V55L58.3546 55C12.7093 55 12.3879 107 58.3546 107H104L104 131.5L27.9357 231H4L80 131H53.2496C-17.9988 131 -17.5005 31 53.2496 31L149 31V40L156 31L180 0L204 31L211 40Z"
              fill="#F4F5F8"
            />
            <path d="M163 98.8929H176.852V73H163V98.8929Z" fill="#F4F5F8" />
            <path d="M197 73H183.148V98.8929H197V73Z" fill="#F4F5F8" />
            <path d="M163 104.071H176.852V131H163V104.071Z" fill="#F4F5F8" />
            <path d="M183.148 131V104.071H197V131H183.148Z" fill="#F4F5F8" />
          </svg>

          <IonText slot="secondary">
            <i>© Renos & Rebuilds</i>
          </IonText>
        </IonButtons>
        <IonButtons slot="primary">
          <IonButton routerLink="/Home/CreatePost">
            <IonIcon color="dark" slot="icon-only" icon={addSharp} />
          </IonButton>
          <NotificationsBar />

          <div
            className="pointer"
            onClick={() => firstMenuRef.current?.toggle()}
          >
            {user?.user?.email ? (
              <IonAvatar
                id="profileMenu"
                style={{
                  height: "3rem",
                  width: "3rem",
                }}
              >
                <IonImg src={user.user.photo} alt="avatar" />
              </IonAvatar>
            ) : (
              <IonIcon slot="icon-only" icon={personCircle} />
            )}
          </div>
        </IonButtons>
      </IonToolbar>
    </>
  );
};

export default Toolbar;
