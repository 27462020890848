import { useContext, useEffect, useState } from "react";
import { EnvContext } from "../../lib/envcontext";
import UserItem from "../sitewide/UserItem";
import { IonCard, IonImg, IonRow, IonicSlides } from "@ionic/react";
import PostPreview from "../socialMedia/PostPreview";
import ModerationActionsMenu from "./ModerationActionsMenu";
import { PostData } from "../../lib/types";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "@ionic/react/css/ionic-swiper.css";
import { retrievePost } from "../../lib/util";
interface PostPreviewProps {
  id: string;
  reason: string;
  reportID: string;
}

const PostModPreview: React.FC<PostPreviewProps> = ({
  id,
  reason,
  reportID,
}) => {
  const { environment } = useContext(EnvContext);
  const [post, setPost] = useState({} as PostData);
  const [hidden, setHidden] = useState(false);
  useEffect(() => {
    retrievePost({
      id: id,
      backendURL: environment.backendURL,
    }).then((data) => {
      setPost(data);
    });
  }, [environment, id]);
  return (
    <>
      {hidden || !post?.user?.email ? (
        <></>
      ) : (
        <IonCard
          style={{
            padding: "1rem",
          }}
        >
          <UserItem link={true} email={post?.user.email} />
          <p>Title: {post?.post.title}</p>
          <p>Description: {post?.post.description}</p>
          {post.post.repost ? (
            <div
              style={{
                backgroundColor: "var(--ion-color-light)",
                padding: "1rem",
              }}
            >
              <PostPreview postID={id} beingShared={true} />
            </div>
          ) : (
            <>
              {post?.post?.photo ? (
                <Swiper modules={[Pagination, IonicSlides]} pagination={true}>
                  {post.post.photo.map((photo, index: number) => {
                    return (
                      <SwiperSlide key={index}>
                        {photo.includes("mp4") ? (
                          <video controls autoPlay loop muted>
                            <source
                              src={`${environment.storageURL}/photos/${photo}`}
                              type="video/mp4"
                            />
                          </video>
                        ) : (
                          // invisible if no photo
                          <IonImg
                            style={{
                              margin: "1rem",
                              padding: "1rem",
                            }}
                            src={`${environment.storageURL}/photos/${photo}`}
                          />
                        )}
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              ) : (
                <></>
              )}
            </>
          )}
          <IonRow>Reason for report: {reason}</IonRow>
          <br />
          <ModerationActionsMenu
            email={post?.user.email}
            postID={post?.post.postID}
            reportID={reportID}
            setHidden={setHidden}
          />
        </IonCard>
      )}
    </>
  );
};

export default PostModPreview;
