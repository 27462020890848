import {
  IonContent,
  IonPage,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import { useContext } from "react";
import { NavBarVisibilityContext } from "../../lib/navbarvisibilitycontext";

const AdvertisingTerms: React.FC = () => {
  const { setNavbarVisibility } = useContext(NavBarVisibilityContext);

  useIonViewWillEnter(() => {
    setNavbarVisibility(false);
  }, [setNavbarVisibility]);
  useIonViewWillLeave(() => {
    setNavbarVisibility(true);
  }, [setNavbarVisibility]);
  return (
    <IonPage>
      <IonContent fullscreen>
        <div
          style={{
            //top padding 1, bottom padding 1, left padding 5, right padding 5
            padding: "1rem 10rem 1rem 10rem",
          }}
        >
          <h1>Renos&Rebuilds</h1>
          <h2>WEBSITE ADVERTISING AGREEMENT</h2>

          <p>This Agreement is dated 11/05/2023</p>

          <p>BETWEEN:</p>
          <p>
            Renos&Rebuilds PTY LTD of PO Box 2322, Mansfield, Queensland, 4122
            (Website Owner);
          </p>
          <p>AND</p>
          <p>You the (Advertiser)</p>

          <h3>RECITALS:</h3>
          <p>The Website Owner owns and operates the Website.</p>
          <p>
            The Advertiser wishes to purchase advertising space on the Website.
          </p>

          <h3>OPERATIVE PROVISIONS:</h3>

          <h4>Definitions and Interpretation</h4>
          <p>In this Agreement, unless the context indicates the contrary:</p>
          <ul>
            <li>
              Advertising Content means the content of advertisements submitted
              by or on behalf of the Advertiser to the Website Owner in a
              request to Advertise including but not limited to all text,
              graphics, icons, photographs, and URLs.
            </li>
            <li>
              Confidential Information means all information provided by one
              party to the other in connection with this Agreement where such
              information is identified as confidential at the time of its
              disclosure or ought reasonably be considered confidential based on
              its content, nature, or the manner of its disclosure, but
              excluding:
            </li>
            <ul>
              <li>
                information that enters the public domain or is disclosed to a
                party by a third party, other than through a breach of this
                Agreement; and
              </li>
              <li>information developed independently by a party.</li>
            </ul>
            <li>Fees means the advertising fees detailed at Schedule 1.</li>
            <li>
              Force Majeure Event means any event beyond the control of the
              relevant party.
            </li>
            <li>
              GST has the meaning given in A New Tax System (Goods and Services
              Tax) Act 1999 (Cth), or any other similar tax.
            </li>
            <li>
              Intellectual Property Rights means all intellectual property
              rights, including all copyright, patents, trade marks, design
              rights, moral rights, trade secrets, domain names, know-how, and
              other rights of a similar nature, whether registrable or not and
              whether registered or not, and any applications for registration
              or rights to make such an application.
            </li>
            <li>
              Website means Renos&Rebuilds located at{" "}
              <a href="http://www.renosandrebuiilds.com">
                www.renosandrebuiilds.com
              </a>
              .
            </li>
          </ul>

          <p>Unless the context requires otherwise:</p>
          <ul>
            <li>
              a reference to a person includes a corporation or any other legal
              entity;
            </li>
            <li>the singular includes the plural and vice versa;</li>
            <li>
              headings are for convenience and do not form part of this
              Agreement or otherwise affect the interpretation of this
              Agreement;
            </li>
            <li>
              the term "includes" (or any similar term) means "includes without
              limitation"; and
            </li>
            <li>
              a reference to any statute includes references to any subsequently
              amended, consolidated or re-enacted version of that statute and
              all delegated legislation or other statutory instruments made
              under it.
            </li>
          </ul>

          <h4>Advertising requests</h4>
          <p>
            The Advertiser must complete an in App business account to have the
            ability to create an advertising campaign for advertising space on
            the Website.
          </p>
          <p>
            All information in the Business account must be complete and
            accurate and must comply with all laws, regulations, and relevant
            industry codes.
          </p>
          <p>
            Advertising is self-managed through the in App pre-purchase campaign
            management, advertisements must not request publication or linking
            to content that is illegal or may bring the Website Owner into
            disrepute.
          </p>
          <p>
            The Website Owner may reject or cancel any advertising at any time
            at its sole discretion.
          </p>
          <p>
            By submitting an advertisement, the Advertiser authorizes the
            Website Owner to publish the Advertising Content on the Website.
          </p>

          <h4>Positioning and style</h4>
          <p>
            The Advertiser acknowledges and agrees that the Website Owner
            retains complete editorial control over the Websites. The Website
            Owner may, at its sole discretion:
          </p>
          <ul>
            <li>refuse to include any Advertising Content at any time;</li>
            <li>remove any Advertising Content at any time;</li>
            <li>position Advertising Content as it sees fit; and</li>
            <li>
              label any Advertising Content as an "advertisement" for
              clarification and stipulate any other conditions to ensure that it
              is clear that the Advertising Content is an advertisement.
            </li>
          </ul>

          <h4>Payment</h4>
          <p>The Licensee must pay the Licensor the Fees.</p>
          <p>
            The Licensor may invoice the Licensee for the Fees on a weekly basis
            in arrears.
          </p>
          <p>
            Each invoice is payable within 7 days of the date of the invoice. If
            the Licensee has not paid an invoice in full by that date, the
            Licensee must pay interest on any unpaid amount from that date until
            the date of payment, to be calculated on a daily basis at a rate of
            10 percentage points over the cash rate set by the Reserve Bank of
            Australia (or, if that rate ceases to exist, a comparable rate
            nominated by the Licensor).
          </p>

          <h4>GST</h4>
          <p>
            All amounts payable under this Agreement are expressed exclusive of
            GST.
          </p>
          <p>
            In respect of any taxable supply, the Advertiser must pay to the
            Website Owner an additional amount equal to the prevailing GST rate,
            payable at the same time and in the same manner as the Fees, subject
            to the receipt by the Advertiser of a valid tax invoice.
          </p>

          <h4>Confidentiality</h4>
          <p>
            A party must not, without the prior written consent of the other,
            use or disclose the other party's Confidential Information unless
            expressly permitted by this Agreement or required to do so by law or
            any regulatory authority.
          </p>
          <p>A party may:</p>
          <ul>
            <li>
              use the Confidential Information of the other party solely for the
              purposes of complying with its obligations and exercising its
              rights under this Agreement; and
            </li>
            <li>
              disclose the Confidential Information to its personnel or advisers
              to the extent necessary for them to know the information for
              purposes related to this Agreement, but only if reasonable steps
              are taken to ensure that the confidentiality of the information is
              retained.
            </li>
          </ul>
          <p>
            Each party must implement and maintain effective security measures
            to prevent unauthorized use and disclosure of the other party's
            Confidential Information while it is in the receiving party's
            possession or control.
          </p>
          <p>
            Each party must return or, at the other party's option, destroy all
            Confidential Information of the disclosing party in the receiving
            party's possession or control, on the earlier of the Website Owner's
            request or on termination of this Agreement for any reason.
          </p>
          <h4>Warranties</h4>
          <p>The Advertiser represents and warrants that:</p>
          <ol>
            <li>
              it is fully authorised to publish, and to authorise the Website
              Owner to publish, all Advertising Content;
            </li>
            <li>
              the Advertising Content and the publication of the Advertising
              Content on the Websites does not and will not, at any time,
              infringe any Intellectual Property Right, any right of confidence
              or other right of any person;
            </li>
            <li>
              all Advertising Content will comply with all applicable laws,
              regulations, and relevant industry codes;
            </li>
            <li>
              all advertised products and services will match the advertised
              description and be available in the manner, at the price, and for
              the time period advertised; and
            </li>
            <li>
              each website located at any URL shown or embedded in any
              Advertising Content:
              <ol type="a">
                <li>
                  is controlled and operated by or on behalf of the Advertiser;
                </li>
                <li>is functional and accessible; and</li>
                <li>
                  at all times operates in compliance with all applicable laws,
                  regulations, and relevant industry codes.
                </li>
              </ol>
            </li>
          </ol>

          <h4>Liability</h4>
          <p>
            To the full extent permitted by law, the Website Owner excludes all
            liability in respect of loss of data, interruption of business, or
            any consequential or incidental damages.
          </p>
          <p>
            To the full extent permitted by law, the Website Owner excludes all
            representations, warranties, or terms (whether express or implied)
            other than those expressly set out in this Agreement.
          </p>
          <p>
            The Website Owner's total aggregate liability for all claims
            relating to this Agreement is limited to the Fees payable under this
            Agreement.
          </p>
          <p>
            Either party's liability for any claim relating to this Agreement
            will be reduced to the extent to which the other party contributed
            to the damage arising from the claim.
          </p>
          <p>
            This Agreement is to be read subject to any legislation which
            prohibits or restricts the exclusion, restriction, or modification
            of any implied warranties, conditions, guarantees, or obligations.
            If such legislation applies, to the extent possible, the Website
            Owner limits its liability in respect of any claim to, at the
            Website Owner's option:
          </p>
          <ul>
            <li>
              in the case of goods:
              <ol type="i">
                <li>
                  the replacement of the goods or the supply of equivalent
                  goods;
                </li>
                <li>the repair of the goods;</li>
                <li>
                  the payment of the cost of replacing the goods or acquiring
                  equivalent goods; or
                </li>
                <li>the payment of having the goods repaired;</li>
              </ol>
            </li>
            <li>
              in the case of services:
              <ol type="i">
                <li>the supply of the services again; or</li>
                <li>
                  the payment of the cost of having the services supplied again.
                </li>
              </ol>
            </li>
          </ul>

          <h4>Indemnity</h4>
          <p>
            The Advertiser indemnifies and holds the Website Owner, its agents,
            affiliates, subsidiaries, directors, officers, employees,
            consultants, and contractors (collectively Indemnified Persons)
            harmless from and against any and all costs, claims, losses,
            damages, liability, and expense (including all reasonable legal
            fees) which may be made or brought against or suffered or incurred,
            directly or indirectly by the Indemnified Persons in connection
            with:
          </p>
          <ol>
            <li>any breach of this Agreement by the Advertiser;</li>
            <li>
              any act of fraud or willful misconduct by or on behalf of the
              Advertiser; or
            </li>
            <li>
              the publication of, or any act or omission in relation to, the
              Advertising Content or any website represented by a URL included
              or embedded in the Advertising Content.
            </li>
          </ol>

          <h4>Termination</h4>
          <p>
            A party may terminate this Agreement by written notice to the other
            if any of the following events has occurred in respect of the other
            party:
          </p>
          <ol>
            <li>
              a material breach of this Agreement which:
              <ol type="a">
                <li>is not remediable; or</li>
                <li>
                  if capable of remedy, is not remedied by the other party
                  within 14 days of written notice;
                </li>
              </ol>
            </li>
            <li>
              an insolvency event occurs, other than an internal reconstruction
              with notice to the other party.
            </li>
          </ol>

          <h4>Consequences of termination</h4>
          <p>
            If this Agreement is terminated or expires for any reason, then, in
            addition and without prejudice to any other rights or remedies
            available:
          </p>
          <ol>
            <li>
              the parties are immediately released from their obligations under
              the Agreement except those obligations in clauses 6 to 9 and any
              other obligations that, by their nature, survive termination;
            </li>
            <li>
              each party retains the rights and claims it has against the other;
              and
            </li>
            <li>the Advertiser must immediately pay all outstanding Fees.</li>
          </ol>

          <h4>Notices</h4>
          <p>
            The parties' contact details for notices under this Agreement are as
            follows, or as otherwise notified by one party to the other from
            time to time:
          </p>
          <p>Website Owner: Renos&amp;Rebuilds PTY LTD</p>
          <p>Address: PO Box 2322, Mansfield, Queensland, 4122</p>
          <p>Contact: admin@renosandrebuilds.com</p>
          <p>And THE Advertiser</p>
          <p>All notices must be in writing and can be given by:</p>
          <ol>
            <li>hand delivery during normal business hours;</li>
            <li>registered post; or</li>
            <li>
              facsimile followed within 2 business days by one of the means
              listed above.
            </li>
          </ol>
          <p>A notice is deemed to be given and received:</p>
          <ol>
            <li>
              if delivered in accordance with paragraph (b)(i), on the next
              business day after the day of delivery;
            </li>
            <li>
              if sent in accordance with paragraph (b)(ii), 5 clear business
              days after the day of posting;
            </li>
            <li>
              if sent in accordance with paragraph (b)(iii), on the next
              business day after transmission.
            </li>
          </ol>
          <h4>General</h4>
          <p>
            The Advertiser must not assign or otherwise deal in any other way
            with any of its rights under this Agreement without the prior
            written consent of the Website Owner.
          </p>
          <p>
            Nothing contained in this Agreement creates any relationship of
            partnership or agency between the parties.
          </p>
          <p>
            If a provision of this Agreement is invalid or unenforceable, it is
            to be read down or severed to the extent necessary without affecting
            the validity or enforceability of the remaining provisions.
          </p>
          <p>
            Each party must, at its own expense, do everything reasonably
            necessary to give full effect to this Agreement and the events
            contemplated by it.
          </p>
          <p>
            This Agreement (and any documents executed in connection with it) is
            the entire agreement of the parties about its subject matter and
            supersedes all other representations, arrangements, or agreements.
            Other than as expressly set out in this Agreement, no party has
            relied on any representation made by or on behalf of the other.
          </p>
          <p>
            This Agreement may be amended only by a document signed by all
            parties.
          </p>
          <p>
            A provision of or a right under this Agreement may not be waived or
            varied except in writing signed by the person to be bound.
          </p>
          <p>
            This Agreement may be executed in counterparts which will be taken
            together to constitute one document.
          </p>
          <p>
            A party will not be responsible for a failure to comply with its
            obligations under this Agreement to the extent that failure is
            caused by a Force Majeure Event, provided that the party keeps the
            other closely informed in such circumstances and uses reasonable
            endeavors to rectify the situation.
          </p>
          <p>
            Without limiting any other right to terminate under this Agreement,
            if a Force Majeure Event affects a party's performance under this
            Agreement for more than thirty (30) consecutive days, the other
            party may immediately terminate this Agreement by written notice.
          </p>
          <p>
            All stamp duties and other government charges in relation to this
            Agreement must be paid by the Advertiser.
          </p>
          <p>
            This Agreement is governed by the laws of Queensland, and each party
            submits to the jurisdiction of the courts of Queensland.
          </p>
        </div>{" "}
      </IonContent>
    </IonPage>
  );
};

export default AdvertisingTerms;
