import {
  IonAvatar,
  IonCard,
  IonContent,
  IonItem,
  IonList,
  IonPage,
  IonRow,
  IonText,
  useIonAlert,
  useIonLoading,
  useIonRouter,
  useIonViewWillEnter,
} from "@ionic/react";
import { useContext, useState } from "react";
import GenericBackToolbar from "../../components/sitewide/GenericBackToolbar";
import { EnvContext } from "../../lib/envcontext";
import { UserContext } from "../../lib/usercontext";
import "../../theme/standard.css";
import EnterEmailModal from "../../components/user/Modal/EnterEmailModal";
import axios from "axios";

const MyAccounts: React.FC = () => {
  const router = useIonRouter();
  const { user } = useContext(UserContext);
  const [ownedBusinesses, setOwnedBusinesses] = useState([]);
  const { environment } = useContext(EnvContext);
  const [show, hide] = useIonLoading();
  const [presentAlert] = useIonAlert();
  const { setUser } = useContext(UserContext);

  useIonViewWillEnter(async () => {
    fetch(
      `${environment.backendURL}/open/user/retrieveProfile/ownedBusinesses`,
      {
        credentials: "include",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setOwnedBusinesses(data);
      });
  }, [environment, user]);

  return (
    <IonPage>
      <GenericBackToolbar name="My businesses" />
      <IonContent>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "2vh",
          }}
        >
          <IonCard
            className="mobileResize"
            style={{
              height: "70vh",
            }}
          >
            {ownedBusinesses?.length > 0 ? (
              <div
                style={{
                  overflowY: "auto",
                  height: "100%",
                }}
              >
                <IonList
                  style={{
                    width: "100%",
                  }}
                >
                  {ownedBusinesses.map((business: string, index: number) => {
                    return (
                      <IonItem
                        key={index}
                        button
                        onClick={() => {
                          show("Logging in...");
                          axios({
                            method: "POST",
                            data: {
                              email: business,
                              //there is no password but it is required for the passport js strategy
                              password: "password",
                            },
                            withCredentials: true,
                            url: `${environment.backendURL}/auth/businessLogin`,
                          })
                            .then((data) => {
                              console.log(data);
                              hide();
                              if (data.data.errors) {
                                presentAlert({
                                  message: `
                            Please check your login details and try again.
                            `,
                                  buttons: ["OK"],
                                });
                                return;
                              }
                              setUser({
                                user: {
                                  displayname: data.data.displayname,
                                  email: data.data.email,
                                  photo: `${
                                    environment.storageURL
                                  }/photos/profile/${
                                    data?.data.email
                                  }.png?${Date.now()}`,
                                  type: "business",
                                  admin: false,
                                },
                              });
                              hide();
                              router.push("/Home");
                            })
                            .catch((err) => {
                              console.log(err);
                              hide();
                              presentAlert({
                                message: `
                            Please check your login details and try again.
                          `,
                                buttons: ["OK"],
                              });
                            });
                        }}
                      >
                        <IonRow>
                          {" "}
                          <IonAvatar>
                            <img
                              src={`${environment.storageURL}/photos/profile/${business}.png`}
                              alt="profile"
                            />
                          </IonAvatar>
                          &nbsp; &nbsp;
                          <IonText
                            style={{
                              fontSize: "1.3rem",
                            }}
                          >
                            {business}
                          </IonText>
                        </IonRow>
                      </IonItem>
                    );
                  })}
                </IonList>
              </div>
            ) : (
              <IonItem>
                <IonText>
                  <p>
                    You don't own any business accounts. Create one now by
                    pressing the below button
                  </p>
                </IonText>
              </IonItem>
            )}
            {/* <IonItem
              id="createBusiness"
              onClick={() => {
                router.push("/account/createBusinessAccount");
              }}
              button
            >
              <IonIcon slot="start" icon={briefcaseSharp} />
              <IonText>Apply for a new business account</IonText>
            </IonItem> */}
            <EnterEmailModal
              text="
              create a new business account
            "
              type="business"
            />
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default MyAccounts;
