import {
  IonContent,
  IonItemDivider,
  IonList,
  IonPage,
  useIonViewWillEnter,
} from "@ionic/react";
import { useParams } from "react-router";
import { useContext, useState } from "react";
import { EnvContext } from "../../lib/envcontext";
import GenericBackToolbar from "../../components/sitewide/GenericBackToolbar";
import UserItem from "../../components/sitewide/UserItem";
import PostPreview from "../../components/socialMedia/PostPreview";
interface Node {
  id: string;
  type: string;
}
const Tag: React.FC = () => {
  const { environment } = useContext(EnvContext);
  const { id } = useParams<{ id: string }>();
  const [feed, setFeed] = useState([] as Node[]);
  useIonViewWillEnter(() => {
    fetch(`${environment.backendURL}/open/sitewide/tagSearch/${id}`, {
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          console.log(data);
        } else {
          setFeed(data);
        }
      });
  });
  return (
    <IonPage>
      <GenericBackToolbar name="Tag" />
      <IonContent>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IonList>
            {feed.map((node, index) => {
              if (node?.type === "Person") {
                return (
                  <div key={index}>
                    <UserItem email={node.id} link={true} />
                    <IonItemDivider />
                  </div>
                );
              } else if (node?.type === "communityPost") {
                return (
                  <div key={index}>
                    <PostPreview beingShared={true} postID={node.id} />
                    <IonItemDivider />
                  </div>
                );
              } else {
                return <></>;
              }
            })}
          </IonList>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Tag;
