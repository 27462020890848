import { useParams } from "react-router";
import { useContext, useEffect, useState } from "react";
import {
  IonAvatar,
  IonBackButton,
  IonButton,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonText,
  useIonAlert,
  useIonRouter,
  useIonViewWillEnter,
} from "@ionic/react";
import { ellipsisVerticalSharp, personCircleSharp } from "ionicons/icons";
import { UserContext } from "../../lib/usercontext";
import PostMinInfin from "../../components/socialMedia/PostMinInfin";
import { EnvContext } from "../../lib/envcontext";
import "./Profile.css";
import { format, parseISO } from "date-fns";
import { LinkItUrl } from "react-linkify-it";
import GenericBackToolbar from "../../components/sitewide/GenericBackToolbar";
import ProfileInteractionsModal from "../../components/user/Modal/ProfileInteractionsModal";
import { retrieveUserBasic } from "../../lib/util";
import FollowersModal from "../../components/socialMedia/modal/FollowersModal";
import FollowingModal from "../../components/socialMedia/modal/FollowingModal";

const Profile = () => {
  //typescript
  const { id } = useParams<{ id: string }>();
  const router = useIonRouter();
  const { environment } = useContext(EnvContext);
  const [loading, setloading] = useState(true);
  const [userError, setuserError] = useState(false);
  const [profile, setProfile] = useState({} as any /*Person | Business*/);
  const [posts, setPosts] = useState([] as any[]);
  const [followers, setFollowers] = useState([] as any[]);
  const [following, setFollowing] = useState([] as any[]);
  const { user } = useContext(UserContext);
  const [pageUpdate, setPageUpdate] = useState<boolean>(false);
  const [interactionsModal, setInteractionsModal] = useState<boolean>(false);
  const [followersModal, setFollowersModal] = useState<boolean>(false);
  const [followingModal, setFollowingModal] = useState<boolean>(false);
  const [show] = useIonAlert();

  useEffect(() => {
    retrieveUserBasic({
      email: id,
      backendURL: environment.backendURL,
    }).then((data) => {
      if (data.error) {
        setuserError(true);
      } else {
        setProfile(data);
        setloading(false);
      }
    });
    fetch(
      `${environment.backendURL}/open/user/retrieveProfile/following/${id}`,
      {
        method: "GET",
        credentials: "include",
      }
    )
      .then((res) => res.json())
      .then((data) => setFollowing(data));
    fetch(
      `${environment.backendURL}/open/user/retrieveProfile/followers/${id}`,
      {
        method: "GET",
        credentials: "include",
      }
    )
      .then((res) => res.json())
      .then((data) => setFollowers(data));
    fetch(`${environment.backendURL}/open/user/retrieveProfile/posts/${id}`, {
      method: "GET",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => setPosts(data));
  }, [pageUpdate, id, user, environment.backendURL]);

  useIonViewWillEnter(() => {
    setPageUpdate(!pageUpdate);
  }, [pageUpdate]);

  return (
    <>
      <FollowersModal
        setModalOpen={setFollowersModal}
        modalOpen={followersModal}
        followers={followers}
      />
      <FollowingModal
        setModalOpen={setFollowingModal}
        modalOpen={followingModal}
        following={following}
      />
      {!loading ? (
        <div>
          <ProfileInteractionsModal
            profile={profile}
            setInteractionsModal={setInteractionsModal}
            interactionsModal={interactionsModal}
          />
          <IonPage id="main-content">
            <IonContent>
              <GenericBackToolbar name={profile.displayname} />
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonBackButton />
                  </IonCol>
                </IonRow>
                <IonRow class="ion-text-center ion-justify-content-center ion-align-items-center">
                  <IonCol>
                    {/* the Date.now is to prevent cacheing */}
                    {profile.email ? (
                      <IonAvatar>
                        <img
                          src={`${environment.storageURL}/photos/profile/${
                            profile.email
                          }.png?${Date.now()}`}
                          alt="profile"
                        />
                      </IonAvatar>
                    ) : null}
                  </IonCol>
                  <IonCol>
                    <IonText>
                      <div>
                        <b>{posts.length}</b> posts
                      </div>
                    </IonText>
                  </IonCol>
                  <IonCol>
                    <div
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setFollowersModal(true);
                      }}
                    >
                      <b>{followers.length}</b> followers
                    </div>
                  </IonCol>
                  <IonCol>
                    <div
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setFollowingModal(true);
                      }}
                    >
                      <b>{following.length}</b> following
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonRow>
                      <IonText>
                        <b>{profile.displayname}</b>
                      </IonText>
                    </IonRow>
                    <br />
                    <IonRow>
                      <IonText>
                        <b>{profile.realname}</b>
                      </IonText>
                    </IonRow>{" "}
                    <br />
                    <IonRow>
                      <IonText>
                        <b>{profile.location}</b>
                      </IonText>
                    </IonRow>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    {profile.type === "Person" ? (
                      <>
                        {" "}
                        {profile.bio !== "" ? (
                          <IonItem>
                            <LinkItUrl>
                              <IonText>
                                <div>{profile.bio}</div>
                              </IonText>
                            </LinkItUrl>
                          </IonItem>
                        ) : null}
                        {profile.dob && profile.dob !== "" && (
                          <IonItem>
                            {" "}
                            <IonText>
                              <div>
                                {format(
                                  parseISO(profile.dob.toString()),
                                  "MMM d, yyyy"
                                )}
                              </div>
                            </IonText>
                          </IonItem>
                        )}
                        <IonText>
                          {profile.tags !== undefined
                            ? profile.tags.map((tag: any, index: number) => {
                                return (
                                  <IonChip
                                    onClick={() => {
                                      router.push(`/tag/${tag}`);
                                    }}
                                    key={index}
                                  >
                                    <IonLabel>#{tag}</IonLabel>
                                  </IonChip>
                                );
                              })
                            : null}
                        </IonText>
                      </>
                    ) : (
                      <>
                        <IonItem>
                          <IonText>
                            <div>{profile.about}</div>
                          </IonText>
                        </IonItem>
                        <IonItem>
                          <IonText>
                            <div>{profile.businessType}</div>
                          </IonText>
                        </IonItem>
                        <IonItem>
                          <IonText>
                            <div>{profile.phone}</div>
                          </IonText>
                        </IonItem>
                      </>
                    )}

                    <IonText>
                      {profile.licences !== undefined &&
                      profile.licences.length > 0
                        ? JSON.parse(profile.licences).map(
                            (licence: any, index: number) => {
                              return (
                                <IonChip key={index}>
                                  <IonLabel>
                                    {licence.licenceType} -{" "}
                                    {licence.licenceNumber}
                                  </IonLabel>
                                </IonChip>
                              );
                            }
                          )
                        : null}
                    </IonText>
                  </IonCol>
                </IonRow>
                <IonRow className="interactions">
                  {/* if the profile belongs to logged in user, dont display interactions bar (follow, msg) */}
                  {user.user.email === profile.email ? (
                    <IonCol>
                      <IonButton
                        onClick={() => {
                          user.user.type === "person"
                            ? router.push("/account/editProfile")
                            : router.push("/account/editBusiness");
                        }}
                        expand="block"
                        color="medium"
                      >
                        Edit Profile
                      </IonButton>
                    </IonCol>
                  ) : (
                    <>
                      <IonCol>
                        {followers?.includes(user.user.email) ? (
                          <>
                            <IonButton
                              onClick={() => {
                                fetch(
                                  `${environment.backendURL}/open/user/profileinteractions/${profile.email}/unfollow`,
                                  {
                                    method: "POST",
                                    credentials: "include",
                                    headers: {
                                      "Content-Type": "application/json",
                                    },
                                    body: JSON.stringify({
                                      email: user.user.email,
                                    }),
                                  }
                                )
                                  .then((res) => res.json())
                                  .then((data) => {
                                    setPageUpdate(!pageUpdate);
                                  });
                              }}
                              color="light"
                              expand="block"
                            >
                              Unfollow
                            </IonButton>
                          </>
                        ) : (
                          <>
                            <IonButton
                              onClick={() => {
                                user.user.email
                                  ? fetch(
                                      `${environment.backendURL}/open/user/profileinteractions/${profile.email}/follow`,
                                      {
                                        method: "POST",
                                        credentials: "include",
                                        headers: {
                                          "Content-Type": "application/json",
                                        },
                                        body: JSON.stringify({
                                          email: user.user.email,
                                        }),
                                      }
                                    )
                                      .then((res) => res.json())
                                      .then((data) => {
                                        setPageUpdate(!pageUpdate);
                                      })
                                  : router.push("/Authenticate");
                              }}
                              color="primary"
                              expand="block"
                            >
                              Follow
                            </IonButton>
                          </>
                        )}
                      </IonCol>
                      <IonCol>
                        <IonButton
                          onClick={() => {
                            fetch(
                              `${environment.backendURL}/open/user/createconversation`,
                              {
                                method: "POST",
                                credentials: "include",
                                headers: {
                                  "Content-Type": "application/json",
                                },
                                body: JSON.stringify({
                                  participants: [
                                    user.user.email,
                                    profile.email,
                                  ],
                                }),
                              }
                            )
                              .then((res) => res.json())
                              .then((data) => {
                                console.log(data);
                                if (data.errors) {
                                  if (
                                    data.errors[0].msg ===
                                    "conversation already exists"
                                  ) {
                                    router.push(`/home/messages`);
                                    return;
                                  }
                                  show({
                                    header: "Error",
                                    message: data.errors[0].msg,
                                    buttons: ["OK"],
                                  });
                                  return;
                                }

                                router.push(
                                  `/Home/chat/${data.conversationID}`
                                );
                              });
                          }}
                          color="light"
                          expand="block"
                        >
                          Message
                        </IonButton>
                      </IonCol>
                      <IonCol>
                        <IonButton
                          fill="clear"
                          color={"dark"}
                          id="profileInteractions"
                          onClick={() => {
                            setInteractionsModal(true);
                          }}
                        >
                          <IonIcon
                            slot="icon-only"
                            icon={ellipsisVerticalSharp}
                          />
                        </IonButton>
                      </IonCol>
                    </>
                  )}
                </IonRow>
                {profile.private &&
                profile.email !== user.user.email &&
                !followers.includes(user.user.email) ? (
                  <IonRow>
                    <IonCol>
                      <IonText>
                        <p>
                          This profile is private. You must be following this
                          user to view their posts
                        </p>
                      </IonText>
                    </IonCol>
                  </IonRow>
                ) : (
                  <PostMinInfin posts={posts} />
                )}
              </IonGrid>
            </IonContent>
          </IonPage>
        </div>
      ) : (
        <div>
          <IonPage>
            <IonText>
              {userError ? (
                <IonPage>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100vh",
                    }}
                  >
                    <IonText>
                      <p>
                        Unfourtunately, we couldn't find the profile you were
                        looking for
                      </p>
                    </IonText>
                    <IonText>
                      <p>Please check the URL and try again.</p>
                    </IonText>
                    <IonIcon
                      style={{
                        fontSize: "10rem",
                      }}
                      icon={personCircleSharp}
                    />
                  </div>
                </IonPage>
              ) : (
                <></>
              )}
            </IonText>
          </IonPage>
        </div>
      )}
    </>
  );
};

export default Profile;
