import {
  IonAvatar,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonText,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import { useParams } from "react-router";
import GenericBackToolbar from "../../components/sitewide/GenericBackToolbar";
import "../../theme//standard.css";
import { NavBarVisibilityContext } from "../../lib/navbarvisibilitycontext";
import { useContext, useEffect, useRef, useState } from "react";
import { lockClosedSharp } from "ionicons/icons";
import { EnvContext } from "../../lib/envcontext";
import { JobProfileInterface } from "../../lib/types";
import { UserContext } from "../../lib/usercontext";
const JobProfile: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { setNavbarVisibility } = useContext(NavBarVisibilityContext);
  const { environment } = useContext(EnvContext);
  const { user } = useContext(UserContext);

  const [jobProfileData, setJobProfileData] =
    useState<JobProfileInterface | null>(null);

  useIonViewWillEnter(() => {
    setNavbarVisibility(false);
  }, [setNavbarVisibility]);
  useIonViewWillLeave(() => {
    setNavbarVisibility(true);
  }, [setNavbarVisibility]);

  useEffect(() => {
    fetch(`${environment.backendURL}/open/jobs/retrieveJobProfile/${id}`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        setJobProfileData(data);
      });
  }, []);

  return (
    <IonPage>
      <IonContent fullscreen>
        <GenericBackToolbar
          allwaysGoBackTo="/JobBoard"
          name={jobProfileData?.name ? jobProfileData?.name : ""}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IonCard className="mobileResizeBigger">
            <IonCardHeader>
              <IonItem>
                <IonAvatar
                  style={{
                    height: "7em",
                    width: "7em",
                  }}
                  slot="start"
                >
                  <img
                    src={`${environment.storageURL}/photos/profile/${
                      jobProfileData?.ownerEmail
                    }.png?${Date.now()}`}
                    alt="profile"
                  />{" "}
                </IonAvatar>
                <IonLabel>
                  <h1>{jobProfileData?.name}</h1>
                  <h2>{jobProfileData?.occupation}</h2>
                  <h2>
                    <i>{jobProfileData?.location}</i>
                  </h2>
                </IonLabel>
              </IonItem>
            </IonCardHeader>
            <IonCardContent>
              <IonCard>
                {user.user.email === jobProfileData?.ownerEmail ? (
                  <IonButton
                    routerLink={`/JobBoard/profile/${id}/editjobprofile`}
                    color={"primary"}
                    expand={"block"}
                  >
                    Edit Profile
                  </IonButton>
                ) : (
                  // <IonButton color={"primary"} expand={"block"}>
                  //   Hire Me!
                  // </IonButton>
                  <></>
                )}
              </IonCard>
              <IonGrid>
                <IonRow>
                  <IonCol size="12" sizeLg="8">
                    <IonCard
                      style={{
                        backgroundColor: "var(--ion-color-light)",
                        padding: "1em",
                      }}
                    >
                      <p
                        style={{
                          color: "var(--ion-color-dark)",
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        {jobProfileData?.about}
                      </p>
                    </IonCard>
                    <IonCard
                      style={{
                        backgroundColor: "var(--ion-color-light)",
                        padding: "1em",
                      }}
                    >
                      <IonCardHeader>
                        <IonCardTitle>Work History</IonCardTitle>
                      </IonCardHeader>
                      <IonCardContent
                        style={{
                          textAlign: "left",
                          color: "var(--ion-color-dark)",
                        }}
                      >
                        <ul>
                          {jobProfileData?.experience &&
                            JSON.parse(
                              JSON.parse(jobProfileData?.experience)
                            ).map(
                              (
                                job: {
                                  role: string;
                                  company: string;
                                  startDate: string;
                                  endDate: string;
                                  description: string;
                                },
                                index: number
                              ) => (
                                <div key={index}>
                                  <li>
                                    <h2>{job.role}</h2>
                                    <h3>{job.company}</h3>
                                    <p>
                                      {" "}
                                      {job.startDate} - {job.endDate}
                                    </p>
                                    <p>{job.description}</p>
                                  </li>
                                  <br />
                                </div>
                              )
                            )}
                        </ul>
                      </IonCardContent>
                    </IonCard>
                  </IonCol>
                  <IonCol size="12" sizeLg="4">
                    <IonCard
                      style={{
                        backgroundColor: "var(--ion-color-light)",
                      }}
                    >
                      <IonCardContent>
                        <IonCardHeader>
                          <IonCardTitle>Contact Details</IonCardTitle>
                        </IonCardHeader>
                        <IonText>
                          <p>
                            Phone Number:{" "}
                            {jobProfileData?.phone
                              ? jobProfileData?.phone
                              : "Not Provided"}
                          </p>
                        </IonText>
                      </IonCardContent>
                    </IonCard>
                    <IonCard
                      style={{
                        backgroundColor: "var(--ion-color-light)",
                        color: "var(--ion-color-dark)",
                      }}
                    >
                      <IonCardHeader>
                        <IonCardTitle>
                          Licences, Ceritifications and Education
                        </IonCardTitle>
                      </IonCardHeader>
                      <IonCardContent
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {/* <ul>
                          <li>
                            <h2>TAFE QLD</h2>
                            <p> Certificate III in Plumbing (CPC32413) </p>
                          </li>
                          <br />

                          <li>
                            <h2>University of Sydney</h2>
                            <p> Bachelor of Plumbing (Honours) (Toilets) </p>
                          </li>
                          <br />
                          <li>
                            <h2> University of Queensland</h2>
                            <p> Master of Plumbing (Kitchen Sinks) </p>
                          </li>
                          <br />
                          <li>
                            <h2> University of Melbourne</h2>
                            <p> PhD in Quantum Plumbing</p>
                          </li>
                        </ul> */}
                        <ul>
                          {jobProfileData?.education &&
                            JSON.parse(
                              JSON.parse(jobProfileData?.education)
                            ).map(
                              (
                                education: {
                                  institution: string;
                                  qualification: string;
                                },
                                index: number
                              ) => (
                                <div key={index}>
                                  <li>
                                    <h2>{education.institution}</h2>
                                    <p>{education.qualification}</p>
                                  </li>
                                  <br />
                                </div>
                              )
                            )}
                        </ul>
                      </IonCardContent>
                    </IonCard>
                    {/* <IonCard
                      style={{
                        backgroundColor: "var(--ion-color-light)",
                        color: "var(--ion-color-dark)",
                      }}
                    >
                      <IonCardHeader>
                        <IonCardTitle>Work Preferences</IonCardTitle>
                      </IonCardHeader>
                      <IonCardContent
                        style={{
                          textAlign: "left",
                        }}
                      >
                        <ul>
                          <li>Full Time</li>
                          <br />
                          <li>Contract</li>
                        </ul>
                      </IonCardContent>
                    </IonCard> */}
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCardContent>
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default JobProfile;
