import {
  IonAvatar,
  IonButton,
  IonCard,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonPage,
  IonSkeletonText,
  useIonRouter,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import { ellipseSharp, createSharp } from "ionicons/icons";
import { useContext, useState } from "react";
import GenericBackToolbar from "../../components/sitewide/GenericBackToolbar";
import { EnvContext } from "../../lib/envcontext";
import { UserContext } from "../../lib/usercontext";
import UserItem from "../../components/sitewide/UserItem";
import { ChatData, Participant } from "../../lib/types";
import TimeSincePosted from "../../components/sitewide/TimeSincePosted";
import { NavBarVisibilityContext } from "../../lib/navbarvisibilitycontext";
import "../../theme/standard.css";
const MyMessages: React.FC = () => {
  const { environment } = useContext(EnvContext);
  const user = useContext(UserContext).user;
  const [chatList, setChatList] = useState([] as ChatData[]);
  const router = useIonRouter();
  const { setNavbarVisibility } = useContext(NavBarVisibilityContext);

  useIonViewWillEnter(() => {
    fetch(`${environment.backendURL}/open/user/getchatlist`, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        setChatList(data);
      });
  }, [user.user.email, environment.backendURL, setChatList]);
  useIonViewWillEnter(() => {
    setNavbarVisibility(false);
  }, [setNavbarVisibility]);
  useIonViewWillLeave(() => {
    setNavbarVisibility(true);
  }, [setNavbarVisibility]);
  return (
    <IonPage>
      <GenericBackToolbar
        name="Messages"
        button={
          <IonButton
            id="newchat"
            onClick={() => {
              router.push("/Home/messages/newchat");
            }}
          >
            <IonIcon size="large" icon={createSharp} />
          </IonButton>
        }
      />
      <IonContent fullscreen>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "1em",
          }}
        >
          <div className="mobileResize">
            {chatList === null ? (
              <>
                <IonCard
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "0.5em",
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  {[...Array(4)].map((val, index) => (
                    <IonItem
                      key={index}
                      button
                      style={{
                        width: "100%",
                      }}
                    >
                      {" "}
                      <IonAvatar slot="start">
                        <IonSkeletonText animated={true}></IonSkeletonText>
                      </IonAvatar>
                      <IonLabel>
                        <IonSkeletonText animated={true}></IonSkeletonText>
                      </IonLabel>
                    </IonItem>
                  ))}
                </IonCard>
              </>
            ) : chatList.length ? (
              <IonCard
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  padding: "0.5em",
                  textAlign: "center",
                  height: "82vh",
                  overflowY: "auto",
                }}
              >
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  {chatList.map((chat: ChatData, index: number) => (
                    <IonItem
                      color={chat.unopened ? "medium" : "light"}
                      key={index}
                      onClick={() => {
                        router.push(`/Home/chat/${chat.conversationID}`);
                      }}
                      button
                      style={{
                        width: "100%",
                        marginBlock: "0.5em",
                      }}
                    >
                      {chat.users.length > 2 ? (
                        <div>
                          {chat.users
                            .filter(
                              (person: Participant) =>
                                person.email !== user.user.email
                            )
                            .map((user: Participant, index: number) => (
                              <IonAvatar
                                key={index}
                                style={{
                                  marginLeft: index === 0 ? "0" : "-2.5em",
                                }}
                                slot="start"
                              >
                                <img
                                  src={`${environment.storageURL}/photos/profile/${user.email}.png`}
                                  alt="avatar"
                                />
                              </IonAvatar>
                            ))}
                          <div>
                            <b>{chat.name}</b>
                          </div>
                        </div>
                      ) : (
                        <>
                          {chat.users
                            .filter(
                              (person: Participant) =>
                                person.email !== user.user.email
                            )
                            .map((user: Participant, index: number) => (
                              <div key={index}>
                                <UserItem
                                  color={chat.unopened ? "medium" : "light"}
                                  link={false}
                                  email={user.email}
                                />
                              </div>
                            ))}
                        </>
                      )}
                      {chat.lastMessage ? (
                        <>
                          {" "}
                          <i>
                            {" "}
                            &emsp;
                            {chat?.lastMessage?.message}
                          </i>
                          &emsp;
                          <i>
                            <TimeSincePosted
                              timestamp={chat?.lastMessage?.timestamp}
                            />
                          </i>
                        </>
                      ) : (
                        <i>No messages yet</i>
                      )}
                    </IonItem>
                  ))}
                </div>
              </IonCard>
            ) : (
              <IonCard
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "5em",
                  textAlign: "center",
                }}
              >
                <h1>No chats yet</h1>
                <p>Start a conversation with someone</p>
              </IonCard>
            )}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default MyMessages;
