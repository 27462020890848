import {
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonImg,
  IonText,
  useIonRouter,
} from "@ionic/react";
import React, { useContext, useEffect } from "react";
import { EnvContext } from "../../lib/envcontext";
import { PostData } from "../../lib/types";
import { retrievePost } from "../../lib/util";

interface PostMinProps {
  post: string;
}

const PostMin = ({ post }: PostMinProps) => {
  const router = useIonRouter();
  const { environment } = useContext(EnvContext);
  const [postData, setPostData] = React.useState({} as PostData);
  useEffect(() => {
    if (post) {
      retrievePost({
        id: post,
        backendURL: environment.backendURL,
      }).then((data) => {
        setPostData(data);
      });
    }
  }, [post, environment]);

  return (
    <div>
      <IonCard
        style={{
          cursor: "pointer",
          width: "20em",
          height: "20em",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        id={postData?.post?.postID}
        onClick={() => {
          router.push(`/post/${postData?.post?.postID}`);
        }}
      >
        {
          //make sure the post has loaded if so then render the post
          postData?.post?.photo &&
          JSON.stringify(postData?.post?.photo) !== "[]" ? (
            postData?.post?.photo?.[0].includes("mp4") ? (
              <video
                style={{
                  margin: "1rem",
                  maxHeight: "100%",
                  maxWidth: "100%",
                }}
                controls
                autoPlay
                loop
                muted
              >
                <source
                  src={`${environment.storageURL}/photos/${postData?.post?.photo[0]}`}
                  type="video/mp4"
                />
              </video>
            ) : (
              <IonImg
                src={`${environment.storageURL}/photos/${postData?.post?.photo[0]}`}
              />
            )
          ) : postData?.post?.title ? (
            <>
              <div
                style={{
                  maxWidth: "100%",
                }}
              >
                <IonCardContent>
                  <IonCardTitle>{postData?.post?.title} </IonCardTitle>

                  <IonText>
                    {postData?.post?.description.length < 150
                      ? postData?.post?.description
                      : postData?.post?.description.substring(0, 150) + "..."}
                  </IonText>
                </IonCardContent>
              </div>
            </>
          ) : (
            <></>
          )
        }
      </IonCard>
    </div>
  );
};

export default PostMin;
