import {
  IonContent,
  IonPage,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import GenericBackToolbar from "../../components/sitewide/GenericBackToolbar";
import { NavBarVisibilityContext } from "../../lib/navbarvisibilitycontext";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../lib/usercontext";
import { EnvContext } from "../../lib/envcontext";
import CampaignMin from "../../components/advertising/CampaignMin";

const ManageCampaigns: React.FC = () => {
  const { setNavbarVisibility } = useContext(NavBarVisibilityContext);
  const { user } = useContext(UserContext);
  const { environment } = useContext(EnvContext);

  const [campaigns, setCampaigns] = useState([] as any);
  useEffect(() => {
    fetch(`${environment.backendURL}/open/socialmedia/getmycampaigns`, {
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        setCampaigns(data);
      });
  }, [environment, user]);

  useIonViewWillEnter(() => {
    setNavbarVisibility(false);
  }, [setNavbarVisibility]);
  useIonViewWillLeave(() => {
    setNavbarVisibility(true);
  }, [setNavbarVisibility]);

  return (
    <IonPage>
      <IonContent fullscreen>
        <GenericBackToolbar name="My campaigns" />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          {campaigns.map((campaign: any, key: number) => {
            return <CampaignMin key={key} post={campaign} />;
          })}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ManageCampaigns;
