import {
  IonContent,
  IonPage,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import ExploreContainer from "../../components/sitewide/ExploreContainer";
import { useContext } from "react";
import { NavBarVisibilityContext } from "../../lib/navbarvisibilitycontext";

const EULA: React.FC = () => {
  const { setNavbarVisibility } = useContext(NavBarVisibilityContext);

  useIonViewWillEnter(() => {
    setNavbarVisibility(false);
  }, [setNavbarVisibility]);
  useIonViewWillLeave(() => {
    setNavbarVisibility(true);
  }, [setNavbarVisibility]);
  return (
    <IonPage>
      <IonContent fullscreen>
        <div>
          <h1>END USER LICENSE AGREEMENT (EULA) for Renos and Rebuilds</h1>

          <p>
            This End User License Agreement ("EULA") is a legal agreement
            between you (hereinafter referred to as "User") and SDL Technology,
            the developer of the website "Renos and Rebuilds" (hereinafter
            referred to as "Website"), and PBH Group, the copyright holder. This
            EULA governs your use of the Website and any related services
            provided.
          </p>

          <p>
            By accessing or using Renos and Rebuilds, you agree to be bound by
            the terms of this EULA. If you do not agree to the terms of this
            EULA, you may not use the Website.
          </p>

          <h2>1. LICENSE GRANT</h2>

          <p>
            SDL Technology and PBH Group grant you a non-exclusive,
            non-transferable, limited license to access and use Renos and
            Rebuilds for personal, non-commercial purposes in accordance with
            the terms of this EULA.
          </p>

          <h2>2. OBJECTIONABLE CONTENT AND ABUSIVE USERS</h2>

          <p>
            The User acknowledges and agrees that Renos and Rebuilds is intended
            to provide a safe and positive environment for all users.
            Objectionable content, including but not limited to offensive,
            harmful, or explicit material, as well as abusive behavior or
            harassment towards other users, will not be tolerated. SDL
            Technology and PBH Group reserve the right to, at their sole
            discretion, take appropriate actions against users engaging in such
            behavior, including but not limited to warnings, suspension, or
            termination of access.
          </p>

          <h2>3. INTELLECTUAL PROPERTY</h2>

          <p>
            All intellectual property rights related to Renos and Rebuilds,
            including but not limited to copyrights, trademarks, and patents,
            are owned by PBH Group. The User agrees not to reproduce,
            distribute, or create derivative works from any content found on
            Renos and Rebuilds without explicit written permission from PBH
            Group.
          </p>

          <h2>4. LIMITATIONS ON USE</h2>

          <p>The User shall not:</p>

          <ul>
            <li>
              Use Renos and Rebuilds for any illegal or unauthorized purpose.
            </li>
            <li>
              Attempt to gain unauthorized access to the Website or any of its
              services.
            </li>
            <li>Modify, adapt, or hack the Website or its related services.</li>
            <li>
              Reproduce, duplicate, copy, sell, resell, or exploit any portion
              of the Website.
            </li>
          </ul>

          <h2>5. DISCLAIMER OF WARRANTIES</h2>

          <p>
            The Website is provided "as is" and "as available" without
            warranties of any kind. SDL Technology and PBH Group make no
            warranties, express or implied, regarding the accuracy,
            completeness, or availability of Renos and Rebuilds.
          </p>

          <h2>6. LIMITATION OF LIABILITY</h2>

          <p>
            SDL Technology and PBH Group shall not be liable for any indirect,
            incidental, special, consequential, or punitive damages, including
            but not limited to loss of profits or data, arising out of or in
            connection with the use or inability to use Renos and Rebuilds.
          </p>

          <h2>7. TERMINATION</h2>

          <p>
            SDL Technology and PBH Group reserve the right, at their sole
            discretion, to terminate or suspend your access to Renos and
            Rebuilds at any time for any reason.
          </p>

          <h2>8. MISCELLANEOUS</h2>

          <p>
            a. This EULA constitutes the entire agreement between the User and
            SDL Technology and PBH Group concerning the subject matter hereof.
            <br />
            b. Any failure by SDL Technology or PBH Group to exercise or enforce
            any right or provision of this EULA shall not constitute a waiver of
            such right or provision.
            <br />
            c. If any provision of this EULA is found by a court of competent
            jurisdiction to be invalid, the parties nevertheless agree that the
            court should endeavor to give effect to the parties' intentions as
            reflected in the provision, and the other provisions of this EULA
            remain in full force and effect.
          </p>

          <h2>9. CONTACT INFORMATION</h2>

          <p>
            If you have any questions or concerns regarding this EULA, please
            contact us at{" "}
            <a href="mailto:contact@renosandrebuilds.com">
              contact@renosandrebuilds.com
            </a>
            .
          </p>

          <p>
            By using Renos and Rebuilds, you acknowledge that you have read,
            understood, and agreed to the terms and conditions of this EULA.
          </p>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default EULA;
