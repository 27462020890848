import {
  IonButton,
  IonCard,
  IonChip,
  IonContent,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonNote,
  IonPage,
  IonRow,
  useIonAlert,
  useIonRouter,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import { closeSharp } from "ionicons/icons";
import { useContext, useState } from "react";
import GenericBackToolbar from "../../components/sitewide/GenericBackToolbar";
import { EnvContext } from "../../lib/envcontext";
import { UserContext } from "../../lib/usercontext";
import UserSearch from "../../components/sitewide/UserSearch";
import { Participant } from "../../lib/types";
import { NavBarVisibilityContext } from "../../lib/navbarvisibilitycontext";
const Conversation: React.FC = () => {
  const { user } = useContext(UserContext);
  const { environment } = useContext(EnvContext);
  const [recipients, setRecipients] = useState([] as Array<Participant>);
  const [groupName, setGroupName] = useState("");
  const [show] = useIonAlert();
  const { setNavbarVisibility } = useContext(NavBarVisibilityContext);
  const router = useIonRouter();
  useIonViewWillEnter(() => {
    setNavbarVisibility(false);
  }, [setNavbarVisibility]);
  useIonViewWillLeave(() => {
    setNavbarVisibility(true);
  }, [setNavbarVisibility]);
  useIonViewWillEnter(() => {
    setRecipients([]);
  }, [setRecipients]);
  return (
    <IonPage>
      <IonContent fullscreen>
        <GenericBackToolbar name="Create Chat" />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "1em",
          }}
        >
          <IonCard
            style={{
              padding: "1em",
              height: "70vh",
              overflowY: "auto",
            }}
          >
            <div>
              <IonRow>
                {recipients &&
                  recipients.map((recipient: any, index) => {
                    return (
                      <IonChip
                        key={index}
                        style={{
                          cursor: "default",
                        }}
                      >
                        <IonLabel>{recipient.displayname}</IonLabel>
                        <IonIcon
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setRecipients(
                              recipients.filter(
                                (r: any) => r.email !== recipient.email
                              )
                            );
                          }}
                          icon={closeSharp}
                        />
                      </IonChip>
                    );
                  })}
              </IonRow>
            </div>
            {recipients && recipients.length > 1 && (
              <IonItem
                className={
                  groupName.length <= 3 || groupName.length >= 100
                    ? "ion-invalid"
                    : "ion-valid"
                }
              >
                <IonInput
                  debounce={500}
                  id="GroupName"
                  onIonChange={(e: any) => {
                    setGroupName(e.detail.value!);
                  }}
                  placeholder="Group Name"
                />
                <IonNote
                  style={{
                    width: "15em",
                  }}
                  slot="error"
                >
                  Must have more than 3 characters
                </IonNote>
              </IonItem>
            )}
            {recipients && recipients.length > 0 && (
              <IonButton
                id="createChat"
                disabled={
                  (recipients.length > 1 && groupName.length <= 3) ||
                  groupName.length >= 100
                }
                onClick={() => {
                  fetch(
                    `${environment.backendURL}/open/user/createconversation`,
                    {
                      method: "POST",
                      credentials: "include",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({
                        participants: [
                          {
                            email: user.user.email,
                            displayname: user.user.displayname,
                          },
                          ...recipients,
                        ].map((r: any) => r.email),
                        groupname: groupName,
                      }),
                    }
                  )
                    .then((res) => res.json())
                    .then((data) => {
                      if (data.errors) {
                        show({
                          header: "Error",
                          message: data.errors[0].msg,
                          buttons: ["OK"],
                        });
                        return;
                      }
                      router.push(`/Home/chat/${data.conversationID}`);
                    });
                }}
                fill="solid"
                style={{
                  width: "100%",
                }}
              >
                Next
              </IonButton>
            )}
            <UserSearch setUsers={setRecipients} users={recipients} />
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Conversation;
