import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonGrid,
  IonInput,
  IonItem,
  IonPage,
  IonRow,
  useIonAlert,
  useIonLoading,
  useIonRouter,
} from "@ionic/react";
import { useContext, useState } from "react";
import { UserContext } from "../../lib/usercontext";
import { EnvContext } from "../../lib/envcontext";
import axios from "axios";
import GenericBackToolbar from "../../components/sitewide/GenericBackToolbar";
import { useParams } from "react-router";
const BusinessLogin = () => {
  const { setUser } = useContext(UserContext);
  const { environment } = useContext(EnvContext);
  const router = useIonRouter();
  const [presentAlert] = useIonAlert();
  const [show, hide] = useIonLoading();
  const { id } = useParams<{ id: string }>();

  const [password, setPassword] = useState("");
  return (
    <IonPage>
      <GenericBackToolbar name="Business Login" />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <IonCard className="loginCard">
          <IonCardHeader>
            <IonCardTitle>Login</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonGrid>
              <IonRow>
                <IonItem>{id}</IonItem>
              </IonRow>
              <IonRow>
                <IonItem>
                  <IonInput
                    label="Password"
                    type="password"
                    autocomplete="current-password"
                    labelPlacement="floating"
                    onIonChange={(e: any) => {
                      setPassword(e.detail.value);
                    }}
                  />{" "}
                </IonItem>
              </IonRow>
              <br />
              <IonRow>
                <IonButton disabled={password.length === 0 ? true : false}>
                  Login
                </IonButton>
              </IonRow>
              <br />
            </IonGrid>
          </IonCardContent>
        </IonCard>
      </div>
    </IonPage>
  );
};

export default BusinessLogin;
