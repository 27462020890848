import { useContext, useEffect, useState } from "react";
import { EnvContext } from "../../lib/envcontext";
import UserItem from "../sitewide/UserItem";
import { IonCard, IonRow } from "@ionic/react";
import ModerationActionsMenu from "./ModerationActionsMenu";
import { Message } from "../../lib/types";
interface MessagePreviewProps {
  messageID: string;
  reportID: string;
  reason: string;
}

const ModMessagePreview: React.FC<MessagePreviewProps> = ({
  messageID,
  reportID,
  reason,
}) => {
  const { environment } = useContext(EnvContext);
  const [hidden, setHidden] = useState(false);
  const [messageData, setMessageData] = useState({} as Message);
  useEffect(() => {
    fetch(`${environment.backendURL}/open/user/getMessage/${messageID}`, {
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        setMessageData(data);
      });
  }, [environment, messageID]);
  return (
    <>
      {!hidden && (
        <IonCard
          style={{
            padding: "1rem",
          }}
        >
          <UserItem link={true} email={messageData?.message?.senderEmail} />
          <p>Message: {messageData?.message?.message}</p>
          <IonRow>Reason for report: {reason}</IonRow>
          <br />
          <ModerationActionsMenu
            email={messageData?.message?.senderEmail}
            postID={messageID}
            reportID={reportID}
            setHidden={setHidden}
          />
        </IonCard>
      )}
    </>
  );
};

export default ModMessagePreview;
