import {
  IonContent,
  IonPage,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import { useContext } from "react";
import { NavBarVisibilityContext } from "../../lib/navbarvisibilitycontext";

const Privacy: React.FC = () => {
  const { setNavbarVisibility } = useContext(NavBarVisibilityContext);

  useIonViewWillEnter(() => {
    setNavbarVisibility(false);
  }, [setNavbarVisibility]);
  useIonViewWillLeave(() => {
    setNavbarVisibility(true);
  }, [setNavbarVisibility]);
  return (
    <IonPage>
      <IonContent fullscreen>
        <div
          style={{
            //top padding 1, bottom padding 1, left padding 5, right padding 5
            padding: "1rem 10rem 1rem 10rem",
          }}
        >
          <h1>WEBSITE PRIVACY POLICY</h1>
          <p>
            This Privacy Policy applies to all personal information collected by
            Renos&amp;Rebuilds PTY LTD via the website located at{" "}
            <a href="http://renosandrebuilds.com">
              http://renosandrebuilds.com
            </a>
            .
          </p>

          <h2>What is "personal information"?</h2>
          <p>
            The Privacy Act 1988 (Cth) currently defines "personal information"
            as meaning information or an opinion about an identified individual
            or an individual who is reasonably identifiable: whether the
            information or opinion is true or not; and whether the information
            or opinion is recorded in a material form or not. If the information
            does not disclose your identity or enable your identity to be
            ascertained, it will in most cases not be classified as "personal
            information" and will not be subject to this privacy policy.
          </p>

          <h2>What information do we collect?</h2>
          <p>
            The kind of personal information that we collect from you will
            depend on how you use the website. The personal information which we
            collect and hold about you may include: Email, Mobile phone number,
            username and password, location (city, town, state, postcode).
          </p>

          <h2>How we collect your personal information</h2>
          <p>
            We may collect personal information from you whenever you input such
            information into the website. We also collect cookies from your
            computer which enable us to tell when you use the website and also
            to help customise your website experience. As a general rule,
            however, it is not possible to identify you personally from our use
            of cookies.
          </p>

          <h2>Purpose of collection</h2>
          <p>
            The purpose for which we collect personal information is to provide
            you with the best service experience possible on the website. We
            customarily disclose personal information only to our service
            providers who assist us in operating the website. Your personal
            information may also be exposed from time to time to maintenance and
            support personnel acting in the normal course of their duties. By
            using our website, you consent to the receipt of direct marketing
            material. We will only use your personal information for this
            purpose if we have collected such information direct from you, and
            if it is material of a type which you would reasonably expect to
            receive from use. We do not use sensitive personal information in
            direct marketing activity. Our direct marketing material will
            include a simple means by which you can request not to receive
            further communications of this nature.
          </p>

          <h2>Access and correction</h2>
          <p>
            Australian Privacy Principle 12 permits you to obtain access to the
            personal information we hold about you in certain circumstances, and
            Australian Privacy Principle 13 allows you to correct inaccurate
            personal information subject to certain exceptions. If you would
            like to obtain such access, please contact us as set out below.
          </p>

          <h2>Complaint procedure</h2>
          <p>
            If you have a complaint concerning the manner in which we maintain
            the privacy of your personal information, please contact us as set
            out below. All complaints will be considered by all members of
            Renos&amp;Rebuilds PTY LTD and we may seek further information from
            you to clarify your concerns. If we agree that your complaint is
            well founded, we will, in consultation with you, take appropriate
            steps to rectify the problem. If you remain dissatisfied with the
            outcome, you may refer the matter to the Office of the Australian
            Information Commissioner.
          </p>

          <h2>Overseas transfer</h2>
          <p>
            Your personal information will not be disclosed to recipients
            outside Australia unless you expressly request us to do so. If you
            request us to transfer your personal information to an overseas
            recipient, the overseas recipient will not be required to comply
            with the Australian Privacy Principles and we will not be liable for
            any mishandling of your information in such circumstances.
          </p>

          <h2>How to contact us about privacy</h2>
          <p>
            If you have any queries, or if you seek access to your personal
            information, or if you have a complaint about our privacy practices,
            you can contact us through:{" "}
            <a href="mailto:admin@renosandrebuilds.com">
              admin@renosandrebuilds.com
            </a>
            .
          </p>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Privacy;
