import {
  IonButton,
  IonContent,
  IonPage,
  IonText,
  IonTitle,
  useIonRouter,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import GenericBackToolbar from "../../components/sitewide/GenericBackToolbar";
import { NavBarVisibilityContext } from "../../lib/navbarvisibilitycontext";
import { useContext } from "react";

const AdvertisingOnboard: React.FC = () => {
  const { setNavbarVisibility } = useContext(NavBarVisibilityContext);
  const router = useIonRouter();

  useIonViewWillEnter(() => {
    setNavbarVisibility(false);
  }, [setNavbarVisibility]);
  useIonViewWillLeave(() => {
    setNavbarVisibility(true);
  }, [setNavbarVisibility]);
  return (
    <IonPage>
      <IonContent fullscreen>
        <GenericBackToolbar name="Advertising" />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <IonText
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "70%",
              textAlign: "center",
            }}
          >
            <IonTitle>
              <h1>Advertising with R&R</h1>
            </IonTitle>
            <p>
              R&R is a great place to advertise your business. We have a large
              and active user base, and we are always looking for new ways to
              help our users find the best deals and services in their area.
              Start advertising with us by clicking the button below.
            </p>
          </IonText>
          <IonButton
            onClick={() => {
              router.push("/Advertising/NewCampaign");
            }}
          >
            Create an Ad
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default AdvertisingOnboard;
