import {
  IonButton,
  IonButtons,
  IonCard,
  IonIcon,
  IonList,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import "../../../theme/standard.css";
import UserItem from "../../sitewide/UserItem";
import { chevronBackSharp } from "ionicons/icons";
interface FollowersModalProps {
  setModalOpen: (open: boolean) => void;
  modalOpen: boolean;
  following: string[];
}
const FollowingModal: React.FC<FollowersModalProps> = ({
  setModalOpen,
  modalOpen,
  following,
}) => {
  return (
    <IonModal
      onDidDismiss={() => {
        setModalOpen(false);
      }}
      isOpen={modalOpen}
    >
      <IonToolbar>
        <IonButtons slot="start">
          <IonButton
            onClick={() => {
              setModalOpen(false);
            }}
          >
            <IonIcon icon={chevronBackSharp} />
          </IonButton>
        </IonButtons>
        <IonTitle>
          {following.length > 0 ? following.length : "No"} people following
        </IonTitle>
      </IonToolbar>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",

          height: "100%",
        }}
      >
        <IonCard
          style={{
            width: "100%",
            overflowY: "auto",
          }}
        >
          <IonList
            style={{
              width: "100%",
              overflowY: "auto",
              marginBottom: "5em",
            }}
          >
            {following.length > 0 ? (
              following?.map((follower: string, index) => (
                <UserItem key={index} link={true} email={follower} />
              ))
            ) : (
              <p>No one following</p>
            )}
          </IonList>
        </IonCard>
      </div>
    </IonModal>
  );
};

export default FollowingModal;
