import {
  IonButton,
  IonCard,
  IonIcon,
  IonRow,
  IonSpinner,
  IonText,
  useIonRouter,
} from "@ionic/react";
import { ellipsisVerticalSharp, heartSharp } from "ionicons/icons";
import { useContext, useEffect, useState } from "react";
import { EnvContext } from "../../lib/envcontext";
import { UserContext } from "../../lib/usercontext";
import CommentViewerModal from "./modal/CommentViewerModal";
import CommentOwnerModal from "./modal/CommentOwnerModal";
import {
  CommentData,
  Person,
  childCommentData,
  repliedtoCommentData,
  replyingTo,
} from "../../lib/types";
import RichText from "../sitewide/RichText";
import TimeSincePosted from "../sitewide/TimeSincePosted";
import MediaZoom from "../sitewide/MediaZoom";
import { BlockingsContext } from "../../lib/blockingscontextprovider";
import { retrieveComment } from "../../lib/util";
interface CommentProps {
  comment: CommentData;
  repliedtoCommentData: repliedtoCommentData;
  setReplyingTo: React.Dispatch<React.SetStateAction<replyingTo>>;
  children: childCommentData[];
}

const RichComment = ({
  comment,
  setReplyingTo,
  children,
  repliedtoCommentData,
}: CommentProps) => {
  const [showChildren, setShowChildren] = useState(false);
  const [commentData, setCommentData] = useState({} as CommentData);
  const [writerData, setWriterData] = useState({} as Person);
  const [childrenComments, setChildrenComments] = useState(children);
  const [liked, setLiked] = useState(false);
  const { user } = useContext(UserContext);
  const { environment } = useContext(EnvContext);
  const [viewerModal, setViewerModal] = useState(false);
  const [ownerModal, setOwnerModal] = useState(false);
  const [visible, setVisible] = useState(true);
  const router = useIonRouter();
  const { blockings } = useContext(BlockingsContext);

  useEffect(() => {
    retrieveComment({
      id: comment.postID,
      backendURL: environment.backendURL,
    }).then((data) => {
      if (blockings.includes(data.email)) {
        setVisible(false);
      }
      setCommentData(data);
      setLiked(data?.likes?.includes(user?.user?.email) ? true : false);

      fetch(
        `${environment.backendURL}/open/user/retrieveProfile/basic/${data?.email}`,
        {
          credentials: "include",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setWriterData(data);
        });
    });
  }, [environment, user, comment.postID]);

  useEffect(() => {
    if (repliedtoCommentData?.parentID === comment.postID) {
      if (childrenComments.length === 0) {
        setChildrenComments([
          {
            postID: repliedtoCommentData.newID,
            timestamp: Date.now(),
            children: [],
          },
        ]);
      } else {
        //put new comment at index 0
        const temp = [
          {
            postID: repliedtoCommentData.newID,
            timestamp: Date.now(),
            children: [],
          },
          ...childrenComments,
        ];
        setChildrenComments([]);
        //delay to prevent rerendering issues
        setTimeout(() => {
          setChildrenComments(temp);
        }, 100);
      }
      setShowChildren(true);
    }
  }, [repliedtoCommentData, comment.postID]);

  return (
    <>
      <CommentOwnerModal
        comment={commentData}
        ownerModal={ownerModal}
        setOwnerModal={setOwnerModal}
        setVisible={setVisible}
      />
      <CommentViewerModal
        comment={commentData}
        viewerModal={viewerModal}
        setViewerModal={setViewerModal}
      />

      {visible && (
        <>
          <IonCard
            style={{
              padding: "0.2rem",
              width: "100%",
            }}
          >
            <>
              <IonRow
                style={{
                  fontSize: "0.8rem",
                }}
              >
                <IonRow
                  style={{
                    width: "100%",
                    whiteSpace: "pre-wrap",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <IonText
                    onClick={() => {
                      router.push(`/home/profile/${commentData?.email}`);
                    }}
                    style={{
                      cursor: "pointer",
                      padding: "0.2rem",
                    }}
                  >
                    {writerData?.realname
                      ? writerData?.realname
                      : writerData?.displayname}
                  </IonText>
                  {
                    // if the post is the user's post, show the three dots menu
                    writerData?.email === user.user.email ? (
                      <IonButton
                        id="OwnerCommentmenuButton"
                        fill="clear"
                        color={"dark"}
                        onClick={() => {
                          setOwnerModal(true);
                        }}
                      >
                        <IonIcon
                          slot="icon-only"
                          icon={ellipsisVerticalSharp}
                        />
                      </IonButton>
                    ) : (
                      <IonButton
                        fill="clear"
                        color={"dark"}
                        id="ViewerCommentMenuButton"
                        onClick={() => {
                          setViewerModal(true);
                        }}
                      >
                        <IonIcon
                          slot="icon-only"
                          icon={ellipsisVerticalSharp}
                        />
                      </IonButton>
                    )
                  }
                </IonRow>
                <IonRow
                  style={{
                    padding: "1rem",
                  }}
                >
                  <RichText text={commentData?.message} />
                  <IonRow>
                    {commentData.photo != null &&
                      JSON.stringify(commentData.photo) !== "[]" &&
                      commentData.photo.map((photo, index) => {
                        return (
                          <div key={index}>
                            <IonCard
                              style={{
                                width: "5em",
                                height: "5em",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <MediaZoom
                                small={true}
                                src={`${environment.storageURL}/photos/${photo}`}
                              />
                            </IonCard>
                          </div>
                        );
                      })}
                  </IonRow>
                </IonRow>
              </IonRow>
              <IonRow
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <IonText>
                  {commentData?.timestamp !== undefined && (
                    <TimeSincePosted timestamp={commentData?.timestamp} />
                  )}
                </IonText>
                <IonButton
                  onClick={() => {
                    setReplyingTo({
                      postID: commentData?.postID,
                      email: commentData?.email,
                    });
                  }}
                  style={{
                    fontSize: "0.7em",
                    fontWeight: "normal",
                  }}
                  color={"dark"}
                  size={"small"}
                  fill="clear"
                >
                  reply
                </IonButton>
                {writerData?.email !== user.user.email && (
                  <IonButton
                    fill="clear"
                    id={liked ? "commentLikeTrue" : "CommentLikeFalse"}
                    onClick={() => {
                      fetch(
                        `${
                          environment.backendURL
                        }/open/socialmedia/commentinteractions/${
                          comment.postID
                        }/${liked ? "unlike" : "like"}`,
                        {
                          method: "POST",
                          credentials: "include",
                          headers: {
                            "Content-Type": "application/json",
                          },
                          body: JSON.stringify({
                            email: user?.user?.email,
                          }),
                        }
                      ).then(() => {
                        setLiked(!liked);
                      });
                    }}
                  >
                    <IonIcon
                      color={liked ? "primary" : "dark"}
                      slot="icon-only"
                      icon={heartSharp}
                    />
                  </IonButton>
                )}
              </IonRow>
              <>
                {childrenComments.length !== 0 && (
                  <IonButton
                    color={"dark"}
                    size={"small"}
                    style={{
                      fontSize: "0.7em",
                      fontWeight: "normal",
                    }}
                    fill="clear"
                    onClick={() => {
                      setShowChildren(!showChildren);
                      setReplyingTo({} as replyingTo);
                    }}
                  >
                    {showChildren ? "hide" : "show"} replies
                  </IonButton>
                )}
                {showChildren && (
                  <>
                    {childrenComments.length === 0 ? (
                      <IonSpinner name="dots"></IonSpinner>
                    ) : (
                      <IonRow>
                        {childrenComments.map((comment: any, index: number) => (
                          <RichComment
                            key={index}
                            comment={comment}
                            setReplyingTo={setReplyingTo}
                            children={comment.children}
                            repliedtoCommentData={repliedtoCommentData}
                          />
                        ))}
                      </IonRow>
                    )}
                  </>
                )}
              </>
            </>
          </IonCard>
        </>
      )}
    </>
  );
};

export default RichComment;
