import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonSearchbar,
  IonText,
  useIonAlert,
  useIonLoading,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import "../../theme/standard.css";
import { trashBinSharp } from "ionicons/icons";
import { useContext, useEffect, useState } from "react";
import GenericBackToolbar from "../../components/sitewide/GenericBackToolbar";
import LocationSearch from "../../components/sitewide/LocationSearch";
import PhotoUpload from "../../components/sitewide/PhotoUpload";
import { EnvContext } from "../../lib/envcontext";
import { UserContext } from "../../lib/usercontext";
import { retrieveUserBasic } from "../../lib/util";
import { emailDisplayname, location } from "../../lib/types";
import UserItem from "../../components/sitewide/UserItem";
import { NavBarVisibilityContext } from "../../lib/navbarvisibilitycontext";

const EditProfile: React.FC = () => {
  const [photo, setPhoto] = useState([]);
  const { user, setUser } = useContext(UserContext);
  const [presentAlert] = useIonAlert();
  const { environment } = useContext(EnvContext);
  const [tempLicence, setTempLicence] = useState({
    licenceType: "",
    licenceNumber: "",
  });
  const [businessType, setBusinessType] = useState("");
  const [about, setAbout] = useState("");
  const [phone, setPhone] = useState("");
  const [licences, setLicences] = useState<
    { licenceType: string; licenceNumber: string }[]
  >([]);

  const [location, setLocation] = useState({} as location);
  const [ownerProfiles, setOwnerProfiles] = useState<string[]>([] as string[]);
  const [errors, setErrors] = useState({
    businessType: false,
    about: false,
    phone: false,
  });
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [searchResults, setSearchResults] = useState(<></>);
  const [present, dismiss] = useIonLoading();
  const fetchUsers = async (name: string) => {
    if (name.length > 1) {
      const response = await fetch(
        `${environment.backendURL}/open/user/search/${name}`,
        {
          credentials: "include",
        }
      );
      const data = await response.json();
      //map search results to display in search results list
      setSearchResults(
        data.map((result: emailDisplayname, index: number) => {
          return (
            <div
              key={index}
              onClick={() => {
                setSearchResults(<></>);

                //set owner email if not already in list
                if (!ownerProfiles.includes(result.email)) {
                  setOwnerProfiles([...ownerProfiles, result.email]);
                  setSaveDisabled(false);
                  presentAlert({
                    header: "Owner Added",
                    message: `${result.email} has been added as an owner, they can now sign in as this business. Please note they must use the password that this  business was created with.`,
                    buttons: ["OK"],
                  });
                }
              }}
            >
              <UserItem email={result.email} link={false} />
            </div>
          );
        })
      );
    }
  };
  useEffect(() => {
    if (
      errors.businessType ||
      errors.about ||
      errors.phone ||
      !businessType ||
      !phone ||
      !location
    ) {
      setSaveDisabled(true);
    } else {
      setSaveDisabled(false);
    }
  }, [location, businessType, about, phone]);

  useEffect(() => {
    if (user?.user) {
      retrieveUserBasic({
        email: user?.user?.email,
        backendURL: environment.backendURL,
      }).then((data: any) => {
        setLocation({
          lat: data.point.y,
          lon: data.point.x,
          name: data.location,
        });
        setBusinessType(data.businessType);
        setAbout(data.about);
        setPhone(data.phone);
        setLicences(
          data?.licences.length ? JSON.parse(data.licences) : ([] as any[])
        );
      });
      fetch(
        `${environment.backendURL}/open/user/retrieveProfile/ownerProfiles`,
        {
          credentials: "include",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setOwnerProfiles(data);
        });
    }
  }, [environment, user?.user?.email]);
  const { setNavbarVisibility } = useContext(NavBarVisibilityContext);

  useIonViewWillEnter(() => {
    setNavbarVisibility(false);
  }, [setNavbarVisibility]);
  useIonViewWillLeave(() => {
    setNavbarVisibility(true);
  }, [setNavbarVisibility]);
  return (
    <IonPage>
      <GenericBackToolbar name="Edit Business" />
      <IonContent>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <IonCard className="mobileResize">
            <IonRow>
              <IonItem>
                <IonInput
                  label="Business Type"
                  labelPlacement="floating"
                  className={`${!errors.businessType && "ion-valid"} ${
                    errors.businessType && "ion-invalid"
                  } ${"ion-touched"}`}
                  errorText="Must be between 3 and 100 characters"
                  debounce={500}
                  value={businessType}
                  name="businessType"
                  onIonChange={(e: any) => {
                    if (
                      (e.detail.value.length > 3 &&
                        e.detail.value.length < 100) ||
                      e.detail.value === ""
                    ) {
                      setErrors({ ...errors, businessType: false });
                      setBusinessType(e.detail.value);
                    } else {
                      setErrors({ ...errors, businessType: true });
                      setBusinessType(e.detail.value);
                    }
                  }}
                />{" "}
              </IonItem>
            </IonRow>
            <IonRow>
              <IonItem>
                <IonInput
                  label="About"
                  labelPlacement="floating"
                  name="About"
                  className={`${!errors.about && "ion-valid"} ${
                    errors.about && "ion-invalid"
                  } ${"ion-touched"}`}
                  errorText="Must be less than 100 characters"
                  value={about}
                  debounce={500}
                  onIonChange={(e: any) => {
                    if (e.detail.value.length < 100) {
                      setErrors({ ...errors, about: false });
                      setAbout(e.detail.value);
                    } else {
                      setErrors({ ...errors, about: true });
                      setAbout(e.detail.value);
                    }
                  }}
                />{" "}
              </IonItem>
            </IonRow>
            <IonRow>
              <IonItem>
                <IonInput
                  label="Phone"
                  labelPlacement="floating"
                  name="Phone"
                  className={`${!errors.phone && "ion-valid"} ${
                    errors.phone && "ion-invalid"
                  } ${"ion-touched"}`}
                  value={phone}
                  errorText="Please enter a valid number"
                  debounce={500}
                  onIonChange={(e: any) => {
                    if (e.detail.value.length < 100 || e.detail.value === "") {
                      setErrors({ ...errors, phone: false });
                      setPhone(e.detail.value);
                    } else {
                      setErrors({ ...errors, phone: true });
                      setPhone(e.detail.value);
                    }
                  }}
                />{" "}
              </IonItem>
            </IonRow>
            <br />
            <LocationSearch
              location={location}
              setLocation={setLocation}
              disableSave={setSaveDisabled}
            />
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>Manage Business Owners</IonCardTitle>
              </IonCardHeader>

              <IonCardContent>
                <IonItem>
                  {ownerProfiles.map((owner: any, index: number) => (
                    <IonChip
                      color={"danger"}
                      onClick={() => {
                        if (ownerProfiles.length > 1) {
                          presentAlert({
                            header: "Remove Owner",
                            message: `Are you sure you want to remove ${owner} from your business?`,
                            buttons: [
                              "Cancel",
                              {
                                text: "Remove",
                                handler: () => {
                                  setOwnerProfiles(
                                    ownerProfiles.filter(
                                      (o: any) => o !== owner
                                    )
                                  );
                                },
                              },
                            ],
                          });
                        } else {
                          presentAlert({
                            header: "Remove Owner",
                            message: `You must have at least one owner`,
                            buttons: ["Ok"],
                          });
                        }
                      }}
                      key={index}
                    >
                      <IonLabel>{owner}</IonLabel>
                    </IonChip>
                  ))}
                </IonItem>
                <IonItem>
                  <IonSearchbar
                    onIonChange={(e: any) => {
                      if (e.detail.value === "") {
                        setSearchResults(<></>);
                      }
                      fetchUsers(e.detail.value);
                    }}
                    debounce={500}
                    placeholder="Enter Username"
                  ></IonSearchbar>
                  {searchResults ? (
                    <IonList>{searchResults}</IonList>
                  ) : (
                    <IonItem>
                      <IonLabel>
                        <h2>No Results</h2>
                        <h3>Please enter a valid username into the field</h3>
                      </IonLabel>
                    </IonItem>
                  )}
                </IonItem>
              </IonCardContent>
            </IonCard>
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>Licences</IonCardTitle>
              </IonCardHeader>
              <IonItem>
                <IonGrid>
                  <IonRow>
                    <IonCol>
                      <IonItem>
                        <IonInput
                          label="Licence name"
                          labelPlacement="floating"
                          name="licence name"
                          value={tempLicence.licenceType}
                          onIonChange={(e: any) =>
                            setTempLicence({
                              ...tempLicence,
                              licenceType: e.detail.value,
                            })
                          }
                        />
                      </IonItem>
                      <IonItem>
                        <IonInput
                          label="Licence number"
                          labelPlacement="floating"
                          name="licence number"
                          value={tempLicence.licenceNumber}
                          onIonChange={(e: any) =>
                            setTempLicence({
                              ...tempLicence,
                              licenceNumber: e.detail.value,
                            })
                          }
                          type="number"
                        ></IonInput>
                      </IonItem>
                      {tempLicence.licenceNumber !== "" &&
                      tempLicence.licenceType !== "" ? (
                        <IonButton
                          onClick={() => {
                            licences.push(tempLicence);
                            setTempLicence({
                              licenceType: "",
                              licenceNumber: "",
                            });
                          }}
                        >
                          Add
                        </IonButton>
                      ) : (
                        <></>
                      )}
                    </IonCol>
                    <IonCol>
                      <IonList>
                        {licences !== undefined &&
                        JSON.stringify(licences) !== "[]" ? (
                          licences.map((licence, key) => (
                            <IonCard
                              style={{
                                width: "50%",
                              }}
                              key={key}
                            >
                              <IonItem>
                                <IonText>
                                  {licence.licenceType} -{" "}
                                  {licence.licenceNumber}
                                </IonText>
                                <IonIcon
                                  icon={trashBinSharp}
                                  onClick={() => {
                                    setLicences(
                                      licences.filter(
                                        (item) =>
                                          item.licenceNumber !==
                                            licence.licenceNumber &&
                                          item.licenceType !==
                                            licence.licenceType
                                      )
                                    );
                                  }}
                                />
                              </IonItem>
                            </IonCard>
                          ))
                        ) : (
                          <></>
                        )}
                      </IonList>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonItem>
            </IonCard>
            <PhotoUpload
              photo={photo}
              setPhoto={setPhoto}
              mode="photo"
              messageText={"Upload New Business Icon"}
              maxWidth={200}
              maxHeight={200}
            />
            <IonButton
              disabled={saveDisabled}
              onClick={() => {
                setSaveDisabled(true);
                present("Saving...");
                const formData = new FormData();
                formData.append("businessType", businessType);
                formData.append("about", about);
                formData.append("phone", phone);
                formData.append("licences", JSON.stringify(licences));
                formData.append("location", location.name);
                formData.append("point", JSON.stringify(location));
                formData.append("ownerProfiles", JSON.stringify(ownerProfiles));
                if (photo) {
                  formData.append("sampleFile", photo[0]);
                }
                fetch(`${environment.backendURL}/open/user/updateBusiness`, {
                  method: "POST",
                  credentials: "include",
                  body: formData,
                })
                  .then((res) => res.json())

                  .then((res) => {
                    if (res.errors) {
                      presentAlert({
                        header: "Error",
                        message: res.errors[0].msg,
                        buttons: ["Ok"],
                      });
                      console.log(res.errors);
                      dismiss();

                      return;
                    }
                    dismiss();
                    setUser({
                      ...user,
                      user: {
                        ...user.user,
                        photo: `${environment.storageURL}/photos/profile/${
                          user?.user?.email
                        }.png?${Date.now()}`,
                      },
                    });
                  })
                  .catch((err) => {
                    dismiss();
                    console.log(err);
                  });
              }}
            >
              Save
            </IonButton>
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default EditProfile;
