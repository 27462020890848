import {
  IonChip,
  IonLabel,
  IonList,
  IonSearchbar,
  useIonAlert,
  useIonRouter,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { EnvContext } from "../../lib/envcontext";
import { getActiveTags, getAllTags } from "../../lib/util";

interface Props {
  tags: string[];
  setTags: (tags: string[]) => void;
  single?: boolean;
  activeOnly: boolean;
  form?: boolean;
}

interface Tag {
  tag: string;
  active: boolean;
}

const TagSelector: React.FC<Props> = ({
  tags,
  setTags,
  single,
  activeOnly,
  form,
}) => {
  function findPartialMatches(text: string) {
    var matches = [];
    for (var i = 0; i < predefinedTags.length; i++) {
      if (
        predefinedTags[i].tag
          .toLocaleLowerCase()
          .includes(text.toLocaleLowerCase())
      ) {
        matches.push(predefinedTags[i].tag);
      }
    }
    return matches;
  }

  const [results, setResults] = useState<string[]>([]);
  const [tagError, setTagError] = useState<boolean>(false);
  const [tempTag, setTempTag] = useState<string>("");
  const router = useIonRouter();
  const { environment } = useContext(EnvContext);
  const [predefinedTags, setPredefinedTags] = useState<Tag[]>([]);
  const [presentAlert] = useIonAlert();

  const handleSetTags = (newTags: string[]) => {
    if (newTags.length > 6) {
      presentAlert({
        header: "Too many tags",
        message: "You can only have up to 6 tags per post.",
        buttons: ["OK"],
      });
    } else {
      setTags(newTags);
    }
  };
  useEffect(() => {
    if (activeOnly) {
      getActiveTags({
        backendURL: environment.backendURL,
      }).then((data) => {
        if (data.error) {
          console.log(data);
        } else {
          setPredefinedTags(data);
        }
      });
    } else {
      getAllTags({
        backendURL: environment.backendURL,
      }).then((data) => {
        if (data.error) {
          console.log(data);
        } else {
          setPredefinedTags(data);
        }
      });
    }
  }, []);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxHeight: form ? "15em" : "100%",
      }}
    >
      <IonSearchbar
        placeholder="Search tags"
        value={tempTag}
        onIonInput={(e: any) => {
          setTempTag(e.detail.value);
          if (e.detail.value.length > 0) {
            const matches = findPartialMatches(e.target.value);
            if (matches.length > 0) {
              setResults(matches);
              setTagError(false);
            } else {
              setResults([]);
              setTagError(true);
            }
          } else {
            setResults([]);
          }
        }}
      ></IonSearchbar>
      {results && (
        <IonList
          style={{
            overflow: "auto",
            height: "100%",
          }}
        >
          {results.map((result: string, index: number) => {
            return (
              <IonChip
                style={{
                  cursor: "pointer",
                }}
                key={index}
                onClick={() => {
                  if (single) {
                    router.push(`/tag/${result}`);
                  } else {
                    if (tags) {
                      if (!tags?.includes(result)) {
                        setResults([]);
                        setTempTag("");
                        handleSetTags([...tags, result]);
                      } else {
                        setResults([]);
                        setTempTag("");
                      }
                    } else {
                      handleSetTags([result]);
                      setResults([]);
                      setTempTag("");
                    }
                  }
                }}
              >
                {result}
              </IonChip>
            );
          })}
        </IonList>
      )}
      {tags && (
        <IonList>
          {tags.map((tag: string, index: number) => {
            return (
              <IonChip
                color="danger"
                onClick={() => {
                  if (tags) {
                    handleSetTags(tags.filter((item) => item !== tag));
                  }
                }}
                key={index}
              >
                <IonLabel>#{tag}</IonLabel>
              </IonChip>
            );
          })}
        </IonList>
      )}
    </div>
  );
};

export default TagSelector;
