import {
  IonButton,
  IonButtons,
  IonContent,
  IonIcon,
  IonItem,
  IonModal,
  IonText,
  IonToolbar,
  useIonAlert,
  useIonRouter,
} from "@ionic/react";
import { chevronBackSharp } from "ionicons/icons";
import { UserContext } from "../../../lib/usercontext";
import { useContext } from "react";
import { EnvContext } from "../../../lib/envcontext";
import { PostData } from "../../../lib/types";
interface ViewerPostModalProps {
  setViewerModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  viewerModalOpen: boolean;
  postID: string;
  post: PostData;
}

const ViewerPostModal: React.FC<ViewerPostModalProps> = ({
  setViewerModalOpen,
  viewerModalOpen,
  postID,
  post,
}) => {
  const [presentAlert] = useIonAlert();
  const { user } = useContext(UserContext);
  const router = useIonRouter();
  const { environment } = useContext(EnvContext);

  return (
    <IonModal
      onDidDismiss={() => {
        setViewerModalOpen(false);
      }}
      isOpen={viewerModalOpen}
    >
      <IonToolbar>
        <IonButtons slot="start">
          <IonButton
            onClick={() => {
              setViewerModalOpen(false);
            }}
          >
            <IonIcon icon={chevronBackSharp} />
          </IonButton>
        </IonButtons>
      </IonToolbar>
      <IonContent className="ion-padding">
        {/* {fullscreen} */}
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <IonItem
            id="menuModalGoToPost"
            onClick={() => {
              setViewerModalOpen(false);
              router.push(`/post/${postID}`);
            }}
            button
          >
            <IonText>Go to post</IonText>
          </IonItem>

          <IonItem
            id="goToProfile"
            button
            onClick={() => {
              setViewerModalOpen(false);
              router.push(`/home/profile/${post?.user?.displayname}`);
            }}
          >
            <IonText>Go to profile</IonText>
          </IonItem>
          <IonItem
            id="menuModalReport"
            button
            onClick={() => {
              presentAlert({
                header: "Report post",
                message: "Are you sure you want to report this post?",

                inputs: [
                  {
                    label: "Spam",
                    type: "radio",
                    value: "Spam",
                  },
                  {
                    label: "Illegal",
                    type: "radio",
                    value: "Illegal",
                  },
                  {
                    label: "Misc",
                    type: "radio",
                    value: "Misc",
                  },
                ],

                buttons: [
                  {
                    text: "Cancel",
                    role: "cancel",
                    cssClass: "secondary",
                  },

                  {
                    text: "Okay",
                    handler: (data) => {
                      const formData = new FormData();
                      formData.append("reason", data);
                      formData.append("email", user?.user.email);
                      fetch(
                        `${environment.backendURL}/open/socialmedia/postinteractions/${postID}/report`,
                        {
                          method: "POST",
                          body: formData,
                          credentials: "include",
                        }
                      )
                        .then((res) => res.json())
                        .then(() => {
                          setViewerModalOpen(false);
                        });
                    },
                  },
                ],
              });
            }}
          >
            <IonText color={"danger"}>Report</IonText>
          </IonItem>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default ViewerPostModal;
