import {
  IonContent,
  IonPage,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import ExploreContainer from "../../components/sitewide/ExploreContainer";
import { useContext } from "react";
import { ToolBarVisibilityContext } from "../../lib/toolbarvisibilitycontext";

const RealEstate: React.FC = () => {
  const { setToolbarVisibility } = useContext(ToolBarVisibilityContext);
  useIonViewWillEnter(() => {
    setToolbarVisibility(true);
  });
  useIonViewWillLeave(() => {
    setToolbarVisibility(false);
  });
  return (
    <IonPage>
      <IonContent fullscreen>
        <ExploreContainer name="RealEstate page" />
      </IonContent>
    </IonPage>
  );
};

export default RealEstate;
