import BigPostWall from "../../components/socialMedia/BigPostWall";
import {
  IonContent,
  IonPage,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import "./Home.css";
import { ToolBarVisibilityContext } from "../../lib/toolbarvisibilitycontext";
import { useContext } from "react";

const Home: React.FC = () => {
  const { setToolbarVisibility } = useContext(ToolBarVisibilityContext);
  useIonViewWillEnter(() => {
    setToolbarVisibility(true);
  });
  useIonViewWillLeave(() => {
    setToolbarVisibility(false);
  });

  return (
    <IonPage
      style={{
        marginTop: "4rem",
      }}
    >
      <IonContent>
        <BigPostWall />
      </IonContent>
    </IonPage>
  );
};

export default Home;
