import { IonButton, IonIcon, IonRow, IonText } from "@ionic/react";
import { logoGoogle } from "ionicons/icons";
import { useContext } from "react";
import { EnvContext } from "../../lib/envcontext";
import { isPlatform } from "@ionic/react";

const OauthButtons = () => {
  const { environment } = useContext(EnvContext);

  return (
    <>
      <>
        {!isPlatform("ios") && (
          <IonRow>
            <IonButton
              expand="block"
              style={{
                width: "100%",
              }}
              onClick={() => {
                window.location.href = `${environment.backendURL}/auth/google`;
              }}
            >
              <IonIcon
                style={{
                  marginRight: "10px",
                }}
                icon={logoGoogle}
              />
              <IonText>Continue with Google</IonText>
            </IonButton>
          </IonRow>
        )}
      </>
    </>
  );
};
export default OauthButtons;
