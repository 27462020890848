import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonContent,
  IonList,
  IonPage,
  IonToolbar,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import UserItem from "../../components/sitewide/UserItem";
import { EnvContext } from "../../lib/envcontext";
import "../../theme/standard.css";
import { NavBarVisibilityContext } from "../../lib/navbarvisibilitycontext";
const Relationships: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { environment } = useContext(EnvContext);
  const [tab, setTab] = useState("followers");
  const [followers, setFollowers] = useState<string[]>([]);
  const [following, setFollowing] = useState<string[]>([]);
  useEffect(() => {
    Promise.all([
      fetch(
        `${environment.backendURL}/open/user/retrieveProfile/following/${id}`,
        {
          credentials: "include",
        }
      ),
      fetch(
        `${environment.backendURL}/open/user/retrieveProfile/followers/${id}`,
        {
          credentials: "include",
        }
      ),
    ])
      .then(([followingRes, followersRes]) =>
        Promise.all([followingRes.json(), followersRes.json()])
      )
      .then(([followingData, followersData]) => {
        setFollowing(followingData);
        setFollowers(followersData);
      });
  }, [environment, id]);
  const { setNavbarVisibility } = useContext(NavBarVisibilityContext);

  useIonViewWillEnter(() => {
    setNavbarVisibility(false);
  }, [setNavbarVisibility]);
  useIonViewWillLeave(() => {
    setNavbarVisibility(true);
  }, [setNavbarVisibility]);
  return (
    <IonPage>
      <IonContent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/home" />
          </IonButtons>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <IonButton
              onClick={() => {
                setTab("followers");
              }}
              fill={"clear"}
              color={tab === "followers" ? "primary" : "dark"}
            >
              Followers
            </IonButton>
            <IonButton
              onClick={() => setTab("following")}
              fill={"clear"}
              color={tab === "following" ? "primary" : "dark"}
            >
              Following
            </IonButton>
          </div>
        </IonToolbar>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",

            height: "100%",
          }}
        >
          <IonCard
            style={{
              maxHeight: "80vh",
            }}
            className="mobileResize"
          >
            {tab === "followers" ? (
              <>
                <IonList
                  style={{
                    width: "100%",
                    overflowY: "auto",
                  }}
                >
                  {followers.length > 0 ? (
                    followers?.map((follower: string, index) => (
                      <UserItem key={index} link={true} email={follower} />
                    ))
                  ) : (
                    <p>No followers</p>
                  )}
                </IonList>
              </>
            ) : (
              <>
                <IonList
                  style={{
                    width: "100%",
                  }}
                >
                  {following.length > 0 ? (
                    following?.map((following: string, index) => (
                      <UserItem key={index} link={true} email={following} />
                    ))
                  ) : (
                    <p>Not following anyone</p>
                  )}
                </IonList>
              </>
            )}
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Relationships;
