import {
  IonAvatar,
  IonButton,
  IonCard,
  IonChip,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRow,
  useIonAlert,
} from "@ionic/react";
import { useContext, useState } from "react";
import { UserContext } from "../../lib/usercontext";
import { EnvContext } from "../../lib/envcontext";
import { ellipsisVertical, happySharp } from "ionicons/icons";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import RichText from "../sitewide/RichText";
import MediaZoom from "../sitewide/MediaZoom";
interface MessageProps {
  messageID: Number;
  senderEmail: string;
  photo: string[];
  timestamp: number;
  message: string;

  reactions: {
    user: {
      email: string;
      displayname: string;
    };
    reaction: string;
  }[];
}

const InteractionMenu = ({
  owner,
  show,
  messageID,
  refetch,
  message,
}: {
  owner: boolean;
  show: boolean;
  messageID: Number;
  message: string;
  refetch: () => void;
}) => {
  const [interactionsOpen, setInteractionsOpen] = useState(false);
  const [emojiOpen, setEmojiOpen] = useState(false);
  const { environment } = useContext(EnvContext);
  const [presentAlert] = useIonAlert();
  const { user } = useContext(UserContext);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: owner ? "row" : "row-reverse",
        visibility: show ? "visible" : "hidden",
      }}
    >
      <IonButton
        onClick={() => {
          setInteractionsOpen(true);
        }}
        fill="clear"
        color={"medium"}
      >
        <IonIcon icon={ellipsisVertical} />
      </IonButton>
      <IonButton
        onClick={() => {
          setEmojiOpen(true);
        }}
        fill="clear"
        color={"medium"}
      >
        <IonIcon icon={happySharp} />
      </IonButton>
      <IonModal
        style={{}}
        onDidDismiss={() => {
          setEmojiOpen(false);
        }}
        isOpen={emojiOpen}
      >
        <IonContent
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
          class="ion-padding"
        >
          {" "}
          <Picker
            data={data}
            onEmojiSelect={(emoji: { native: string }) => {
              fetch(
                `${environment.backendURL}/open/user/directMessageInteractions/${messageID}/react`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  credentials: "include",
                  body: JSON.stringify({
                    reaction: emoji.native,
                    email: user.user.email,
                  }),
                }
              ).then((res) => {
                if (res.status === 200) {
                  setEmojiOpen(false);
                  refetch();
                }
              });
            }}
          />
        </IonContent>
      </IonModal>
      <IonModal
        onDidDismiss={() => {
          setInteractionsOpen(false);
        }}
        isOpen={interactionsOpen}
      >
        <IonContent class="ion-padding">
          <IonList>
            {owner ? (
              <>
                <IonItem
                  onClick={() => {
                    navigator.clipboard.writeText(message);
                    setInteractionsOpen(false);
                  }}
                  button
                >
                  <IonLabel>Copy</IonLabel>
                </IonItem>
                <IonItem disabled={true} button color={"danger"}>
                  <IonLabel>Delete</IonLabel>
                </IonItem>
              </>
            ) : (
              <>
                <IonItem
                  onClick={() => {
                    navigator.clipboard.writeText(message);
                    setInteractionsOpen(false);
                  }}
                  button
                >
                  <IonLabel>Copy</IonLabel>
                </IonItem>
                <IonItem
                  onClick={() => {
                    presentAlert({
                      header: "Report post",
                      message: "Are you sure you want to report this post?",

                      inputs: [
                        {
                          label: "Spam",
                          type: "radio",
                          value: "Spam",
                        },
                        {
                          label: "Illegal",
                          type: "radio",
                          value: "Illegal",
                        },
                        {
                          label: "Misc",
                          type: "radio",
                          value: "Misc",
                        },
                      ],

                      buttons: [
                        {
                          text: "Cancel",
                          role: "cancel",
                          cssClass: "secondary",
                        },

                        {
                          text: "Okay",
                          handler: (data) => {
                            const formData = new FormData();
                            formData.append("reason", data);
                            formData.append("email", user?.user.email);
                            fetch(
                              `${environment.backendURL}/open/user/directMessageInteractions/${messageID}/report`,
                              {
                                method: "POST",
                                body: formData,
                                credentials: "include",
                              }
                            )
                              .then((res) => res.json())
                              .then(() => {
                                setInteractionsOpen(false);
                              });
                          },
                        },
                      ],
                    });
                  }}
                  button
                  color={"danger"}
                >
                  <IonLabel>Report</IonLabel>
                </IonItem>
              </>
            )}
          </IonList>
        </IonContent>
      </IonModal>
    </div>
  );
};

const RichMessage = ({
  messageID,
  senderEmail,
  photo,
  timestamp,
  message,
  reactions,
}: MessageProps) => {
  const { user } = useContext(UserContext);
  const { environment } = useContext(EnvContext);
  const [showMenu, setShowMenu] = useState(false);
  const [trigger, setTrigger] = useState(false);

  return (
    <>
      <>
        <IonItem
          style={{
            margin: "1em",
          }}
          onMouseEnter={() => {
            setTimeout(() => {
              setShowMenu(true);
            }, 50);
          }}
          onMouseLeave={() => {
            setTimeout(() => {
              setShowMenu(false);
            }, 50);
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems:
                senderEmail === user.user.email ? "flex-end" : "flex-start",
              whiteSpace: "pre-wrap",
              overflowWrap: "break-word",
              width: "100%",
            }}
          >
            <div
              style={{
                maxWidth: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              {senderEmail === user.user.email && (
                <InteractionMenu
                  owner={true}
                  show={showMenu}
                  messageID={messageID}
                  refetch={() => {
                    setTrigger(!trigger);
                  }}
                  message={message}
                />
              )}
              {senderEmail !== user.user.email && (
                <IonAvatar
                  style={{
                    maxHeight: "2em",
                    maxWidth: "2em",
                  }}
                  slot="start"
                >
                  <img
                    src={`${environment.storageURL}/photos/profile/${senderEmail}.png`}
                    alt="avatar"
                  />
                </IonAvatar>
              )}
              <IonCard
                color={senderEmail === user.user.email ? "medium" : "light"}
                style={{
                  maxWidth: "80%",
                  padding: "0.8rem",
                }}
              >
                <RichText text={message} />
                <IonRow>
                  {photo != null &&
                    JSON.stringify(photo) !== "[]" &&
                    photo.map((photo, index) => {
                      return (
                        <div key={index}>
                          <IonCard
                            style={{
                              width: "5em",
                              height: "5em",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <MediaZoom
                              small={true}
                              src={`${environment.storageURL}/photos/${photo}`}
                            />
                          </IonCard>
                        </div>
                      );
                    })}
                </IonRow>
              </IonCard>
              {reactions?.map((reaction) => {
                return <IonChip>{reaction.reaction} </IonChip>;
              })}

              {senderEmail !== user.user.email && (
                <InteractionMenu
                  owner={false}
                  show={showMenu}
                  messageID={messageID}
                  refetch={() => {
                    setTrigger(!trigger);
                  }}
                  message={message}
                />
              )}
            </div>
          </div>
        </IonItem>
      </>
    </>
  );
};

export default RichMessage;
