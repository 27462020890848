import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { chevronBackSharp } from "ionicons/icons";

interface Props {
  name: string;
  button?: JSX.Element;
  allwaysGoBackTo?: string;
}

const GenericBackToolbar: React.FC<Props> = ({
  name,
  button,
  allwaysGoBackTo,
}) => {
  return (
    <IonHeader>
      <IonToolbar color={"light"}>
        <IonButtons slot="start">
          {allwaysGoBackTo ? (
            <>
              <IonButton routerLink={allwaysGoBackTo}>
                <IonIcon icon={chevronBackSharp} />
              </IonButton>
            </>
          ) : (
            <div id="back-button">
              <IonBackButton
                className="back-button"
                text=""
                icon={chevronBackSharp}
                defaultHref="/Home"
              />
            </div>
          )}
        </IonButtons>
        <IonTitle>
          <h4>{name}</h4>
        </IonTitle>
        {button && <IonButtons slot="primary">{button}</IonButtons>}
      </IonToolbar>
    </IonHeader>
  );
};

export default GenericBackToolbar;
