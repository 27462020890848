import { useState } from "react";
import UserItem from "../sitewide/UserItem";
import { IonCard, IonRow } from "@ionic/react";
import ModerationActionsMenu from "./ModerationActionsMenu";

interface ModProfilePreviewProps {
  id: string;
  reason: string;
  reportID: string;
}

const ModProfilePreview: React.FC<ModProfilePreviewProps> = ({
  id,
  reason,
  reportID,
}) => {
  const [hidden, setHidden] = useState(false);

  return (
    <>
      {!hidden && (
        <IonCard
          style={{
            padding: "1rem",
          }}
        >
          <UserItem link={true} email={id} />
          <IonRow>Reason for report: {reason}</IonRow>
          <br />
          <ModerationActionsMenu
            email={id}
            reportID={reportID}
            setHidden={setHidden}
          />
        </IonCard>
      )}
    </>
  );
};

export default ModProfilePreview;
