import {
  IonButton,
  IonButtons,
  IonContent,
  IonIcon,
  IonItem,
  IonModal,
  IonText,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";
import { chevronBackSharp } from "ionicons/icons";
import { useContext } from "react";
import { UserContext } from "../../../lib/usercontext";
import { EnvContext } from "../../../lib/envcontext";
import { Business, Person } from "../../../lib/types";
import { BlockingsContext } from "../../../lib/blockingscontextprovider";

interface ProfileInteractionsModalProps {
  interactionsModal: boolean;
  setInteractionsModal: React.Dispatch<React.SetStateAction<boolean>>;
  profile: Person | Business;
}

const ProfileInteractionsModal: React.FC<ProfileInteractionsModalProps> = ({
  interactionsModal,
  setInteractionsModal,
  profile,
}) => {
  const [presentAlert] = useIonAlert();
  const { user } = useContext(UserContext);
  const { environment } = useContext(EnvContext);
  const { blockings, setBlockings } = useContext(BlockingsContext);

  return (
    <IonModal
      onDidDismiss={() => {
        setInteractionsModal(false);
      }}
      isOpen={interactionsModal}
    >
      <IonToolbar>
        <IonButtons slot="start">
          <IonButton
            onClick={() => {
              setInteractionsModal(false);
            }}
          >
            <IonIcon icon={chevronBackSharp} />
          </IonButton>
        </IonButtons>
      </IonToolbar>
      <IonContent className="ion-padding">
        {/* {fullscreen} */}
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {blockings.includes(profile.email) ? (
            <IonItem
              id="Unblock"
              button
              onClick={() => {
                fetch(
                  `${environment.backendURL}/open/user/profileinteractions/${profile.email}/unblock`,
                  {
                    method: "POST",
                    credentials: "include",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      email: user?.user?.email,
                    }),
                  }
                )
                  .then(() => {
                    setBlockings(
                      blockings.filter(
                        (email: string) => email !== profile.email
                      )
                    );

                    setInteractionsModal(false);
                  })
                  .catch((err) => console.log(err));
              }}
            >
              <IonText>Unblock</IonText>
            </IonItem>
          ) : (
            <IonItem
              id="Block"
              button
              onClick={() => {
                presentAlert({
                  header: "Block user",
                  message: "Are you sure you want to block this user?",
                  buttons: [
                    {
                      text: "Cancel",
                      role: "cancel",
                      cssClass: "secondary",
                    },

                    {
                      text: "Okay",
                      handler: () => {
                        fetch(
                          `${environment.backendURL}/open/user/profileinteractions/${profile.email}/block`,
                          {
                            method: "POST",
                            credentials: "include",
                            headers: {
                              "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                              email: user?.user?.email,
                            }),
                          }
                        )
                          .then(() => {
                            setBlockings([...blockings, profile.email]);
                            setInteractionsModal(false);
                          })
                          .catch((err) => console.log(err));
                      },
                    },
                  ],
                });
              }}
            >
              <IonText color={"danger"}>Block user</IonText>
            </IonItem>
          )}
          <IonItem
            id="menuModalReport"
            button
            onClick={() => {
              presentAlert({
                header: "Report post",
                message: "Are you sure you want to report this post?",

                inputs: [
                  {
                    label: "Spam",
                    type: "radio",
                    value: "Spam",
                  },
                  {
                    label: "Illegal",
                    type: "radio",
                    value: "Illegal",
                  },
                  {
                    label: "Misc",
                    type: "radio",
                    value: "Misc",
                  },
                ],

                buttons: [
                  {
                    text: "Cancel",
                    role: "cancel",
                    cssClass: "secondary",
                  },

                  {
                    text: "Okay",
                    handler: (data) => {
                      const formData = new FormData();
                      formData.append("reason", data);
                      formData.append("email", user?.user.email);
                      fetch(
                        `${environment.backendURL}/open/user/profileinteractions/${profile.email}/report`,
                        {
                          method: "POST",
                          body: formData,
                          credentials: "include",
                        }
                      )
                        .then((res) => res.json())
                        .then(() => {
                          setInteractionsModal(false);
                        });
                    },
                  },
                ],
              });
            }}
          >
            <IonText color={"danger"}>Report</IonText>
          </IonItem>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default ProfileInteractionsModal;
