import { IonGrid, IonRow } from "@ionic/react";
import PostMin from "./PostMin";
import "./PostMinInfin.css";
interface PostPreviewProps {
  posts: string[];
}

const PostMinInfin = ({ posts }: PostPreviewProps) => {
  return (
    <IonGrid>
      <IonRow
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        {posts?.map((post, index) => {
          return <PostMin key={index} post={post} />;
        })}
      </IonRow>
    </IonGrid>
  );
};

export default PostMinInfin;
