import {
  IonButton,
  IonCard,
  IonCol,
  IonContent,
  IonDatetime,
  IonDatetimeButton,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonPage,
  IonTextarea,
  IonToggle,
  useIonAlert,
  useIonLoading,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import GenericBackToolbar from "../../components/sitewide/GenericBackToolbar";
import LocationSearch from "../../components/sitewide/LocationSearch";
import PhotoUpload from "../../components/sitewide/PhotoUpload";
import { EnvContext } from "../../lib/envcontext";
import { UserContext } from "../../lib/usercontext";
import "../../theme/standard.css";
import TagSelector from "../../components/sitewide/TagSelector";
import { retrieveUserBasic } from "../../lib/util";
import { location } from "../../lib/types";
import { NavBarVisibilityContext } from "../../lib/navbarvisibilitycontext";

const EditProfile: React.FC = () => {
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [photo, setPhoto] = useState([]);
  const { user, setUser } = useContext(UserContext);
  const { environment } = useContext(EnvContext);
  const [dob, setDob] = useState(null);
  const [present, dismiss] = useIonLoading();
  const [location, setLocation] = useState({} as location);
  const [realname, setRealname] = useState("");
  const [bio, setBio] = useState("");
  const [tags, setTags] = useState<string[]>([]);
  const [privateProfile, setPrivateProfile] = useState(false);
  const [errors, setErrors] = useState({
    realname: false,
    bio: false,
    tags: false,
  });
  const [show, hide] = useIonAlert();
  useEffect(() => {
    if (user?.user) {
      retrieveUserBasic({
        email: user?.user?.email,
        backendURL: environment.backendURL,
      })
        .then((data: any) => {
          setRealname(data.realname);
          setBio(data.bio);
          setTags(data.tags);
          setPrivateProfile(data.private);
          setDob(data.dob ? data.dob : null);
          if (data.location) {
            setLocation({
              name: data.location,
              lat: data.point.y,
              lon: data.point.x,
            });
          }
        })
        .then(() => {
          dismiss();
        });
    }
  }, [environment, user?.user]);

  useEffect(() => {
    if (errors.realname || errors.bio) {
      setSaveDisabled(true);
    } else {
      setSaveDisabled(false);
    }
  }, [realname, bio, location, tags, privateProfile]);
  const { setNavbarVisibility } = useContext(NavBarVisibilityContext);

  useIonViewWillEnter(() => {
    setNavbarVisibility(false);
  }, [setNavbarVisibility]);
  useIonViewWillLeave(() => {
    setNavbarVisibility(true);
  }, [setNavbarVisibility]);
  return (
    <>
      <IonPage>
        <GenericBackToolbar name="Edit Profile" />
        <IonContent>
          <div className="wrapper">
            <IonCard className="form">
              <IonItem>
                <IonInput
                  debounce={500}
                  className={`${!errors.realname && "ion-valid"} ${
                    errors.realname && "ion-invalid"
                  } ${"ion-touched"}`}
                  helperText="Must have more than 3 characters"
                  value={realname}
                  label="Real Name"
                  labelPlacement="floating"
                  name="realname"
                  onIonChange={(e: any) => {
                    if (/^(?=.{4,100}$)([\s\S]*)|^$/) {
                      setErrors({ ...errors, realname: false });
                      setRealname(e.detail.value);
                    } else {
                      setErrors({ ...errors, realname: true });
                      setRealname(e.detail.value);
                    }
                  }}
                />{" "}
              </IonItem>
              <IonItem>
                <IonTextarea
                  label="Bio"
                  labelPlacement="floating"
                  className={`${!errors.bio && "ion-valid"} ${
                    errors.bio && "ion-invalid"
                  } ${"ion-touched"}`}
                  debounce={500}
                  errorText="Must have less than 1000 characters"
                  helperText="optional"
                  value={bio}
                  name="bio"
                  onIonChange={(e: any) => {
                    if (e.detail.value.length < 1000) {
                      setErrors({ ...errors, bio: false });
                      setBio(e.detail.value);
                    } else {
                      setErrors({ ...errors, bio: true });
                      setBio(e.detail.value);
                    }
                  }}
                />{" "}
              </IonItem>
              <TagSelector
                form={true}
                tags={tags}
                setTags={(tags) => {
                  setTags(tags);
                }}
                activeOnly={true}
              />
              <IonItem>
                <IonLabel>Birthday </IonLabel>

                <IonCol
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                  }}
                >
                  <IonDatetimeButton datetime="datetime" />
                </IonCol>
              </IonItem>
              <IonItem>
                <IonLabel>Private account</IonLabel>

                <IonCol
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                  }}
                >
                  <IonToggle
                    aria-label="Private Account Toggle"
                    checked={privateProfile}
                    onIonChange={(e) => {
                      setPrivateProfile(e.detail.checked);
                    }}
                  />
                </IonCol>
              </IonItem>
              <LocationSearch
                location={location}
                setLocation={setLocation}
                disableSave={setSaveDisabled}
              />

              <IonModal keepContentsMounted={true}>
                <IonDatetime
                  value={dob}
                  onIonChange={(e: any) => {
                    setDob(e?.detail?.value?.toString());
                  }}
                  presentation="date"
                  id="datetime"
                />
              </IonModal>
              <IonItem>
                <PhotoUpload
                  photo={photo}
                  setPhoto={setPhoto}
                  mode="photo"
                  messageText={"Upload New Profile Picture"}
                  maxWidth={200}
                  maxHeight={200}
                  multiple={false}
                />
              </IonItem>
              <IonItem>
                <IonButton
                  disabled={saveDisabled}
                  onClick={() => {
                    present("Saving...");
                    setSaveDisabled(true);
                    const formData = new FormData();
                    formData.append("private", privateProfile.toString());
                    if (photo) {
                      formData.append("sampleFile", photo[0]);
                    }
                    if (location && location.name) {
                      formData.append("location", location.name);
                      formData.append("point", JSON.stringify(location));
                    }

                    if (realname) {
                      formData.append("realname", realname);
                    }
                    if (bio) {
                      formData.append("bio", bio);
                    }
                    if (dob !== null && dob !== undefined) {
                      formData.append("dob", dob);
                    }
                    if (tags) {
                      formData.append("tags", JSON.stringify(tags));
                    }
                    fetch(`${environment.backendURL}/open/user/updatePerson`, {
                      method: "POST",
                      body: formData,
                      credentials: "include",
                    })
                      .then((res) => res.json())
                      .then((data) => {
                        console.log(data);
                        dismiss();
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                    setUser({
                      ...user,
                      user: {
                        ...user.user,
                        photo: `${environment.storageURL}/photos/profile/${
                          user?.user?.email
                        }.png?${Date.now()}`,
                      },
                    });
                  }}
                >
                  Save
                </IonButton>
              </IonItem>
              <IonItem>
                <IonButton
                  color={"danger"}
                  onClick={() => {
                    show({
                      header: "Delete Account",
                      message: "Are you sure you want to delete your account?",
                      buttons: [
                        {
                          text: "Cancel",
                          role: "cancel",
                          handler: () => {
                            console.log("Cancel clicked");
                          },
                        },
                        {
                          text: "Yes",
                          handler: () => {
                            fetch(
                              `${environment.backendURL}/open/user/deleteaccount`,
                              {
                                method: "DELETE",
                                credentials: "include",
                              }
                            )
                              .then((res) => res.json())
                              .then((data) => {
                                console.log(data);
                                window.location.href = "/";
                              })
                              .catch((err) => {
                                console.log(err);
                              });
                          },
                        },
                      ],
                    });
                  }}
                >
                  Delete Account
                </IonButton>
              </IonItem>
            </IonCard>
          </div>
        </IonContent>
      </IonPage>
    </>
  );
};

export default EditProfile;
