import {
  IonButton,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonInput,
  IonPage,
  IonText,
  useIonLoading,
  useIonRouter,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import { useContext, useState } from "react";
import { UserContext } from "../../lib/usercontext";
import { EnvContext } from "../../lib/envcontext";
import { useParams } from "react-router";
import "../../theme/standard.css";
import GenericBackToolbar from "../../components/sitewide/GenericBackToolbar";
import { NavBarVisibilityContext } from "../../lib/navbarvisibilitycontext";
const SharePost: React.FC = () => {
  const [present, dismiss] = useIonLoading();
  const { id } = useParams<{ id: string }>();
  const [form, setForm] = useState({
    postTitle: "",
    description: "",
  });
  const [errors, setErrors] = useState({
    postTitle: false,
    description: false,
  });
  const { user } = useContext(UserContext);
  const { environment } = useContext(EnvContext);
  const router = useIonRouter();
  const { setNavbarVisibility } = useContext(NavBarVisibilityContext);

  useIonViewWillEnter(() => {
    setNavbarVisibility(false);
  }, [setNavbarVisibility]);
  useIonViewWillLeave(() => {
    setNavbarVisibility(true);
  }, [setNavbarVisibility]);
  return (
    <IonPage>
      <GenericBackToolbar name="Share Post" />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100%",
        }}
      >
        <IonCard
          style={{
            height: "60vh",
          }}
          className="mobileResize"
        >
          <div>
            <IonCardHeader>
              <IonCardTitle>Share Post</IonCardTitle>
            </IonCardHeader>
            <IonText>
              Share a post with your feed, you can also add your own title and
              description.
            </IonText>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-evenly",
              width: "100%",
            }}
          >
            <IonInput
              debounce={500}
              name="postTitle"
              onIonChange={(e: any) => {
                if (
                  (e.detail.value.length > 3 && e.detail.value.length < 100) ||
                  e.detail.value === ""
                ) {
                  setErrors({ ...errors, postTitle: false });
                  setForm({ ...form, postTitle: e.detail.value });
                } else {
                  setErrors({ ...errors, postTitle: true });
                  setForm({ ...form, postTitle: "" });
                }
              }}
              label="Write a new title"
              labelPlacement="floating"
            />
            <IonInput
              debounce={500}
              name="description"
              onIonChange={(e: any) => {
                if (
                  (e.detail.value.length > 3 && e.detail.value.length < 1000) ||
                  e.detail.value === ""
                ) {
                  setErrors({ ...errors, description: false });
                  setForm({ ...form, description: e.detail.value });
                } else {
                  setErrors({ ...errors, description: true });
                  setForm({ ...form, description: "" });
                }
              }}
              label="Write a new description"
              labelPlacement="floating"
            />

            {/* <div
                style={{
                  width: "20%",
                }}
              >
                <PostMin post={id} />
              </div> */}
          </div>
          <IonButton
            disabled={
              errors.postTitle ||
              errors.description ||
              form.postTitle === "" ||
              form.description === "" ||
              form.postTitle.length < 3 ||
              form.description.length < 3
            }
            onClick={() => {
              present("Uploading post...");
              const formData = new FormData();
              formData.append("email", user.user.email);
              formData.append("title", form.postTitle);
              formData.append("description", form.description);
              formData.append("repost", id);
              fetch(`${environment.backendURL}/open/socialmedia/publishpost`, {
                method: "POST",
                body: formData,
                credentials: "include",
              })
                .then((res) => res.json())
                .then(() => {
                  dismiss();
                  router.canGoBack() ? router.goBack() : router.push("/Home");
                });
            }}
          >
            Share
          </IonButton>
        </IonCard>
      </div>
    </IonPage>
  );
};

export default SharePost;
