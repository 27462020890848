import {
  IonButton,
  IonButtons,
  IonContent,
  IonIcon,
  IonItem,
  IonModal,
  IonText,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";
import { chevronBackSharp } from "ionicons/icons";
import { useContext } from "react";
import { EnvContext } from "../../../lib/envcontext";
import { UserContext } from "../../../lib/usercontext";
import { CommentData } from "../../../lib/types";
interface CommentViewerModalProps {
  viewerModal: boolean;
  setViewerModal: React.Dispatch<React.SetStateAction<boolean>>;
  comment: CommentData;
}

const CommentViewerModal: React.FC<CommentViewerModalProps> = ({
  viewerModal,
  setViewerModal,
  comment,
}) => {
  const [presentAlert] = useIonAlert();
  const { user } = useContext(UserContext);
  const { environment } = useContext(EnvContext);
  return (
    <IonModal
      onDidDismiss={() => {
        setViewerModal(false);
      }}
      isOpen={viewerModal}
    >
      <IonToolbar>
        <IonButtons slot="start">
          <IonButton
            onClick={() => {
              setViewerModal(false);
            }}
          >
            <IonIcon icon={chevronBackSharp} />
          </IonButton>
        </IonButtons>
      </IonToolbar>
      <IonContent>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <IonItem
            id="menuModalReport"
            button
            onClick={() => {
              presentAlert({
                header: "Report post",
                message: "Are you sure you want to report this post?",

                inputs: [
                  {
                    label: "Spam",
                    type: "radio",
                    value: "Spam",
                  },
                  {
                    label: "Illegal",
                    type: "radio",
                    value: "Illegal",
                  },
                  {
                    label: "Misc",
                    type: "radio",
                    value: "Misc",
                  },
                ],

                buttons: [
                  {
                    text: "Cancel",
                    role: "cancel",
                    cssClass: "secondary",
                  },

                  {
                    text: "Okay",
                    handler: (data) => {
                      const formData = new FormData();
                      formData.append("reason", data);
                      formData.append("email", user?.user.email);
                      fetch(
                        `${environment.backendURL}/open/socialmedia/commentinteractions/${comment.postID}/report`,
                        {
                          method: "POST",
                          body: formData,
                          credentials: "include",
                        }
                      )
                        .then((res) => res.json())
                        .then(() => {
                          setViewerModal(false);
                        });
                    },
                  },
                ],
              });
            }}
          >
            <IonText color={"danger"}>Report</IonText>
          </IonItem>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default CommentViewerModal;
