import {
  IonCol,
  IonPage,
  IonContent,
  IonItem,
  IonLabel,
  IonLoading,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonToolbar,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import { useContext, useState } from "react";
import { EnvContext } from "../../lib/envcontext";
import CommentPreview from "../../components/moderation/CommentPreview";
import PostModPreview from "../../components/moderation/PostModPreview";
import ModProfilePreview from "../../components/moderation/ModProfilePreview";
import { ModerationData, ModerationItem } from "../../lib/types";
import ModMessagePreview from "../../components/moderation/ModMessagePreview";
import GenericBackToolbar from "../../components/sitewide/GenericBackToolbar";
import { NavBarVisibilityContext } from "../../lib/navbarvisibilitycontext";
const Moderation: React.FC = () => {
  const { setNavbarVisibility } = useContext(NavBarVisibilityContext);

  useIonViewWillEnter(() => {
    setNavbarVisibility(false);
  }, [setNavbarVisibility]);
  useIonViewWillLeave(() => {
    setNavbarVisibility(true);
  }, [setNavbarVisibility]);
  const { environment } = useContext(EnvContext);
  const [moderationData, setModerationData] = useState([] as ModerationData);
  const [filter, setFilter] = useState("all");
  useIonViewWillEnter(() => {
    fetch(`${environment.backendURL}/moderator/moderationQueue`, {
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        setModerationData(data);
        console.log(data);
      });
  });
  return (
    <IonPage>
      <IonContent>
        <GenericBackToolbar name="Moderation" />
        <IonToolbar>
          <IonCol>
            <IonRadioGroup
              value={filter}
              onIonChange={(e) => setFilter(e.detail.value)}
            >
              <IonRow>
                <IonItem>
                  <IonRadio slot="end" value="all" />
                  <IonLabel>All</IonLabel>
                </IonItem>
                <IonItem>
                  <IonRadio slot="end" value="socialPost" />
                  <IonLabel>Social Posts</IonLabel>
                </IonItem>
                <IonItem>
                  <IonRadio slot="end" value="comment" />
                  <IonLabel>Comments</IonLabel>
                </IonItem>
                <IonItem>
                  <IonRadio slot="end" value="Profile" />
                  <IonLabel>Profiles</IonLabel>
                </IonItem>
                <IonItem>
                  <IonRadio slot="end" value="Message" />
                  <IonLabel>Message</IonLabel>
                </IonItem>
              </IonRow>
            </IonRadioGroup>
          </IonCol>
        </IonToolbar>
        <div
          style={{
            maxWidth: "70vw",
          }}
        >
          {moderationData === null ? (
            <IonLoading isOpen={true} />
          ) : moderationData.length === 0 ? (
            <></>
          ) : (
            moderationData
              .filter((item: ModerationItem) =>
                filter === "all"
                  ? item.type === "socialPost" ||
                    item.type === "comment" ||
                    item.type === "Profile" ||
                    item.type === "Message"
                  : item.type === filter
              )
              .map((item: ModerationItem, index: number) => {
                switch (item.type) {
                  case "socialPost":
                    return (
                      <PostModPreview
                        key={index}
                        reason={item.reason}
                        // @ts-ignore: Impossible to be null
                        id={item.postID}
                        reportID={item.reportID}
                      />
                    );
                  case "comment":
                    return (
                      <CommentPreview
                        key={index}
                        reason={item.reason}
                        // @ts-ignore: Impossible to be null
                        id={item.postID}
                        reportID={item.reportID}
                      />
                    );

                  case "Profile":
                    return (
                      <ModProfilePreview
                        key={index}
                        reason={item.reason}
                        // @ts-ignore: Impossible to be null
                        id={item.email}
                        reportID={item.reportID}
                      />
                    );
                  case "Message":
                    return (
                      <ModMessagePreview
                        key={index}
                        reason={item.reason}
                        // @ts-ignore: Impossible to be null

                        messageID={item.postID}
                        reportID={item.reportID}
                      />
                    );
                  default:
                    return <>error</>;
                }
              })
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Moderation;
