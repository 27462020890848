import {
  IonButton,
  IonCard,
  IonCardHeader,
  IonHeader,
  IonInput,
  IonItem,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  useIonAlert,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { NavBarVisibilityContext } from "../../lib/navbarvisibilitycontext";
import { EnvContext } from "../../lib/envcontext";
import Captcha from "../../components/sitewide/Captcha";
import "./PasswordReset.css";
const PasswordReset = () => {
  const [captcha, setCapcha] = useState("");
  const [captchaKey, setCaptchaKey] = useState(0);
  const { environment } = useContext(EnvContext);
  const { setNavbarVisibility } = useContext(NavBarVisibilityContext);
  useIonViewWillEnter(() => {
    setNavbarVisibility(false);
  }, [setNavbarVisibility]);
  useIonViewWillLeave(() => {
    setNavbarVisibility(true);
  }, [setNavbarVisibility]);
  const token = new URLSearchParams(window.location.search).get("token");
  const [password, setPassword] = useState("");
  const [present] = useIonAlert();
  useEffect(() => {
    fetch(environment.backendURL + "/auth/verifyRecoveryCodeValid/" + token, {
      credentials: "include",
    })
      .then((response) => {
        if (response.status === 200) {
          console.log("valid");
        } else {
          present({
            header: "Error",
            message: "Invalid token",
            buttons: ["OK"],
          });
          console.log("invalid");
        }
      })

      .catch((error) => {
        present({
          header: "Error",
          message: "Invalid token",
          buttons: ["OK"],
        });
      });
  }, [environment.backendURL, present, token]);
  return (
    <IonPage>
      <div className="wrapper">
        <IonCard className="passwordResetCard">
          <IonCardHeader>
            <IonToolbar>
              <IonTitle>
                <h4>Reset Password</h4>
              </IonTitle>
            </IonToolbar>
          </IonCardHeader>
          <IonInput
            style={{ textAlign: "start" }}
            className={
              password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/)
                ? "ion-invalid"
                : "ion-valid"
            }
            errorText="Password must be at least 8 characters "
            label="New Password"
            labelPlacement="floating"
            type="password"
            value={password}
            onIonChange={(e: any) => {
              e.detail.value?.toString().length > 0
                ? setPassword(e.detail.value!.toString())
                : setPassword("");
            }}
          />
          <br />

          <IonRow
            style={{
              width: "100%",
            }}
          >
            <IonItem
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "start",
              }}
            >
              <Captcha
                key={captchaKey}
                onsuccess={(token: string) => {
                  setCapcha(token);
                }}
              />
            </IonItem>
          </IonRow>
          <br />

          <IonRow style={{ width: "100%" }}>
            <IonButton
              style={{ width: "100%" }}
              expand="block"
              disabled={captcha === "" || password === ""}
              onClick={() => {
                fetch(environment.backendURL + "/auth/resetpassword", {
                  credentials: "include",
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    token: token,
                    password: password,
                    captcha: captcha,
                  }),
                })
                  .then((response) => {
                    if (response.status === 200) {
                      present({
                        header: "Success",
                        message: "Password reset successfully",
                        buttons: [
                          {
                            text: "OK",
                            handler: () => window.location.replace("/login"),
                          },
                        ],
                      });
                    } else {
                      present({
                        header: "Error",
                        message: "Password reset failed",
                        buttons: ["OK"],
                      });
                      console.log(response);
                      setCaptchaKey(Math.random());
                      setCapcha("");
                    }
                  })
                  .catch((error) => {
                    present({
                      header: "Error",
                      message: "Password reset failed",
                      buttons: ["OK"],
                    });
                    setCaptchaKey(Math.random());
                    setCapcha("");
                  });
              }}
            >
              Reset Password
            </IonButton>
          </IonRow>
        </IonCard>
      </div>
    </IonPage>
  );
};

export default PasswordReset;
