import { useContext, useEffect, useState } from "react";
import {
  IonCard,
  IonCardHeader,
  IonGrid,
  IonRow,
  IonAvatar,
  IonLabel,
  IonText,
  useIonRouter,
  IonItem,
  IonTitle,
  IonButton,
  IonIcon,
  IonButtons,
  IonToolbar,
  IonSkeletonText,
  IonicSlides,
  IonChip,
  IonCol,
} from "@ionic/react";
import { UserContext } from "../../lib/usercontext";
import PostInteractions from "./PostInteractions";
import { EnvContext } from "../../lib/envcontext";
import "./PostPreview.css";
import { ellipsisHorizontalSharp, ellipsisVerticalSharp } from "ionicons/icons";
import { LinkItUrl } from "react-linkify-it";
import EditPostModal from "./modal/EditPostModal";
import OwnerPostModal from "./modal/OwnerPostModal";
import ViewerPostModal from "./modal/ViewerPostModal";
import { PostData } from "../../lib/types";
import { Swiper, SwiperSlide, useSwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "@ionic/react/css/ionic-swiper.css";
import RichText from "../sitewide/RichText";
import TimeSincePosted from "../sitewide/TimeSincePosted";
import ImgZoom from "../sitewide/MediaZoom";
import { BlockingsContext } from "../../lib/blockingscontextprovider";
import { retrievePost } from "../../lib/util";

interface PostPreviewProps {
  postID: string;
  beingShared?: boolean;
}

const PostPreview = ({ postID, beingShared }: PostPreviewProps) => {
  const router = useIonRouter();
  const { user } = useContext(UserContext);
  const { blockings } = useContext(BlockingsContext);

  const [post, setPost] = useState({} as PostData);
  const { environment } = useContext(EnvContext);
  const [ownerModalOpen, setOwnerModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [viewerModalOpen, setViewerModalOpen] = useState(false);
  const [invisible, setInvisible] = useState(false);
  const [error, setError] = useState(false);
  useEffect(() => {
    retrievePost({
      id: postID,
      backendURL: environment.backendURL,
    }).then((data) => {
      if (blockings.includes(data.user?.email)) {
        setInvisible(true);
      }
      if (data.errors) {
        setError(true);
      }
      setPost(data);
    });
  }, [environment, postID]);

  return (
    <>
      <EditPostModal
        editModalOpen={editModalOpen}
        setEditModalOpen={setEditModalOpen}
        post={post}
        setPost={setPost}
        postID={postID}
        setOwnerModalOpen={setOwnerModalOpen}
      />
      <OwnerPostModal
        ownerModalOpen={ownerModalOpen}
        setOwnerModalOpen={setOwnerModalOpen}
        postID={postID}
        setEditModalOpen={setEditModalOpen}
        setInvisible={setInvisible}
      />
      <ViewerPostModal
        viewerModalOpen={viewerModalOpen}
        setViewerModalOpen={setViewerModalOpen}
        postID={postID}
        post={post}
      />
      <>
        {!error ? (
          <>
            {" "}
            {post?.post ? (
              <>
                {" "}
                {!invisible ? (
                  <IonCard
                    style={{
                      cursor: post.post.link ? "pointer" : "default",
                    }}
                    onClick={async () => {
                      if (post.ad) {
                        //ensure it is an absolute url, if it is not, make it one
                        let url = post.post.link;
                        if (
                          !url.startsWith("http://") &&
                          !url.startsWith("https://")
                        ) {
                          url = "http://" + url;
                        }
                        await fetch(
                          `${environment.backendURL}/open/socialmedia/campaignClick/${postID}`,
                          {
                            method: "POST",
                            headers: {
                              "Content-Type": "application/json",
                            },
                            credentials: "include",
                          }
                        );
                        window.open(url, "_blank");
                      }
                    }}
                    className="postCard"
                    id={postID}
                  >
                    <IonCardHeader>
                      <IonToolbar>
                        <IonButtons slot="start">
                          <IonItem
                            className="profileLink"
                            onClick={() => {
                              router.push(
                                `/home/profile/${post.user.displayname}`
                              );
                            }}
                            button
                          >
                            <IonRow
                              style={{
                                margin: "1rem",
                              }}
                              class="ion-align-items-center ion-justify-content-start"
                            >
                              <IonAvatar
                                id={post.user.email}
                                class="ion-margin-end"
                              >
                                <img
                                  src={`${
                                    environment.storageURL
                                  }/photos/profile/${
                                    post.user.email
                                  }.png?${Date.now()}`}
                                  alt="avatar"
                                />
                              </IonAvatar>
                              <IonCol>
                                <IonLabel>
                                  <h2>{post?.user?.displayname}</h2>
                                </IonLabel>
                                <IonLabel color="primary">
                                  <i>{post.ad && "sponsored"}</i>
                                </IonLabel>
                              </IonCol>
                            </IonRow>
                          </IonItem>
                        </IonButtons>
                        <IonButtons slot="end">
                          {
                            // if the post is the user's post, show the three dots menu
                            post.user.email === user.user.email ? (
                              post.ad ? (
                                <></>
                              ) : (
                                <IonButton
                                  id="menuButton"
                                  onClick={() => {
                                    setOwnerModalOpen(true);
                                  }}
                                >
                                  <IonIcon
                                    slot="icon-only"
                                    icon={ellipsisVerticalSharp}
                                  />
                                </IonButton>
                              )
                            ) : (
                              <IonButton
                                id="viewerMenuButton"
                                onClick={() => {
                                  setViewerModalOpen(true);
                                }}
                              >
                                <IonIcon
                                  slot="icon-only"
                                  icon={ellipsisVerticalSharp}
                                />
                              </IonButton>
                            )
                          }
                        </IonButtons>
                      </IonToolbar>
                      <IonRow>
                        <TimeSincePosted timestamp={post?.post?.timestamp} />
                      </IonRow>
                      <IonItem
                        style={{
                          maxWidth: "100%",
                        }}
                      >
                        <IonTitle
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            maxWidth: "100%",
                          }}
                          slot="start"
                        >
                          {post?.post?.title}
                        </IonTitle>
                      </IonItem>
                    </IonCardHeader>
                    {post.post.repost ? (
                      <div
                        style={{
                          backgroundColor: "var(--ion-color-light)",
                          padding: "1rem",
                        }}
                      >
                        <PostPreview
                          postID={post.post.repost}
                          beingShared={true}
                        />
                      </div>
                    ) : (
                      <>
                        {post?.post?.photo ? (
                          <>
                            {" "}
                            <Swiper
                              modules={[Pagination, IonicSlides]}
                              pagination={true}
                            >
                              {post.post.photo.map((photo, index: number) => {
                                return (
                                  <SwiperSlide key={index} className="slide">
                                    {/* <IonCol> */}
                                    <ImgZoom
                                      small={true}
                                      src={`${environment.storageURL}/photos/${photo}`}
                                    />
                                    {/* 
                                  </IonCol> */}{" "}
                                  </SwiperSlide>
                                );
                              })}
                            </Swiper>{" "}
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {post.post.photo.length > 1 ? (
                                <IonIcon
                                  size="large"
                                  icon={ellipsisHorizontalSharp}
                                />
                              ) : (
                                <></>
                              )}
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                    <IonCard
                      style={{
                        padding: "10px",
                        margin: "10px",
                        borderRadius: "10px",
                      }}
                    >
                      <IonItem>
                        <RichText
                          truncate={true}
                          text={post.post.description}
                        />
                      </IonItem>
                      {post.post.tags && (
                        <>
                          {post.post.tags.map((tag: string, index: number) => {
                            return (
                              <IonChip
                                onClick={() => {
                                  router.push(`/tag/${tag}`);
                                }}
                                key={index}
                              >
                                <IonLabel>#{tag}</IonLabel>
                              </IonChip>
                            );
                          })}
                        </>
                      )}
                    </IonCard>
                    {!beingShared && <PostInteractions postID={postID} />}
                  </IonCard>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <>
                <IonCard className="card">
                  <IonCardHeader>
                    <IonToolbar>
                      <IonButtons slot="start">
                        <IonItem className="profileLink" button>
                          <IonRow
                            style={{
                              margin: "1rem",
                            }}
                            class="ion-align-items-center ion-justify-content-start"
                          >
                            <IonAvatar class="ion-margin-end">
                              <IonSkeletonText
                                animated={true}
                              ></IonSkeletonText>
                            </IonAvatar>
                            <IonLabel>
                              <IonSkeletonText
                                style={{ width: "10em" }}
                                animated={true}
                              ></IonSkeletonText>
                            </IonLabel>
                          </IonRow>
                        </IonItem>
                      </IonButtons>
                    </IonToolbar>
                    <IonGrid>
                      <IonItem>
                        <IonTitle>
                          <IonSkeletonText
                            style={{ width: "30vw", height: "30vh" }}
                            animated={true}
                          ></IonSkeletonText>
                        </IonTitle>
                      </IonItem>
                    </IonGrid>
                  </IonCardHeader>

                  <IonCard
                    style={{
                      padding: "10px",
                      margin: "10px",
                      borderRadius: "10px",
                    }}
                  >
                    <LinkItUrl>
                      <IonText color={"dark"}>
                        {" "}
                        <IonSkeletonText animated={true}></IonSkeletonText>
                      </IonText>
                    </LinkItUrl>
                  </IonCard>
                </IonCard>
              </>
            )}
          </>
        ) : (
          <IonCard>
            <IonCardHeader>
              <IonToolbar>
                <IonButtons slot="start">
                  <IonItem className="profileLink" button>
                    <IonRow
                      style={{
                        margin: "1rem",
                      }}
                      class="ion-align-items-center ion-justify-content-start"
                    >
                      <IonAvatar class="ion-margin-end">
                        <IonSkeletonText animated={true}></IonSkeletonText>
                      </IonAvatar>
                      <IonLabel>
                        <IonSkeletonText
                          style={{ width: "10em" }}
                          animated={true}
                        ></IonSkeletonText>
                      </IonLabel>
                    </IonRow>
                  </IonItem>
                </IonButtons>
              </IonToolbar>
              <IonGrid>
                <IonItem>
                  <p>
                    An error occurred. Please check the URL and try again, else
                    the post may have been deleted.
                  </p>
                </IonItem>
              </IonGrid>
            </IonCardHeader>
          </IonCard>
        )}
      </>
    </>
  );
};
export default PostPreview;
