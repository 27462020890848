import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonTextarea,
  useIonAlert,
  useIonLoading,
  useIonRouter,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import { useContext, useState } from "react";
import LocationSearch from "../../components/sitewide/LocationSearch";
import { EnvContext } from "../../lib/envcontext";
import axios from "axios";
import { location } from "../../lib/types";
import { NavBarVisibilityContext } from "../../lib/navbarvisibilitycontext";
import "../../theme/standard.css";
import GenericBackToolbar from "../../components/sitewide/GenericBackToolbar";
import { trash } from "ionicons/icons";
const CreateJobProfile = () => {
  interface educationint {
    institution: string;
    qualification: string;
  }
  interface workExperienceint {
    company: string;
    role: string;
    startDate: string;
    endDate: string;
    description: string;
  }

  const router = useIonRouter();
  const { environment } = useContext(EnvContext);
  const [present, dismiss] = useIonLoading();
  const [location, setLocation] = useState({} as location);
  const [about, setAbout] = useState("");
  const [phone, setPhone] = useState("");
  const [FullName, setFullname] = useState("");
  const [occupation, setOccupation] = useState("");
  const [education, setEducation] = useState<educationint[]>([]);
  const [workExperience, setWorkExperience] = useState<workExperienceint[]>([]);
  const { setNavbarVisibility } = useContext(NavBarVisibilityContext);
  const [tempInstitution, setTempInstitution] = useState("");
  const [tempQualification, setTempQualification] = useState("");
  const [tempCompany, setTempCompany] = useState("");
  const [tempRole, setTempRole] = useState("");
  const [tempStartDate, setTempStartDate] = useState("");
  const [tempEndDate, setTempEndDate] = useState("");
  const [tempDescription, setTempDescription] = useState("");
  const [show] = useIonAlert();

  useIonViewWillEnter(() => {
    setNavbarVisibility(false);
  }, [setNavbarVisibility]);
  useIonViewWillLeave(() => {
    setNavbarVisibility(true);
  }, [setNavbarVisibility]);
  return (
    <IonPage>
      <IonContent fullscreen>
        <GenericBackToolbar
          name="Create Job Profile"
          allwaysGoBackTo="/JobBoard"
        />
        <div className="wrapper">
          <IonCard className="mobileResize">
            <IonCardHeader>
              <p>
                Your Job profile is different to your user profile. Your Job
                profile is how you will be seen to employers on the job board,
                think of it like a poster telling the world who you are. Your
                user profile is your personal profile that you can use to
                communicate with other users, employers can contact you through
                your personal profile if they want to hire you.
              </p>
            </IonCardHeader>
            <IonCardContent>
              <IonGrid>
                <IonItem>
                  <IonInput
                    name="Full Name"
                    autocomplete="name"
                    label="Full Name"
                    labelPlacement="floating"
                    id="FullName"
                    debounce={500}
                    value={FullName}
                    onIonChange={(e: any) => {
                      setFullname(e.detail.value);
                    }}
                  />{" "}
                </IonItem>

                <IonItem>
                  <IonInput
                    name="Occupation"
                    label="Occupation"
                    labelPlacement="floating"
                    debounce={500}
                    onIonChange={(e: any) => {
                      setOccupation(e.detail.value);
                    }}
                  />{" "}
                </IonItem>
                <IonItem>
                  <IonTextarea
                    style={{
                      height: "10em",
                    }}
                    name="About"
                    label="About"
                    labelPlacement="floating"
                    debounce={500}
                    onIonChange={(e: any) => {
                      setAbout(e.detail.value);
                    }}
                  />{" "}
                </IonItem>
                <p>
                  Tell us about what you do, what you're good at, what you're
                  looking for, and anything else you want to share. This is
                  where you should put infomation that you expect employers to
                  be searching for
                </p>
                <IonItem>
                  <IonInput
                    name="Phone"
                    label="Phone"
                    labelPlacement="floating"
                    debounce={500}
                    onIonChange={(e: any) => {
                      setPhone(e.detail.value);
                    }}
                  />{" "}
                </IonItem>

                <LocationSearch location={location} setLocation={setLocation} />

                <br />
                <IonCard
                  style={{
                    backgroundColor: "var(--ion-color-light-tint)",
                  }}
                >
                  <IonCardHeader>
                    <IonCardTitle>Education</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonList>
                      <IonItem>
                        <IonInput
                          value={tempInstitution}
                          name="Institution"
                          label="Institution"
                          labelPlacement="floating"
                          debounce={500}
                          onIonChange={(e: any) => {
                            setTempInstitution(e.detail.value);
                          }}
                        />
                      </IonItem>
                      <IonItem>
                        <IonInput
                          value={tempQualification}
                          name="Qualification"
                          label="Qualification"
                          labelPlacement="floating"
                          debounce={500}
                          onIonChange={(e: any) => {
                            setTempQualification(e.detail.value);
                          }}
                        />
                      </IonItem>
                    </IonList>
                    <br />
                    <IonButton
                      disabled={
                        tempInstitution === "" || tempQualification === ""
                      }
                      onClick={() => [
                        setEducation([
                          ...education,
                          {
                            institution: tempInstitution,
                            qualification: tempQualification,
                          },
                        ]),
                        setTempInstitution(""),
                        setTempQualification(""),
                      ]}
                      expand="block"
                    >
                      Add
                    </IonButton>
                    <br />

                    {education.length > 0 ? (
                      <IonList>
                        {education.map((item, index) => (
                          <IonItem key={index}>
                            <IonLabel>{item.institution}</IonLabel>
                            <IonLabel>{item.qualification}</IonLabel>
                            <IonButton
                              fill="clear"
                              style={{
                                color: "var(--ion-color-danger)",
                              }}
                              onClick={() => {
                                setEducation(
                                  education.filter(
                                    (education) =>
                                      education.institution !==
                                        item.institution &&
                                      education.qualification !==
                                        item.qualification
                                  )
                                );
                              }}
                            >
                              <IonIcon icon={trash} />
                            </IonButton>
                          </IonItem>
                        ))}
                      </IonList>
                    ) : null}
                  </IonCardContent>
                </IonCard>
                <br />
                <IonCard
                  style={{
                    backgroundColor: "var(--ion-color-light-tint)",
                  }}
                >
                  <IonCardHeader>
                    <IonCardTitle>Work Experience</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonList>
                      <IonItem>
                        <IonInput
                          name="Company"
                          label="Company"
                          labelPlacement="floating"
                          debounce={500}
                          onIonChange={(e: any) => {
                            setTempCompany(e.detail.value);
                          }}
                          value={tempCompany}
                        />
                      </IonItem>
                      <IonItem>
                        <IonInput
                          name="Role"
                          label="Role"
                          labelPlacement="floating"
                          debounce={500}
                          onIonChange={(e: any) => {
                            setTempRole(e.detail.value);
                          }}
                          value={tempRole}
                        />
                      </IonItem>
                      <IonItem>
                        <IonInput
                          name="Start Date"
                          label="Start Date"
                          labelPlacement="floating"
                          debounce={500}
                          onIonChange={(e: any) => {
                            setTempStartDate(e.detail.value);
                          }}
                          value={tempStartDate}
                        />
                      </IonItem>
                      <IonItem>
                        <IonInput
                          name="End Date"
                          label="End Date"
                          labelPlacement="floating"
                          debounce={500}
                          onIonChange={(e: any) => {
                            setTempEndDate(e.detail.value);
                          }}
                          value={tempEndDate}
                        />
                      </IonItem>
                      <IonItem>
                        <IonInput
                          name="Description"
                          label="Description"
                          labelPlacement="floating"
                          debounce={500}
                          onIonChange={(e: any) => {
                            setTempDescription(e.detail.value);
                          }}
                          value={tempDescription}
                        />
                      </IonItem>
                    </IonList>
                    <br />
                    <IonButton
                      disabled={
                        tempCompany === "" ||
                        tempRole === "" ||
                        tempStartDate === "" ||
                        tempEndDate === "" ||
                        tempDescription === ""
                      }
                      onClick={() => [
                        setWorkExperience([
                          ...workExperience,
                          {
                            company: tempCompany,
                            role: tempRole,
                            startDate: tempStartDate,
                            endDate: tempEndDate,
                            description: tempDescription,
                          },
                        ]),
                        setTempCompany(""),
                        setTempRole(""),
                        setTempStartDate(""),
                        setTempEndDate(""),
                        setTempDescription(""),
                      ]}
                      expand="block"
                    >
                      Add
                    </IonButton>
                    <br />
                    {workExperience.length > 0 ? (
                      <IonList>
                        {workExperience.map((item, index) => (
                          <IonItem key={index}>
                            <IonLabel>{item.company}</IonLabel>
                            <IonLabel>{item.role}</IonLabel>
                            <IonLabel>{item.startDate}</IonLabel>
                            <IonLabel>{item.endDate}</IonLabel>
                            <IonLabel>{item.description}</IonLabel>
                            <IonButton
                              fill="clear"
                              style={{
                                color: "var(--ion-color-danger)",
                              }}
                              onClick={() => {
                                setWorkExperience(
                                  workExperience.filter(
                                    (workExperience) =>
                                      workExperience.company !== item.company &&
                                      workExperience.role !== item.role &&
                                      workExperience.startDate !==
                                        item.startDate &&
                                      workExperience.endDate !== item.endDate &&
                                      workExperience.description !==
                                        item.description
                                  )
                                );
                              }}
                            >
                              <IonIcon icon={trash} />
                            </IonButton>
                          </IonItem>
                        ))}
                      </IonList>
                    ) : null}
                  </IonCardContent>
                </IonCard>
                <br />

                <IonButton
                  expand="block"
                  disabled={
                    about === "" ||
                    phone === "" ||
                    FullName === "" ||
                    !location.name
                  }
                  onClick={() => {
                    present("Creating Job Profile");
                    const formData = new FormData();
                    formData.append("about", about);
                    formData.append("phone", phone);
                    formData.append("name", FullName);
                    formData.append("location", JSON.stringify(location));
                    formData.append("education", JSON.stringify(education));
                    formData.append(
                      "experience",
                      JSON.stringify(workExperience)
                    );
                    formData.append("occupation", occupation);

                    axios({
                      method: "POST",
                      withCredentials: true,
                      url: `${environment.backendURL}/open/jobs/createJobProfile`,
                      data: formData,
                      headers: {
                        "Content-Type": "multipart/form-data",
                      },
                    })
                      .then((res) => {
                        dismiss();
                        // console.log(res);
                        router.push(`/JobBoard/profile/${res.data.postID}`);
                      })
                      .catch((err) => {
                        dismiss();
                        console.log(err);
                        show({
                          message: "Error Creating Profile",
                          header: "Error",
                          buttons: ["OK"],
                        });
                      });
                  }}
                >
                  Sign Up
                </IonButton>
              </IonGrid>
            </IonCardContent>
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default CreateJobProfile;
