import {
  IonButton,
  IonContent,
  IonItemDivider,
  IonLabel,
  IonList,
  IonPage,
  IonSkeletonText,
  IonText,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import GenericBackToolbar from "../../components/sitewide/GenericBackToolbar";
import { NavBarVisibilityContext } from "../../lib/navbarvisibilitycontext";
import { useContext, useState } from "react";
import { useParams } from "react-router";
import { EnvContext } from "../../lib/envcontext";
import { retrievePost } from "../../lib/util";
import PostMin from "../../components/socialMedia/PostMin";
import EditPostModal from "../../components/socialMedia/modal/EditPostModal";

const Campaign: React.FC = () => {
  const { setNavbarVisibility } = useContext(NavBarVisibilityContext);
  const { environment } = useContext(EnvContext);
  const [viewCredits, setViewCredits] = useState<number>(0);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const { id } = useParams<{ id: string }>();
  const [postData, setPostData] = useState({} as any);

  useIonViewWillEnter(async () => {
    setPostData(
      await retrievePost({ id: id, backendURL: environment.backendURL })
    );
    setNavbarVisibility(false);
  }, [setNavbarVisibility]);
  useIonViewWillLeave(() => {
    setNavbarVisibility(true);
  }, [setNavbarVisibility]);
  return (
    <>
      {" "}
      <EditPostModal
        editModalOpen={editModalOpen}
        setEditModalOpen={setEditModalOpen}
        post={postData}
        setPost={setPostData}
        postID={id}
        campaign={true}
      />{" "}
      <IonPage>
        <IonContent fullscreen>
          <GenericBackToolbar name="Campaign Management" />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "1rem",
            }}
          >
            <div>
              <PostMin post={id} />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {Object.keys(postData).length !== 0 ? (
                <>
                  <h1>Statistics</h1>
                  <IonList
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <i>
                      Views:{" "}
                      <IonText color="primary">{postData.post.views}</IonText>
                    </i>
                    <i>
                      Clicks:{" "}
                      <IonText color="primary">{postData.post.clicks}</IonText>
                    </i>
                    <i>
                      Likes:{" "}
                      <IonText color="primary">{postData.likes.length}</IonText>
                    </i>
                    <i>
                      Comments:{" "}
                      <IonText color="primary">
                        {postData.comments.length}
                      </IonText>
                    </i>
                  </IonList>
                  <IonItemDivider />
                  <IonButton
                    onClick={() => {
                      setEditModalOpen(true);
                    }}
                  >
                    Edit Campaign
                  </IonButton>
                  <IonItemDivider />
                  <IonLabel>
                    Remaining credits:{" "}
                    <IonText color="primary">
                      {postData.post.viewCredits}
                    </IonText>
                  </IonLabel>
                  <IonItemDivider />
                  <div>
                    <h2>Buy More Credits </h2>
                    <p>
                      {" "}
                      A credit means that a human will see or click on your
                      advertisement, when someone views your post, you will be
                      charged 1 credit, when someone clicks on your post, you
                      will be charged 5 credits. if you do not want to be
                      charged for links you can remove the link from your post.
                    </p>
                    <IonButton
                      color={viewCredits === 1 ? "success" : "dark"}
                      onClick={() => {
                        setViewCredits(1);
                      }}
                    >
                      100 View Credits - $10.00
                    </IonButton>
                    <IonButton
                      color={viewCredits === 2 ? "success" : "dark"}
                      onClick={() => {
                        setViewCredits(2);
                      }}
                    >
                      500 View Credits - $50.00
                    </IonButton>
                    <IonButton
                      color={viewCredits === 3 ? "success" : "dark"}
                      onClick={() => {
                        setViewCredits(3);
                      }}
                    >
                      1000 View Credits - $100.00
                    </IonButton>
                    <IonButton
                      color={viewCredits === 4 ? "success" : "dark"}
                      onClick={() => {
                        setViewCredits(4);
                      }}
                    >
                      5000 View Credits - $500.00
                    </IonButton>
                    <IonButton
                      color={viewCredits === 5 ? "success" : "dark"}
                      onClick={() => {
                        setViewCredits(5);
                      }}
                    >
                      10000 View Credits - $1000.00
                    </IonButton>
                    <IonItemDivider />

                    {/* <IonRow>
                    {" "}
                    <IonInput
                      style={{
                        maxWidth: "15em",
                      }}
                      placeholder="Enter a coupon code"
                    />
                    <IonButton
                      color="dark"
                      onClick={() => {
                        alert("not implemented");
                      }}
                    >
                      Apply Coupon
                    </IonButton>
                  </IonRow> */}

                    <IonButton
                      disabled={viewCredits === 0}
                      color="primary"
                      onClick={() => {
                        fetch(
                          environment.backendURL + "/payments/createURL/" + id,
                          {
                            credentials: "include",
                            method: "POST",
                            headers: {
                              "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                              id: viewCredits,
                            }),
                          }
                        )
                          .then((res) => res.json())
                          .then((res) => {
                            if (res.url) {
                              window.location = res.url;
                            } else {
                              console.log(res);
                            }
                          });
                      }}
                    >
                      Checkout with stripe
                    </IonButton>
                  </div>
                </>
              ) : (
                <>
                  {" "}
                  <h1>Statistics</h1>
                  <IonList
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <i>
                      Views: <IonSkeletonText animated />
                    </i>
                    <i>
                      Likes: Views: <IonSkeletonText animated />{" "}
                    </i>
                    <i>
                      Comments: Views: <IonSkeletonText animated />
                    </i>
                  </IonList>
                  <IonItemDivider />
                  <IonLabel>
                    Remaining view credits:{" "}
                    <IonText color="primary">
                      <IonSkeletonText animated />{" "}
                    </IonText>
                  </IonLabel>
                </>
              )}
            </div>
          </div>
        </IonContent>
      </IonPage>
    </>
  );
};

export default Campaign;
