const retrievePost = async (params: { id: string; backendURL: string }) => {
  const { id, backendURL } = params;
  return await fetch(`${backendURL}/open/socialmedia/retrievePost/${id}`, {
    credentials: "include",
  })
    .then((res) => res.json())
    .then((data) => {
      return data;
    });
};

const retrieveComment = async (params: { id: string; backendURL: string }) => {
  const { id, backendURL } = params;
  return await fetch(`${backendURL}/open/socialmedia/retrievecomment/${id}`, {
    credentials: "include",
  })
    .then((res) => res.json())
    .then((data) => {
      return data;
    });
};

const retrieveUserBasic = async (params: {
  email: string;
  backendURL: string;
}) => {
  const { email, backendURL } = params;
  return await fetch(`${backendURL}/open/user/retrieveProfile/basic/${email}`, {
    credentials: "include",
  }).then((res) =>
    res.json().then((data) => {
      return data;
    })
  );
};

const getActiveTags = async (params: { backendURL: string }) => {
  const { backendURL } = params;
  return await fetch(`${backendURL}/open/sitewide/gettags`, {
    credentials: "include",
  })
    .then((res) => res.json())
    .then((data) => {
      const filtered = data.filter((tag: any) => tag.active === true);
      return filtered;
    });
};

const getAllTags = async (params: { backendURL: string }) => {
  const { backendURL } = params;
  return await fetch(`${backendURL}/open/sitewide/gettags`, {
    credentials: "include",
  })
    .then((res) => res.json())
    .then((data) => {
      return data;
    });
};

export {
  retrievePost,
  retrieveComment,
  retrieveUserBasic,
  getActiveTags,
  getAllTags,
};
