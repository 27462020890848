import {
  IonContent,
  IonPage,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import { useParams } from "react-router";
import PostPreview from "../../components/socialMedia/PostPreview";
import GenericBackToolbar from "../../components/sitewide/GenericBackToolbar";
import { useContext } from "react";
import { NavBarVisibilityContext } from "../../lib/navbarvisibilitycontext";

const Post: React.FC = () => {
  const { setNavbarVisibility } = useContext(NavBarVisibilityContext);
  useIonViewWillEnter(() => {
    setNavbarVisibility(false);
  }, [setNavbarVisibility]);
  useIonViewWillLeave(() => {
    setNavbarVisibility(true);
  }, [setNavbarVisibility]);
  const { id } = useParams<{ id: string }>();
  return (
    <IonPage
      style={{
        width: "100vw",
        height: "100vh",
      }}
    >
      <IonContent fullscreen>
        <GenericBackToolbar name="Post" />
        <div
          style={{
            padding: "1rem",
            marginBottom: "2rem",
          }}
        >
          <PostPreview postID={id} />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Post;
