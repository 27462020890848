import {
  IonButton,
  IonCard,
  IonCardContent,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonPage,
  useIonAlert,
  useIonLoading,
  useIonRouter,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import LocationSearch from "../../components/sitewide/LocationSearch";
import PhotoUpload from "../../components/sitewide/PhotoUpload";
import { EnvContext } from "../../lib/envcontext";
import { UserContext } from "../../lib/usercontext";
import axios from "axios";
import { location } from "../../lib/types";
import { NavBarVisibilityContext } from "../../lib/navbarvisibilitycontext";
import { useParams } from "react-router";
import "../../theme/standard.css";
const CreateBusinessAccount = () => {
  const router = useIonRouter();
  const [presentAlert] = useIonAlert();
  const { user } = useContext(UserContext);
  const { environment } = useContext(EnvContext);
  const [photo, setPhoto] = useState([]);
  const [present, dismiss] = useIonLoading();
  const [location, setLocation] = useState({} as location);
  const [displayname, setDisplayname] = useState("");
  const [businessType, setBusinessType] = useState("");
  const [about, setAbout] = useState("");
  const [phone, setPhone] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const { setNavbarVisibility } = useContext(NavBarVisibilityContext);
  const { id } = useParams<{ id: string }>();
  const [show] = useIonAlert();
  const [errors, setErrors] = useState({
    displayname: false,
    businessType: false,
    about: false,
    phone: false,
    businessEmail: false,
  });
  useEffect(() => {
    fetch(`${environment.backendURL}/auth/validateEmail/${id}`, {
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.email) {
          setBusinessEmail(data.email);
        } else {
          show({
            backdropDismiss: false,
            message: "Invalid email verification link",

            buttons: [
              {
                text: "Ok",
                handler: () => {
                  router.push("/Home", "root");
                },
              },
            ],
          });
        }
      });
  }, []);
  useIonViewWillEnter(() => {
    setNavbarVisibility(false);
  }, [setNavbarVisibility]);
  useIonViewWillLeave(() => {
    setNavbarVisibility(true);
  }, [setNavbarVisibility]);
  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="wrapper">
          <IonCard className="mobileResize">
            <IonCardContent>
              <IonGrid>
                <IonItem>
                  <IonInput
                    name="Business Email"
                    type="email"
                    autocomplete="email"
                    label="Business Email"
                    labelPlacement="floating"
                    id="email"
                    debounce={500}
                    value={businessEmail}
                    disabled={true}
                  />{" "}
                </IonItem>

                <IonItem>
                  <IonInput
                    name="Business Name"
                    label="Business Name"
                    labelPlacement="floating"
                    className={`${!errors.displayname && "ion-valid"} ${
                      errors.displayname && "ion-invalid"
                    } ${"ion-touched"}`}
                    errorText="Must be between 3 and 100 characters and not contain
                      special characters or spaces"
                    debounce={500}
                    onIonChange={(e: any) => {
                      if (
                        e.detail.value.match(
                          //Must have more than 5 characters and less than 100 and have no special characters or spaces other than . -
                          /^[a-zA-Z0-9-_.]{3,100}$/
                        ) ||
                        e.detail.value === ""
                      ) {
                        setErrors({ ...errors, displayname: false });
                        setDisplayname(e.detail.value.toLowerCase());
                      } else {
                        setErrors({ ...errors, displayname: true });
                        console.log("error");
                        setDisplayname("");
                      }
                    }}
                  />{" "}
                </IonItem>

                <IonItem>
                  <IonInput
                    name="Business Type"
                    label="Business Type"
                    labelPlacement="floating"
                    className={`${!errors.businessType && "ion-valid"} ${
                      errors.businessType && "ion-invalid"
                    } ${"ion-touched"}`}
                    errorText="Must be between 3 and 100 characters"
                    debounce={500}
                    onIonChange={(e: any) => {
                      if (
                        (e.detail.value.length > 3 &&
                          e.detail.value.length < 100) ||
                        e.detail.value.length === 0
                      ) {
                        setErrors({ ...errors, businessType: false });
                        setBusinessType(e.detail.value);
                      } else {
                        setErrors({ ...errors, businessType: true });
                        setBusinessType("");
                      }
                    }}
                  />{" "}
                </IonItem>

                <IonItem>
                  <IonInput
                    name="About Business"
                    label="About Business"
                    labelPlacement="floating"
                    className={`${!errors.about && "ion-valid"} ${
                      errors.about && "ion-invalid"
                    } ${"ion-touched"}`}
                    errorText="Must be less than 1000 characters"
                    debounce={500}
                    onIonChange={(e: any) => {
                      if (e.detail.value.length < 1000) {
                        setErrors({ ...errors, about: false });
                        setAbout(e.detail.value);
                      } else {
                        setErrors({ ...errors, about: true });
                        setAbout("");
                      }
                    }}
                  />{" "}
                </IonItem>

                <IonItem>
                  <IonInput
                    name="Contact Number"
                    label="Contact Number"
                    labelPlacement="floating"
                    className={`${!errors.phone && "ion-valid"} ${
                      errors.phone && "ion-invalid"
                    } ${"ion-touched"}`}
                    errorText="Must be a valid phone number"
                    debounce={500}
                    onIonChange={(e: any) => {
                      //regex to ensure it is a phone number

                      if (
                        e.detail.value.length < 100 ||
                        e.detail.value === ""
                      ) {
                        setErrors({ ...errors, phone: false });
                        setPhone(e.detail.value);
                      } else {
                        setErrors({ ...errors, phone: true });
                        setPhone("");
                      }
                    }}
                  />{" "}
                </IonItem>

                <LocationSearch location={location} setLocation={setLocation} />

                <br />
                <PhotoUpload
                  photo={photo}
                  setPhoto={setPhoto}
                  mode="photo"
                  messageText={"Business Profile Picture"}
                  maxWidth={100}
                  maxHeight={100}
                />
                <br />

                <IonButton
                  disabled={
                    errors.displayname ||
                    errors.businessType ||
                    errors.about ||
                    errors.phone ||
                    errors.businessEmail ||
                    displayname === "" ||
                    businessType === "" ||
                    about === "" ||
                    phone === "" ||
                    businessEmail === "" ||
                    photo.length === 0 ||
                    !location.name
                  }
                  onClick={() => {
                    present("Signing up...");
                    const formData = new FormData();
                    formData.append("photo", photo[0]);
                    formData.append("displayname", displayname);
                    formData.append("businessType", businessType);
                    formData.append("about", about);
                    formData.append("phone", phone);
                    formData.append("businessEmail", businessEmail);
                    formData.append("controllerEmail", user.user.email);
                    formData.append("licences", "[]");
                    formData.append("location", location.name);
                    formData.append("point", JSON.stringify(location));

                    axios({
                      method: "POST",
                      withCredentials: true,
                      url: `${environment.backendURL}/open/user/createbusiness`,
                      data: formData,
                      headers: {
                        "Content-Type": "multipart/form-data",
                      },
                    })
                      .then((res) => {
                        dismiss();

                        router.canGoBack()
                          ? router.goBack()
                          : router.push("/Home");
                      })
                      .catch((err) => {
                        console.log(err);
                        dismiss();
                        presentAlert({
                          header: "Error",
                          message: `Error creating business account ${err.response.data.errors[0].msg}`,
                        });
                      });
                  }}
                >
                  Sign Up
                </IonButton>
              </IonGrid>
            </IonCardContent>
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default CreateBusinessAccount;
