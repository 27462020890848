import {
  IonCard,
  IonItem,
  IonLabel,
  IonList,
  IonSearchbar,
} from "@ionic/react";
import { useContext, useState } from "react";
import { EnvContext } from "../../../lib/envcontext";
import { titlePostID } from "../../../lib/types";
import PostMin from "../../socialMedia/PostMin";
import "../../../theme/standard.css";

const SearchPost: React.FC = () => {
  const [searchResults, setSearchResults] = useState(<></>);
  const { environment } = useContext(EnvContext);
  const fetchPosts = async (name: string) => {
    if (name.length > 0) {
      const response = await fetch(
        `${environment.backendURL}/open/socialmedia/postSearch/${name}`,
        {
          credentials: "include",
        }
      );
      const data = await response.json();
      const postIDs = data.map((post: titlePostID) => post.postID);
      //map search results to display in search results list
      setSearchResults(
        postIDs.map((postID: string, index: number) => {
          return <PostMin post={postID} key={index} />;
        })
      );
    }
  };
  return (
    <IonCard
      className="mobileResize"
      style={{
        height: "60vh",
      }}
    >
      <IonSearchbar
        onIonInput={(e: any) => {
          if (e.detail.value === "") {
            setSearchResults(<></>);
          }

          fetchPosts(e.detail.value);
        }}
        debounce={500}
        placeholder="Search posts"
      ></IonSearchbar>

      {searchResults ? (
        <div
          style={{
            overflowY: "auto",
            height: "100%",
          }}
        >
          <IonList
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {" "}
            {searchResults}
          </IonList>
        </div>
      ) : (
        <IonItem>
          <IonLabel>
            <h2>No Results</h2>
            <h3>Please enter a valid post into the field</h3>
          </IonLabel>
        </IonItem>
      )}
    </IonCard>
  );
};

export default SearchPost;
