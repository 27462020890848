import {
  IonCard,
  IonItem,
  IonLabel,
  IonList,
  IonSearchbar,
} from "@ionic/react";
import { useContext, useState } from "react";
import { EnvContext } from "../../../lib/envcontext";
import { emailDisplayname } from "../../../lib/types";
import "../../../theme/standard.css";
import UserItem from "../UserItem";

const SearchUser: React.FC = () => {
  const fetchUsers = async (name: string) => {
    if (name.length > 1) {
      const response = await fetch(
        `${environment.backendURL}/open/user/search/${name}`,
        {
          credentials: "include",
        }
      );
      const data = await response.json();
      //map search results to display in search results list
      setSearchResults(
        data.map((result: emailDisplayname, index: number) => {
          return <UserItem email={result.email} link={true} key={index} />;
        })
      );
    }
  };
  const [searchResults, setSearchResults] = useState(<></>);
  const { environment } = useContext(EnvContext);
  return (
    <IonCard
      className="mobileResize"
      style={{
        height: "60vh",
      }}
    >
      <IonSearchbar
        onIonInput={(e: any) => {
          if (e.detail.value === "") {
            setSearchResults(<></>);
          }
          fetchUsers(e.detail.value);
        }}
        debounce={500}
        placeholder="Search users"
      ></IonSearchbar>
      {searchResults ? (
        <div
          style={{
            overflowY: "auto",
            height: "100%",
          }}
        >
          <IonList>{searchResults}</IonList>
        </div>
      ) : (
        <IonItem>
          <IonLabel>
            <h2>No Results</h2>
            <h3>Please enter a valid username into the field</h3>
          </IonLabel>
        </IonItem>
      )}
    </IonCard>
  );
};

export default SearchUser;
