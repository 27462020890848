import {
  IonButton,
  IonButtons,
  IonContent,
  IonIcon,
  IonInput,
  IonItemDivider,
  IonModal,
  IonToolbar,
  useIonLoading,
} from "@ionic/react";
import { chevronBackSharp } from "ionicons/icons";
import { useContext, useState } from "react";
import { EnvContext } from "../../../lib/envcontext";
import { PostData } from "../../../lib/types";
interface EditPostModalProps {
  setEditModalOpen: (value: boolean) => void;
  editModalOpen: boolean;
  postID: string;
  post: PostData;
  setPost: React.Dispatch<React.SetStateAction<PostData>>;
  setOwnerModalOpen?: (value: boolean) => void;
  campaign?: boolean;
}

const EditPostModal: React.FC<EditPostModalProps> = ({
  editModalOpen,
  setEditModalOpen,
  postID,
  post,
  setPost,
  setOwnerModalOpen,
  campaign,
}) => {
  const [editModalForm, setEditModalForm] = useState({
    postTitle: "",
    description: "",
    link: "",
  });
  const [present, dismiss] = useIonLoading();
  const { environment } = useContext(EnvContext);
  return (
    <IonModal
      onDidDismiss={() => {
        setEditModalOpen(false);
      }}
      isOpen={editModalOpen}
    >
      <IonToolbar>
        <IonButtons slot="start">
          <IonButton
            onClick={() => {
              if (setOwnerModalOpen) {
                setOwnerModalOpen(true);
              }
              setEditModalOpen(false);
            }}
          >
            <IonIcon icon={chevronBackSharp} />
          </IonButton>
        </IonButtons>
      </IonToolbar>
      <IonContent className="ion-padding">
        {/* {fullscreen} */}
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <IonInput
            id="editPostTitle"
            onIonChange={(e: any) => {
              setEditModalForm({
                ...editModalForm,
                postTitle: e.detail.value!,
              });
            }}
            label="Post Title"
            labelPlacement="floating"
            className={`${editModalForm.postTitle.length > 3 && "ion-valid"} ${
              editModalForm.postTitle.length <= 3 && "ion-invalid"
            } ${"ion-touched"}`}
            errorText="Must have more than 3 characters"
            debounce={500}
          />
          <IonItemDivider />

          <IonInput
            id="editPostDescription"
            onIonChange={(e: any) => {
              setEditModalForm({
                ...editModalForm,
                description: e.detail.value!,
              });
            }}
            name="description"
            label="Description"
            labelPlacement="floating"
            className={`${
              editModalForm.description.length > 3 && "ion-valid"
            } ${
              editModalForm.description.length <= 3 && "ion-invalid"
            } ${"ion-touched"}`}
            errorText="Must have more than 3 characters"
            debounce={500}
          />
          <IonItemDivider />
          {campaign && (
            <>
              <IonInput
                id="editPostLink"
                onIonChange={(e: any) => {
                  setEditModalForm({
                    ...editModalForm,
                    link: e.detail.value!,
                  });
                }}
                name="link"
                label="Link"
                labelPlacement="floating"
                className={`${
                  editModalForm.link.match(
                    /^https?:\/\/(?:[^./?#]+\.)?[^./?#]+(?:\.[^./?#]+)*(?:\/[^./?#]*(?:\.[^./?#]+)*(?:[?#][^./?#]+)?)?$/i
                  ) && "ion-valid"
                } ${
                  !editModalForm.link.match(
                    /^https?:\/\/(?:[^./?#]+\.)?[^./?#]+(?:\.[^./?#]+)*(?:\/[^./?#]*(?:\.[^./?#]+)*(?:[?#][^./?#]+)?)?$/i
                  ) && "ion-invalid"
                } ${"ion-touched"}`}
                errorText="Must be a valid URL (http://example.com)"
                debounce={500}
              />
              <IonItemDivider />
            </>
          )}
          <IonButton
            disabled={
              editModalForm.postTitle.length <= 3 ||
              editModalForm.description.length <= 3
            }
            id="editPostButton"
            onClick={() => {
              present("Updating post...");
              const formData = new FormData();
              formData.append("title", editModalForm.postTitle);
              formData.append("description", editModalForm.description);
              if (campaign && editModalForm.link !== "") {
                formData.append("link", editModalForm.link);
              }
              fetch(
                `${environment.backendURL}/open/socialmedia/updatePost/${postID}`,
                {
                  method: "POST",
                  body: formData,
                  credentials: "include",
                }
              )
                .then((res) => res.json())
                .then((res) => {
                  console.log(res);
                  dismiss();
                  setPost({
                    ...post,
                    post: {
                      ...post.post,
                      title: editModalForm.postTitle,
                      description: editModalForm.description,
                    },
                  });
                  setEditModalOpen(false);
                });
            }}
          >
            Submit
          </IonButton>
          <IonItemDivider />
        </div>
      </IonContent>
    </IonModal>
  );
};

export default EditPostModal;
