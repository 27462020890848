import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonGrid,
  IonInput,
  IonItem,
  IonRow,
  useIonAlert,
  useIonLoading,
  useIonRouter,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../lib/usercontext";
import { EnvContext } from "../../lib/envcontext";
import "./Login.css";
import axios from "axios";
import OauthButtons from "./OauthButtons";
import ResetPasswordModal from "./Modal/ResetPasswordModal";
import EnterEmailModal from "./Modal/EnterEmailModal";
const LoginComponent = () => {
  const { setUser } = useContext(UserContext);
  const { environment } = useContext(EnvContext);
  const [presentAlert] = useIonAlert();

  useEffect(() => {
    fetch(`${environment.backendURL}/auth/authdetails`, {
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.user !== false) {
          router.push("/Home", "root");
        }
      })
      .catch((err) => {
        if (err) {
          presentAlert({
            cssClass: "my-css",
            header: "Network Error",
            message: "Please check your internet connection and try again.",
            buttons: [
              {
                text: "Reload",
                handler: () => {
                  window.location.reload();
                },
              },
            ],
          });
          console.log(err);
        }
      });
  }, [environment]);
  const router = useIonRouter();
  const [show, hide] = useIonLoading();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  return (
    <div className="wrapper">
      <IonCard className="loginCard">
        <IonCardHeader>
          <IonCardTitle>Login</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <IonGrid>
            <IonRow>
              <IonItem>
                <IonInput
                  label="Email"
                  labelPlacement="floating"
                  autocomplete="email"
                  name="email"
                  onIonInput={(e: any) => {
                    setEmail(e.detail.value.toLowerCase());
                  }}
                />{" "}
              </IonItem>
            </IonRow>
            <IonRow>
              <IonItem>
                <IonInput
                  label="Password"
                  type="password"
                  autocomplete="current-password"
                  labelPlacement="floating"
                  onIonInput={(e: any) => {
                    setPassword(e.detail.value);
                  }}
                />{" "}
              </IonItem>
            </IonRow>
            <br />
            <IonRow>
              <IonButton
                disabled={
                  email.length === 0 || password.length === 0 ? true : false
                }
                onClick={() => {
                  show("Logging in...");
                  axios({
                    method: "POST",
                    data: {
                      email: email,
                      password: password,
                    },
                    withCredentials: true,
                    url: `${environment.backendURL}/auth/login`,
                  })
                    .then((data) => {
                      console.log(data);
                      hide();
                      if (data.data.errors) {
                        presentAlert({
                          message: `
                            Please check your login details and try again.
                           ERROR: ${data.data.errors[0].msg}
                          `,
                          buttons: ["OK"],
                        });
                        return;
                      }
                      setUser({
                        user: {
                          displayname: data.data.displayname,
                          email: data.data.email,
                          photo: `${environment.storageURL}/photos/profile/${
                            data?.data.email
                          }.png?${Date.now()}`,
                          type: "person",
                          admin: data.data.admin,
                        },
                      });
                      hide();
                      router.push("/Home");
                    })
                    .catch((err) => {
                      console.log(err);
                      hide();
                      presentAlert({
                        message: `
                          Please check your login details and try again.
                        `,
                        buttons: ["OK"],
                      });
                    });
                }}
              >
                Login
              </IonButton>
            </IonRow>
            <br />
            <ResetPasswordModal />
          </IonGrid>
        </IonCardContent>
      </IonCard>
      <IonCard
        style={{
          padding: "1em",
        }}
        className="loginCard"
      >
        <OauthButtons />
        <br />
        <EnterEmailModal
          text="
            New to Renos? Sign Up
          "
          type="user"
        />
      </IonCard>
    </div>
  );
};

export default LoginComponent;
