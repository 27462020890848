import {
  IonButton,
  IonCol,
  IonInput,
  IonItem,
  IonRow,
  IonText,
} from "@ionic/react";
import { useState } from "react";
import { location } from "../../lib/types";
interface LocationProps {
  setLocation: Function;
  location: location;
  disableSave?: Function;
}

const LocationSearch = ({
  location,
  setLocation,
  disableSave = () => {},
}: LocationProps) => {
  const [locations, setLocations] = useState<Array<location>>([]);
  return (
    <>
      {!location.name ? (
        <IonRow>
          <IonItem
            style={{
              width: "100%",
            }}
          >
            <IonInput
              name="location"
              errorText="Please enter a valid suburb/city"
              label="Location Search"
              labelPlacement="floating"
              debounce={150}
              onIonInput={(e) => {
                fetch(
                  `https://api.locationiq.com/v1/autocomplete?key=pk.4038bfcf8cbb6eb3a538609224798ab2&countrycodes=au&tag=place:suburb&q=${e.detail.value}`
                )
                  .then((res) => res.json())
                  .then((data) => {
                    if (data.length > 0) {
                      setLocations(
                        data.map((location: any) => {
                          return {
                            name:
                              location.address.name +
                              ", " +
                              location.address.state,
                            lat: Number(location.lat),
                            lon: Number(location.lon),
                          };
                        })
                      );
                    } else {
                      setLocations([]);
                    }
                  });
              }}
            />
          </IonItem>
        </IonRow>
      ) : (
        <>
          <IonItem
            style={{
              width: "100%",
            }}
          >
            <IonText slot={"start"}>{location.name}</IonText>
          </IonItem>
          <IonButton
            data-cy="clear-location"
            onClick={() => {
              setLocation("");
              disableSave(true);
            }}
          >
            clear
          </IonButton>
          <br />
        </>
      )}
      {locations.length !== 0 && (
        <IonCol>
          {locations.map((location, index) => (
            <IonItem
              id={location.name}
              button
              key={index}
              onClick={() => {
                setLocation(location);
                setLocations([]);
                disableSave(false);
              }}
            >
              {location.name}
            </IonItem>
          ))}
        </IonCol>
      )}
    </>
  );
};

export default LocationSearch;
