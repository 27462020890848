import { useRef } from "react";
import {
  IonButton,
  IonCard,
  IonIcon,
  IonImg,
  IonRow,
  useIonAlert,
  useIonViewWillLeave,
} from "@ionic/react";
import { camera } from "ionicons/icons";
import Compressor from "compressorjs";

type MediaType = "photo" | "video" | "anyMedia";

interface PhotoUploadProps {
  photo: File[];
  setPhoto: any;
  messageText?: string;
  mode: MediaType;
  multiple?: boolean;
  quality?: number;
  maxWidth?: number;
  maxHeight?: number;
  iconOnly?: boolean;
}

const PhotoUpload = ({
  photo,
  setPhoto,
  messageText,
  mode,
  multiple,
  quality,
  maxWidth,
  maxHeight,
  iconOnly,
}: PhotoUploadProps) => {
  const [presentAlert] = useIonAlert();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  useIonViewWillLeave(() => {
    setPhoto([]);
  });

  const handleFileInputChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!e.target.files) return;

    const newPhotos: File[] = [];
    const promises: Promise<File>[] = [];

    for (const file of e.target.files) {
      const fileType = file.name.split(".").pop()?.toLowerCase()!;
      console.log(file);
      const photoRegex = /^(jpeg|jpg|png)$/i;
      const videoRegex = /^(mp4|mov|quicktime)$/i;
      const anyMediaRegex = /^(jpeg|jpg|png|mp4|mov|quicktime)$/i;

      if (
        (mode === "photo" && !photoRegex.test(fileType)) ||
        (mode === "video" && !videoRegex.test(fileType)) ||
        (mode === "anyMedia" && !anyMediaRegex.test(fileType))
      ) {
        presentAlert({
          header: "Error",
          message: `Please select ${
            mode === "photo"
              ? "either a png or jpg file"
              : mode === "video"
              ? "an mp4 file"
              : "either a png, jpg, mov or mp4 file"
          }`,
          buttons: ["OK"],
        });

        //clear the input
        fileInputRef.current!.value = "";
        console.log(file);
        return;
      }

      if (videoRegex.test(fileType)) {
        newPhotos.push(file);
      } else {
        promises.push(
          new Promise((resolve, reject) => {
            new Compressor(file, {
              quality: quality ?? 0.8,
              maxWidth: maxWidth ?? 1000,
              maxHeight: maxHeight ?? 1000,
              success: (compressed) => {
                //@ts-ignore
                resolve(compressed);
              },
              error: (err) => {
                reject(err);
              },
            });
          })
        );
      }
    }

    const compressedPhotos = await Promise.all(promises);
    const allPhotos = [...newPhotos, ...compressedPhotos];

    if (multiple && photo.length > 0) {
      setPhoto([...photo, ...allPhotos]);
    } else {
      setPhoto(allPhotos);
    }

    fileInputRef.current!.value = "";
  };

  const handleUploadButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handlePhotoCardClick = (media: File) => {
    setPhoto(photo.filter((p) => p !== media));
  };

  return (
    <>
      <IonRow>
        <input
          type="file"
          id="file-upload"
          multiple={multiple}
          style={{ display: "none" }}
          onChange={handleFileInputChange}
          ref={fileInputRef}
        />
        {iconOnly ? (
          <IonButton
            fill="clear"
            id="upload-button"
            onClick={handleUploadButtonClick}
          >
            <IonIcon slot="start" color={"dark"} icon={camera} />
          </IonButton>
        ) : (
          <IonButton id="upload-button" onClick={handleUploadButtonClick}>
            <IonIcon slot="start" icon={camera} />
            {messageText ? messageText : "Upload Photo"}
          </IonButton>
        )}
      </IonRow>
      <IonRow>
        {photo.map((media, index) => (
          <IonCard
            style={{
              width: "10em",
              height: "10em",
            }}
            key={index}
            onClick={() => handlePhotoCardClick(media)}
          >
            {/^(mp4|mov|quicktime)$/i.test(
              media.name.split(".").pop()?.toLowerCase()!
            ) ? (
              <video
                style={{
                  width: "100%",
                  height: "100%",
                }}
                src={URL.createObjectURL(media)}
              />
            ) : (
              <IonImg
                style={{
                  width: "100%",
                  height: "100%",
                }}
                src={URL.createObjectURL(media)}
              />
            )}
          </IonCard>
        ))}
      </IonRow>
    </>
  );
};

export default PhotoUpload;
