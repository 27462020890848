import {
  IonButtons,
  IonContent,
  IonImg,
  IonPage,
  IonToolbar,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import { useContext } from "react";
import { NavBarVisibilityContext } from "../../lib/navbarvisibilitycontext";
import LoginComponent from "../../components/user/LoginComponent";
import "./Splash.css";
import EnterEmailModal from "../../components/user/Modal/EnterEmailModal";
interface SectionProps {
  text: string;
  image: string;
}
const Section: React.FC<SectionProps> = ({ text, image }) => {
  return (
    <div className="section">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          width: "70vw",
          textAlign: "center",
        }}
      >
        <p>{text}</p>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <IonImg
          style={{
            width: "25vw",
          }}
          src={image}
          alt={text}
        />
      </div>
    </div>
  );
};

const Splash: React.FC = () => {
  const { setNavbarVisibility } = useContext(NavBarVisibilityContext);

  useIonViewWillEnter(() => {
    setNavbarVisibility(false);
  }, [setNavbarVisibility]);
  useIonViewWillLeave(() => {
    setNavbarVisibility(true);
  }, [setNavbarVisibility]);
  return (
    <IonPage>
      <IonToolbar id="main" color="light">
        <IonButtons>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            {" "}
            <svg
              width="100%"
              height="9vh"
              viewBox="0 0 232 298"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M211 40V31L306.75 31C377.501 31 377.999 131 306.75 131H280L356 231H332.064L256 131.5L256 107H301.645C347.612 107 347.291 55 301.645 55L236 55V231H211V71.5L180 31L149 71.5V231H124V55L58.3546 55C12.7093 55 12.3879 107 58.3546 107H104L104 131.5L27.9357 231H4L80 131H53.2496C-17.9988 131 -17.5005 31 53.2496 31L149 31V40L156 31L180 0L204 31L211 40Z"
                fill="#F4F5F8"
              />
              <path d="M163 98.8929H176.852V73H163V98.8929Z" fill="#F4F5F8" />
              <path d="M197 73H183.148V98.8929H197V73Z" fill="#F4F5F8" />
              <path d="M163 104.071H176.852V131H163V104.071Z" fill="#F4F5F8" />
              <path d="M183.148 131V104.071H197V131H183.148Z" fill="#F4F5F8" />
            </svg>
            <i>© Renos & Rebuilds</i>
          </div>
        </IonButtons>
      </IonToolbar>
      <IonContent fullscreen>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div style={{ padding: "1em", textAlign: "center" }}>
            <h1>The ultimate app for tradies</h1>
            <h2>Find work, grow your network, and promote your services.</h2>
          </div>
          {/* <p>Share the Build, Share the Reno, Share your Dream.</p> */}
          <LoginComponent />

          <div className="widthResize">
            <Section
              text=" With our social media feature, you can easily connect with other
                tradesmen in your area, share your expertise, and stay
                up-to-date with industry news and trends."
              image="assets/img/TradesmenSpeaking.webp"
            />
            <br />

            <Section
              text=" Looking for a new job or project? Our jobs board allows you to
                search and apply for jobs in your field, all in one convenient
                location.
              "
              image="assets/img/LookingForJob.webp"
            />
            <br />
            <Section
              text="With our marketplace, you can easily promote your services and
                find new clients."
              image="assets/img/BuyingTools.webp"
            />
            <br />

            <Section
              text="Our app also features a real estate board, where you can find
                and list properties that are ideal for your business needs.
                Whether you're looking for a new workspace or a location for
                your next project, our app has you covered."
              image="assets/img/BuildingHouse.webp"
            />

            <div
              style={{
                marginBottom: "2em",
              }}
            >
              <h1>Join your workmates on Renos</h1>
              <EnterEmailModal text="Get started" type="user" />
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Splash;
