import { useContext, useEffect, useState } from "react";
import { EnvContext } from "../../lib/envcontext";
import UserItem from "../sitewide/UserItem";
import { IonCard, IonRow } from "@ionic/react";
import ModerationActionsMenu from "./ModerationActionsMenu";
import { CommentData } from "../../lib/types";
import { retrieveComment } from "../../lib/util";
interface CommentPreviewProps {
  id: string;
  reason: string;
  reportID: string;
}

const CommentPreview: React.FC<CommentPreviewProps> = ({
  id,
  reason,
  reportID,
}) => {
  const { environment } = useContext(EnvContext);
  const [commentData, setCommentData] = useState({} as CommentData);
  const [hidden, setHidden] = useState(false);
  useEffect(() => {
    retrieveComment({ id: id, backendURL: environment.backendURL }).then(
      (data: CommentData) => {
        setCommentData(data);
      }
    );
  }, [environment, id]);
  return (
    <>
      {!hidden && (
        <IonCard
          style={{
            padding: "1rem",
          }}
        >
          <UserItem link={true} email={commentData?.email} />
          <p>Comment: {commentData?.message}</p>
          <IonRow>Reason for report: {reason}</IonRow>
          <br />
          <ModerationActionsMenu
            email={commentData?.email}
            postID={id}
            reportID={reportID}
            setHidden={setHidden}
          />
        </IonCard>
      )}
    </>
  );
};

export default CommentPreview;
