import {
  IonAvatar,
  IonBadge,
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPopover,
  IonText,
  useIonRouter,
} from "@ionic/react";
import { chatbubblesSharp, notificationsSharp } from "ionicons/icons";
import { useContext, useEffect, useState } from "react";
import { EnvContext } from "../../lib/envcontext";
import { UserContext } from "../../lib/usercontext";
import useWebSocket, { ReadyState } from "react-use-websocket";

interface notification {
  type: string;
  target?: string;
  commentText?: string;

  userDetails: {
    displayname: string;
    email: string;
  };
}

const NotificationsBar = () => {
  const { environment } = useContext(EnvContext);
  const { user } = useContext(UserContext);
  const router = useIonRouter();
  const [alerts, setAlerts] = useState({
    newAlerts: 0,
    newMessages: 0,
  });
  const [notifications, setNotifications] = useState<notification[]>([]);
  const socketUrl = `${
    environment.backendURL.startsWith("https")
      ? environment.backendURL.replace("https", "wss")
      : environment.backendURL.replace("http", "ws")
    // the email param is for forcing the frontend to refresh the session when the email changes
  }/open/sitewide/listenonnotification/${user?.user?.email}`;
  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl);
  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  useEffect(() => {
    if (connectionStatus === "Open") {
      sendMessage(JSON.stringify({ email: user?.user?.email }));
    }
  }, [connectionStatus, user?.user?.email]);

  useEffect(() => {
    if (lastMessage !== null) {
      setAlerts(JSON.parse(lastMessage.data));
    }
  }, [lastMessage]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      {" "}
      <div>
        <IonButton
          onClick={() => {
            fetch(`${environment.backendURL}/open/sitewide/getNotifications`, {
              credentials: "include",
            })
              .then((res) => res.json())
              .then((data) => {
                Promise.all(
                  data.map(async (notification: any) => {
                    const userDetails = await fetch(
                      `${environment.backendURL}/open/user/retrieveprofile/basic/${notification.email}`,
                      {
                        credentials: "include",
                      }
                    )
                      .then((res) => res.json())
                      .then((data) => {
                        return data;
                      });

                    return {
                      type: notification.type,
                      userDetails: userDetails,
                      target: notification?.target,
                      commentText: notification?.commentText,
                    };
                  })
                ).then((resolvedNotifications) => {
                  console.log(resolvedNotifications);
                  setNotifications(resolvedNotifications);
                });
              });
          }}
          id="notifications"
          color="dark"
        >
          <IonIcon slot="icon-only" icon={notificationsSharp} />
          {alerts.newAlerts > 0 ? (
            <IonBadge
              style={{
                position: "absolute",
                top: "-0.3rem",
                right: "-0.3rem",
              }}
              color="danger"
            >
              <IonText>{alerts.newAlerts}</IonText>
            </IonBadge>
          ) : null}
        </IonButton>
        <IonPopover trigger="notifications" triggerAction="click">
          <IonContent
            style={{
              height: "100vh",
            }}
            class="ion-padding"
          >
            {notifications.length > 0 ? (
              <IonList
                style={{
                  width: "100%",
                }}
              >
                {notifications.map((notification, key) => {
                  return (
                    <IonItem
                      key={key}
                      button
                      onClick={() => {
                        router.push(
                          notification.target
                            ? `/post/${notification.target}`
                            : `/profile/${notification.userDetails.email}`
                        );
                      }}
                    >
                      <IonAvatar slot="start">
                        <img
                          src={`${environment.storageURL}/photos/profile/${
                            notification.userDetails.email
                          }.png?${Date.now()}`}
                          alt="profile"
                        />{" "}
                      </IonAvatar>
                      <IonLabel>
                        <p
                          style={{
                            fontWeight: "bold",
                            fontSize: "0.8rem",
                            width: "100%",
                            wordWrap: "break-word", // Added this line
                            whiteSpace: "normal",
                          }}
                        >
                          {notification.userDetails.displayname}{" "}
                          {notification.type === "like"
                            ? "liked your post"
                            : null}
                          {notification.type === "follow"
                            ? "followed you"
                            : null}
                          {notification.type === "comment" ? (
                            <span>
                              commented on your post:{" "}
                              <em>{notification.commentText}</em>
                            </span>
                          ) : null}
                        </p>
                      </IonLabel>
                    </IonItem>
                  );
                })}
              </IonList>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  marginTop: "1rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <IonIcon icon={notificationsSharp} size="large" />
                <IonText>No new notifications</IonText>
              </div>
            )}
          </IonContent>
        </IonPopover>
      </div>
      <IonButton id="messages" color="dark" routerLink="/Home/messages">
        <IonIcon slot="icon-only" icon={chatbubblesSharp} />
        {alerts.newMessages > 0 ? (
          <IonBadge
            style={{
              position: "absolute",
              top: "-0.3rem",
              right: "-0.3rem",
            }}
            color="danger"
          >
            <IonText>{alerts.newMessages}</IonText>
          </IonBadge>
        ) : null}
      </IonButton>
    </div>
  );
};

export default NotificationsBar;
