import { IonButton, IonRow, useIonAlert } from "@ionic/react";
import { useContext } from "react";
import { EnvContext } from "../../lib/envcontext";

interface MenuProps {
  email: string;
  postID?: string;
  reportID: string;
  setHidden: (hidden: boolean) => void;
}

const ModerationActionsMenu: React.FC<MenuProps> = ({
  email,
  postID,
  reportID,
  setHidden,
}) => {
  const { environment } = useContext(EnvContext);
  const [presentAlert] = useIonAlert();

  function approvePost() {
    fetch(`${environment.backendURL}/moderator/closereport/${reportID}`, {
      credentials: "include",
      method: "POST",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setHidden(true);
      });
  }
  function deletePost() {
    presentAlert({
      header: "Delete post",
      message: "Are you sure you want to delete this post?",

      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
        },

        {
          text: "Okay",
          handler: () => {
            fetch(`${environment.backendURL}/moderator/deleteitem/${postID}`, {
              credentials: "include",
              method: "DELETE",
            })
              .then((res) => res.json())
              .then((data) => {
                console.log(data);
                setHidden(true);
              });
          },
        },
      ],
    });
  }

  function banUser() {
    presentAlert({
      header: "Ban user",
      message: "Are you sure you want to ban this user?",

      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
        },

        {
          text: "Okay",
          handler: () => {
            fetch(`${environment.backendURL}/moderator/banUser/${email}`, {
              credentials: "include",
              method: "POST",
            })
              .then((res) => res.json())
              .then((data) => {
                console.log(data);
                setHidden(true);
                approvePost();
              });
          },
        },
      ],
    });
  }

  return (
    <>
      <IonRow>
        <IonButton onClick={approvePost}>Approve</IonButton>
        {postID && (
          <IonButton color={"danger"} onClick={deletePost}>
            Delete item
          </IonButton>
        )}
        <IonButton color={"danger"} onClick={banUser}>
          Ban user
        </IonButton>
      </IonRow>
    </>
  );
};

export default ModerationActionsMenu;
