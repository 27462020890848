import {
  IonButton,
  IonContent,
  IonInput,
  IonItem,
  IonModal,
  IonPage,
  IonSearchbar,
  IonToolbar,
  useIonLoading,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { EnvContext } from "../../lib/envcontext";
import GenericBackToolbar from "../../components/sitewide/GenericBackToolbar";
import { NavBarVisibilityContext } from "../../lib/navbarvisibilitycontext";

const TagManagement: React.FC = () => {
  interface Tag {
    tag: string;
    active: boolean;
  }
  const { setNavbarVisibility } = useContext(NavBarVisibilityContext);

  useIonViewWillEnter(() => {
    setNavbarVisibility(false);
  }, [setNavbarVisibility]);
  useIonViewWillLeave(() => {
    setNavbarVisibility(true);
  }, [setNavbarVisibility]);
  const { environment } = useContext(EnvContext);
  const [tags, setTags] = useState<Tag[]>([]);
  const [search, setSearch] = useState("");
  const [present, dismiss] = useIonLoading();
  const [modalOpen, setModalOpen] = useState(false);
  const [newTag, setNewTag] = useState("");
  useEffect(() => {
    fetch(`${environment.backendURL}/open/sitewide/gettags`, {
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        setTags(data);
      });
  }, []);
  return (
    <>
      <IonModal onDidDismiss={() => setModalOpen(false)} isOpen={modalOpen}>
        <IonToolbar>
          <IonButton
            fill="clear"
            onClick={() => {
              setModalOpen(false);
            }}
          >
            Close
          </IonButton>
        </IonToolbar>
        <IonContent className="ion-padding">
          <IonItem>
            <IonInput
              onIonChange={(e) => {
                setNewTag(e.detail.value!.toString());
              }}
              placeholder="Tag Name"
            ></IonInput>
            <IonButton
              onClick={() => {
                if (newTag !== "") {
                  let newTags = [
                    {
                      tag: newTag,
                      active: true,
                    },
                    ...tags,
                  ];
                  setTags(newTags);
                  setModalOpen(false);
                }
              }}
            >
              Add Tag
            </IonButton>
          </IonItem>
        </IonContent>
      </IonModal>
      <IonPage>
        <IonContent fullscreen>
          <GenericBackToolbar name="Tag Management" />
          <IonToolbar>
            <IonButton
              onClick={() => {
                present("Saving...");
                fetch(`${environment.backendURL}/moderator/updatetags`, {
                  credentials: "include",
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(tags),
                })
                  .then((res) => res.json())
                  .then((data) => {
                    console.log(data);
                    dismiss();
                  })
                  .catch((e) => {
                    dismiss();
                    console.log(e);
                  });
              }}
            >
              Save Changes
            </IonButton>
            <IonButton
              onClick={() => {
                setModalOpen(true);
              }}
            >
              Add Tag
            </IonButton>
          </IonToolbar>
          <IonSearchbar
            onIonChange={(e) => {
              setSearch(e.detail.value!);
            }}
          ></IonSearchbar>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {tags.map((tag, index) => {
              if (tag.tag.toLowerCase().includes(search.toLowerCase())) {
                return (
                  <IonItem
                    button
                    style={{
                      width: "100vw",
                    }}
                    key={index}
                    color={tag.active ? "light" : "danger"}
                    onClick={() => {
                      let newTags = [...tags];
                      newTags[index].active = !newTags[index].active;
                      setTags(newTags);
                    }}
                  >
                    {tag.tag}
                  </IonItem>
                );
              }
            })}
          </div>
        </IonContent>
      </IonPage>
    </>
  );
};

export default TagManagement;
