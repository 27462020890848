import {
  IonCard,
  IonContent,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonList,
  IonText,
  useIonViewWillEnter,
} from "@ionic/react";
import { useContext, useState, useEffect } from "react";
import { UserContext } from "../../lib/usercontext";
import PostPreview from "./PostPreview";
import "./BigPostWall.css";
import { checkmarkCircleSharp } from "ionicons/icons";
import { EnvContext } from "../../lib/envcontext";

async function fetchData(url: string) {
  try {
    const response = await fetch(url, {
      credentials: "include",
    });
    const data = await response.json();

    if (response.ok) {
      return data;
    }
    throw new Error(`Error: ${data.message}`);
  } catch (error) {
    console.error(error);
    return [];
  }
}

const BigPostWall = () => {
  const { user } = useContext(UserContext);
  const [followingData, setFollowingData] = useState([]);
  const [discoverData, setDiscoverData] = useState([]);
  const [tagData, setTagData] = useState([]);
  const { environment } = useContext(EnvContext);

  useIonViewWillEnter(async () => {
    if (user.user?.email) {
      const data = await fetchData(
        `${environment.backendURL}/open/socialmedia/following`
      );
      setFollowingData(data);
    }
  });

  useEffect(() => {
    if (user.user?.displayname) {
      const fetchTagFeed = async () => {
        const data = await fetchData(
          `${environment.backendURL}/open/socialmedia/tagFeed`
        );
        setTagData(data);
      };

      fetchTagFeed();
    }
  }, [user.user.displayname, environment]);

  const discovery = async () => {
    const data = await fetchData(
      `${environment.backendURL}/open/socialmedia/discover`
    );
    const filteredData = data.filter(
      //@ts-ignore
      (id) => !followingData.includes(id) && !discoverData.includes(id)
    );

    setDiscoverData((prevDiscoverData) =>
      prevDiscoverData.concat(filteredData)
    );
  };

  // Use useEffect to call discovery function once on component mount
  useEffect(() => {
    discovery();
  }, []);
  return (
    <IonContent>
      <div className="bigpostwall">
        {followingData.length ? (
          <IonList>
            {followingData?.map((postID: string, index: number) => (
              <PostPreview key={index} postID={postID} />
            ))}
          </IonList>
        ) : (
          <></>
        )}
        <br />
        {followingData.length !== 0 && (
          <IonCard
            //this won't extrapolate into the css file for some reason
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              height: "100%",
              padding: "3rem",
            }}
          >
            <IonIcon icon={checkmarkCircleSharp} className="checkmark" />
            <IonText>
              <h1> You smashed the follow feed!</h1>
            </IonText>
            <IonText>
              <h1>scroll down to find new posts!</h1>
            </IonText>
          </IonCard>
        )}
        <br />
        <IonList>
          {tagData?.map((postID: string, index: number) => (
            <PostPreview postID={postID} key={index} />
          ))}
        </IonList>
        <IonList>
          {
            //if data.error is true, then display error message

            discoverData?.map((postID: string, index: number) => (
              <PostPreview postID={postID} key={index} />
            ))
          }
        </IonList>
        <IonInfiniteScroll
          onIonInfinite={(e) => {
            discovery();
            setTimeout(() => e.target.complete(), 500);
          }}
          threshold="5em"
        >
          <IonInfiniteScrollContent
            loadingSpinner="bubbles"
            loadingText="Loading more posts..."
          ></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </div>
    </IonContent>
  );
};

export default BigPostWall;
