import { IonAvatar, IonItem, IonLabel, useIonRouter } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { EnvContext } from "../../lib/envcontext";
import { Person } from "../../lib/types";
import { retrieveUserBasic } from "../../lib/util";
interface UserItemProps {
  email: string;
  link: boolean;
  color?: string;
  badge?: any;
}

const UserItem = ({ email, link, color, badge }: UserItemProps) => {
  const { environment } = useContext(EnvContext);
  const [userData, setUserData] = useState<Person>();
  const [error, setError] = useState(false);
  const router = useIonRouter();
  useEffect(() => {
    if (email) {
      retrieveUserBasic({
        email: email,
        backendURL: environment.backendURL,
      }).then((data: any) => {
        if (data.error) {
          console.log(data);
          setError(true);
        } else {
          setUserData(data);
        }
      });
    }
  }, [email, environment]);

  return (
    <>
      {!error ? (
        userData && (
          <IonItem
            color={color}
            button
            onClick={() => {
              link === true &&
                router.push(`/home/profile/${userData?.displayname}`);
            }}
          >
            <IonAvatar slot="start">
              <img
                src={`${environment?.storageURL}/photos/profile/${userData?.email}.png`}
                alt="avatar"
              />
              <div
                style={{
                  position: "absolute",
                  top: "2rem",
                  left: "2.5rem",
                }}
              >
                {badge}
              </div>
            </IonAvatar>
            <IonLabel>
              <h2>{userData?.displayname}</h2>
            </IonLabel>
          </IonItem>
        )
      ) : (
        <IonItem>
          <IonLabel>
            <h2>User not found</h2>
          </IonLabel>
        </IonItem>
      )}
    </>
  );
};
export default UserItem;
