import {
  IonButton,
  IonButtons,
  IonContent,
  IonIcon,
  IonInput,
  IonModal,
  IonToolbar,
  useIonAlert,
  useIonLoading,
} from "@ionic/react";
import { chevronBackSharp } from "ionicons/icons";
import { useContext, useState } from "react";
import { EnvContext } from "../../../lib/envcontext";
import Captcha from "../../sitewide/Captcha";

const ResetPasswordModal = () => {
  const [visible, setVisible] = useState(false);
  const { environment } = useContext(EnvContext);
  const [email, setEmail] = useState("");
  const [presentAlert] = useIonAlert();
  const [captcha, setCapcha] = useState("");
  const [captchaKey, setCaptchaKey] = useState(0);
  const [emailSent, setEmailSent] = useState(false);
  const [show, hide] = useIonLoading();
  return (
    <>
      {visible ? (
        <IonModal
          onDidDismiss={() => {
            setVisible(false);
          }}
          isOpen={visible}
        >
          {" "}
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton
                onClick={() => {
                  setVisible(false);
                }}
              >
                <IonIcon icon={chevronBackSharp} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
          {emailSent ? (
            <>
              <IonContent>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    width: "100%",
                    height: "100%",
                    textAlign: "center",
                  }}
                >
                  <svg
                    width="260"
                    height="131"
                    viewBox="0 0 360 231"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M211 40V31L306.75 31C377.501 31 377.999 131 306.75 131H280L356 231H332.064L256 131.5L256 107H301.645C347.612 107 347.291 55 301.645 55L236 55V231H211V71.5L180 31L149 71.5V231H124V55L58.3546 55C12.7093 55 12.3879 107 58.3546 107H104L104 131.5L27.9357 231H4L80 131H53.2496C-17.9988 131 -17.5005 31 53.2496 31L149 31V40L156 31L180 0L204 31L211 40Z"
                      fill="#F4F5F8"
                    />
                    <path
                      d="M163 98.8929H176.852V73H163V98.8929Z"
                      fill="#F4F5F8"
                    />
                    <path d="M197 73H183.148V98.8929H197V73Z" fill="#F4F5F8" />
                    <path
                      d="M163 104.071H176.852V131H163V104.071Z"
                      fill="#F4F5F8"
                    />
                    <path
                      d="M183.148 131V104.071H197V131H183.148Z"
                      fill="#F4F5F8"
                    />
                  </svg>

                  <h1>Reset Link Sent!</h1>
                  <h3>
                    We have sent you an email with a link to reset your
                    password, If you do not receive an email within 5 minutes,
                    please check your spam folder.
                  </h3>
                </div>
              </IonContent>
            </>
          ) : (
            <>
              <IonContent
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                    textAlign: "center",
                  }}
                >
                  <h1>Reset Password</h1>
                  <p>
                    Enter your email address and we will send you a link to
                    reset your password.
                  </p>
                  <IonInput
                    label="Email"
                    labelPlacement="floating"
                    type="email"
                    style={{
                      width: "50%",
                    }}
                    value={email}
                    onIonChange={(e) => {
                      setEmail(e.detail.value!.toString());
                    }}
                  />
                  <br />
                  <IonButton
                    disabled={captcha === "" || email === ""}
                    onClick={() => {
                      show("Sending Reset Link...");
                      const formBody = new FormData();
                      formBody.append("email", email.toLowerCase());
                      formBody.append("captcha", captcha);
                      fetch(
                        `${environment.backendURL}/auth/sendpasswordreset`,
                        {
                          method: "POST",
                          body: formBody,
                        }
                      )
                        .then((res) => {
                          hide();
                          if (res.status == 200) {
                            setEmailSent(true);
                          } else {
                            presentAlert({
                              message:
                                "An Error has occured when sending the reset link, please try again",
                              buttons: ["Ok"],
                            });
                            setCaptchaKey(Math.random());
                            setCapcha("");
                          }
                        })
                        .catch((e) => {
                          hide();
                          presentAlert({
                            message:
                              "An Error has occured when sending the reset link, please try again",
                            buttons: ["Ok"],
                          });
                          setCaptchaKey(Math.random());
                          setCapcha("");
                        });
                    }}
                    style={{
                      width: "50%",
                    }}
                  >
                    Send Reset Link
                  </IonButton>
                  <br />
                  <Captcha
                    key={captchaKey}
                    onsuccess={(token: string) => {
                      setCapcha(token);
                    }}
                  />
                </div>
              </IonContent>
            </>
          )}
        </IonModal>
      ) : null}
      <>
        <IonButton
          size="default"
          fill="clear"
          color={"medium"}
          onClick={() => {
            setVisible(true);
          }}
        >
          <b>Forgot Password?</b>
        </IonButton>
      </>
    </>
  );
};

export default ResetPasswordModal;
