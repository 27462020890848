import {
  IonContent,
  IonLabel,
  IonPage,
  IonSegment,
  IonSegmentButton,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import { useContext, useState } from "react";
import "./Search.css";
import SearchPost from "../../components/sitewide/SearchTypes/SearchPost";
import SearchUser from "../../components/sitewide/SearchTypes/SearchUser";
import SearchTag from "../../components/sitewide/SearchTypes/SearchTag";
import { ToolBarVisibilityContext } from "../../lib/toolbarvisibilitycontext";
const Search: React.FC = () => {
  const [searchType, setSearchType] = useState("user");
  const { setToolbarVisibility } = useContext(ToolBarVisibilityContext);
  useIonViewWillEnter(() => {
    setToolbarVisibility(true);
  });
  useIonViewWillLeave(() => {
    setToolbarVisibility(false);
  });
  const RenderType = () => {
    switch (searchType) {
      case "user":
        return <SearchUser />;
      case "post":
        return <SearchPost />;
      case "tag":
        return <SearchTag />;
    }
  };
  return (
    <IonPage
      style={{
        marginTop: "9vh",
      }}
    >
      <IonContent>
        <IonSegment
          scrollable={true}
          class="segment"
          onIonChange={(e: any) => {
            setSearchType(e.detail.value!);
          }}
          value={searchType}
        >
          <IonSegmentButton value="user">
            <IonLabel>Users</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="post">
            <IonLabel>Posts</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="tag">
            <IonLabel>Tags</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton disabled={true} value="realestate">
            <IonLabel>RealEstate</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton disabled={true} value="jobs">
            <IonLabel>Jobs</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton disabled={true} value="marketplace">
            <IonLabel>Marketplace</IonLabel>
          </IonSegmentButton>
        </IonSegment>
        <div className="fullwidthFlexColumn">{RenderType()}</div>
      </IonContent>
    </IonPage>
  );
};

export default Search;
