import {
  IonContent,
  IonPage,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import ExploreContainer from "../../components/sitewide/ExploreContainer";
import { useContext } from "react";
import { NavBarVisibilityContext } from "../../lib/navbarvisibilitycontext";

const Contact: React.FC = () => {
  const { setNavbarVisibility } = useContext(NavBarVisibilityContext);

  useIonViewWillEnter(() => {
    setNavbarVisibility(false);
  }, [setNavbarVisibility]);
  useIonViewWillLeave(() => {
    setNavbarVisibility(true);
  }, [setNavbarVisibility]);
  return (
    <IonPage>
      <IonContent fullscreen>
        <div
          style={{
            padding: "1rem 10rem 1rem 10rem",
          }}
        >
          <h1>Contact Us</h1>

          <p>
            For any queries or technical concerns, please contact our admin team
            at{" "}
            <a href="mailto:admin@renosandrebuilds.com">
              admin@renosandrebuilds.com
            </a>
            .
          </p>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Contact;
