import {
  IonButton,
  IonCard,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonPage,
  IonProgressBar,
  useIonAlert,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import { useContext, useState } from "react";
import { UserContext } from "../../lib/usercontext";
import { useIonRouter } from "@ionic/react";
import { EnvContext } from "../../lib/envcontext";
import PhotoUpload from "../../components/sitewide/PhotoUpload";
import axios from "axios";
import GenericBackToolbar from "../../components/sitewide/GenericBackToolbar";
import SmartTextField from "../../components/sitewide/SmartTextField";
import TagSelector from "../../components/sitewide/TagSelector";
import { NavBarVisibilityContext } from "../../lib/navbarvisibilitycontext";
import "../../theme/standard.css";

interface errors {
  postTitle: boolean;
  description: boolean;
  tags: boolean;
}
const CreatePost: React.FC = () => {
  const router = useIonRouter();
  const [presentAlert] = useIonAlert();
  const { environment } = useContext(EnvContext);
  const [photo, setPhoto] = useState([]);
  const [postTitle, setPostTitle] = useState("");
  const [description, setDescription] = useState("");
  const [tags, setTags] = useState<string[]>([]);
  const { user } = useContext(UserContext);
  const [progress, setProgress] = useState<number>();
  const [errors, setErrors] = useState({} as errors);
  const { setNavbarVisibility } = useContext(NavBarVisibilityContext);

  useIonViewWillEnter(() => {
    setNavbarVisibility(false);
  }, [setNavbarVisibility]);
  useIonViewWillLeave(() => {
    setNavbarVisibility(true);
  }, [setNavbarVisibility]);
  return (
    <IonPage>
      <IonContent fullscreen>
        <GenericBackToolbar
          name="New post"
          button={
            <IonButton
              color="primary"
              onClick={() => {
                if (
                  postTitle === "" ||
                  description === "" ||
                  errors.postTitle ||
                  errors.description
                ) {
                  presentAlert({
                    header: "Error",
                    message: "Please fill in all fields",
                    buttons: ["OK"],
                  });
                } else {
                  // present("Uploading post...");
                  const formData = new FormData();
                  // for each photo, append it to the form data
                  photo.forEach((photo) => {
                    formData.append("sampleFile", photo);
                  });
                  formData.append("email", user.user.email);
                  formData.append("title", postTitle);
                  formData.append("description", description);
                  formData.append("tags", tags ? JSON.stringify(tags) : "[]");

                  axios
                    .post(
                      `${environment.backendURL}/open/socialmedia/publishpost`,
                      formData,
                      {
                        withCredentials: true,
                        headers: {
                          "Content-Type": "multipart/form-data",
                        },

                        onUploadProgress: (progressEvent: any) => {
                          console.log(
                            "Upload Progress: " +
                              Math.round(
                                (progressEvent.loaded / progressEvent.total) *
                                  100
                              ) +
                              "%"
                          );
                          setProgress(
                            Math.round(
                              (progressEvent.loaded / progressEvent.total) * 100
                            )
                          );
                        },
                      }
                    )
                    .then((res) => {
                      if (res.status !== 200) {
                        presentAlert({
                          header: "Error",
                          message: "Something went wrong",
                          buttons: ["OK"],
                        });
                        console.log(res);
                        setProgress(undefined);
                      } else {
                        setProgress(undefined);
                        setPostTitle("");
                        setDescription("");
                        setTags([]);

                        setPhoto([]);

                        router.push("/Home");
                      }
                    })
                    .catch((err) => {
                      presentAlert({
                        header: "Error",
                        message: `Error: ${err.response.data.errors[0].msg}}`,
                        buttons: ["OK"],
                      });
                      console.log(err);
                      setProgress(undefined);
                    });
                }
              }}
            >
              <h6>Share</h6>
            </IonButton>
          }
        />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <IonCard
            className="mobileResize"
            style={{
              margin: "10px",
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <IonGrid>
              <IonItem>
                <IonInput
                  debounce={500}
                  name="postTitle"
                  value={postTitle}
                  className={`${!errors.postTitle && "ion-valid"} ${
                    errors.postTitle && "ion-invalid"
                  } ${"ion-touched"}`}
                  onIonChange={(e: any) => {
                    if (
                      (e.detail.value.length > 3 &&
                        e.detail.value.length < 100) ||
                      e.detail.value === ""
                    ) {
                      setErrors({ ...errors, postTitle: false });
                      setPostTitle(e.detail.value);
                    } else {
                      setErrors({ ...errors, postTitle: true });
                      setPostTitle(e.detail.value);
                    }
                  }}
                  label="Write a title"
                  labelPlacement="floating"
                  errorText="Must have more than 3 characters"
                />
              </IonItem>
              <br />
              <SmartTextField
                text={description}
                setText={(text) => {
                  setDescription(text);
                }}
                setError={(error) => {
                  setErrors({ ...errors, description: error });
                }}
                rules={/^(?=.{4,999}$)([\s\S]*)|^$/}
                errorText="Must have more than 3 characters"
                error={errors.description}
                debounce={500}
                placeholder="Write a description"
                name="description"
              />
              <br /> <br /> <br />
              <p>
                Tags are used to categorize your post. You can add as many as
                you want.
              </p>
              <TagSelector
                form={true}
                tags={tags}
                setTags={(tags) => {
                  setTags(tags);
                }}
                activeOnly={true}
              />
              <br />
              {!progress && (
                <PhotoUpload
                  photo={photo}
                  setPhoto={setPhoto}
                  mode="anyMedia"
                  multiple={true}
                />
              )}
              {progress && (
                <>
                  <br />
                  <IonProgressBar value={progress / 100}></IonProgressBar>
                </>
              )}
            </IonGrid>
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default CreatePost;
