import { Turnstile } from "@marsidev/react-turnstile";

interface CapchaProps {
  onsuccess: (token: string) => void;
}

const Captcha: React.FC<CapchaProps> = ({ onsuccess }) => {
  return (
    <Turnstile
      onSuccess={(token: string) => onsuccess(token)}
      siteKey="0x4AAAAAAADmPDI0uvfFftL4"
    />
  );
};

export default Captcha;
