import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonTextarea,
  useIonAlert,
  useIonLoading,
  useIonRouter,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import LocationSearch from "../../components/sitewide/LocationSearch";
import { EnvContext } from "../../lib/envcontext";
import axios from "axios";
import { location } from "../../lib/types";
import { NavBarVisibilityContext } from "../../lib/navbarvisibilitycontext";
import "../../theme/standard.css";
import GenericBackToolbar from "../../components/sitewide/GenericBackToolbar";
import { trash } from "ionicons/icons";
import { useParams } from "react-router";
const EditJobProfile = () => {
  interface educationint {
    institution: string;
    qualification: string;
  }
  interface workExperienceint {
    company: string;
    role: string;
    startDate: string;
    endDate: string;
    description: string;
  }

  const { environment } = useContext(EnvContext);
  const [present, dismiss] = useIonLoading();
  const [location, setLocation] = useState({} as location);
  const [about, setAbout] = useState("");
  const [phone, setPhone] = useState("");
  const [FullName, setFullname] = useState("");
  const [occupation, setOccupation] = useState("");
  const [education, setEducation] = useState<educationint[]>([]);
  const [workExperience, setWorkExperience] = useState<workExperienceint[]>([]);
  const { setNavbarVisibility } = useContext(NavBarVisibilityContext);
  const [tempInstitution, setTempInstitution] = useState("");
  const [tempQualification, setTempQualification] = useState("");
  const [tempCompany, setTempCompany] = useState("");
  const [tempRole, setTempRole] = useState("");
  const [tempStartDate, setTempStartDate] = useState("");
  const [tempEndDate, setTempEndDate] = useState("");
  const [tempDescription, setTempDescription] = useState("");
  const [show] = useIonAlert();
  const { id } = useParams<{ id: string }>();
  const router = useIonRouter();
  useIonViewWillEnter(() => {
    setNavbarVisibility(false);
  }, [setNavbarVisibility]);
  useIonViewWillLeave(() => {
    setNavbarVisibility(true);
  }, [setNavbarVisibility]);

  useEffect(() => {
    fetch(`${environment.backendURL}/open/jobs/retrieveJobProfile/${id}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setFullname(data.name);
        setOccupation(data.occupation);
        setAbout(data.about);
        setPhone(data.phone);
        setLocation({
          name: data.location,
          lat: data.point.y,
          lon: data.point.x,
        });
        setEducation(JSON.parse(JSON.parse(data.education)));
        setWorkExperience(JSON.parse(JSON.parse(data.experience)));
      });
  }, [id]);
  return (
    <IonPage>
      <IonContent fullscreen>
        <GenericBackToolbar
          name="Edit Job Profile"
          allwaysGoBackTo={`/JobBoard/profile/${id}`}
        />
        <div className="wrapper">
          <IonCard className="mobileResize">
            <IonCardContent>
              <IonGrid>
                <IonItem>
                  <IonInput
                    name="Full Name"
                    autocomplete="name"
                    label="Full Name"
                    labelPlacement="floating"
                    id="FullName"
                    value={FullName}
                    onIonChange={(e: any) => {
                      setFullname(e.detail.value);
                    }}
                  />{" "}
                </IonItem>

                <IonItem>
                  <IonInput
                    name="Occupation"
                    label="Occupation"
                    labelPlacement="floating"
                    value={occupation}
                    onIonChange={(e: any) => {
                      setOccupation(e.detail.value);
                    }}
                  />{" "}
                </IonItem>
                <IonItem>
                  <IonTextarea
                    style={{
                      height: "6em",
                    }}
                    name="About"
                    label="About"
                    labelPlacement="floating"
                    value={about}
                    onIonChange={(e: any) => {
                      setAbout(e.detail.value);
                    }}
                  />{" "}
                </IonItem>

                <IonItem>
                  <IonInput
                    name="Phone"
                    label="Phone"
                    labelPlacement="floating"
                    value={phone}
                    onIonChange={(e: any) => {
                      setPhone(e.detail.value);
                    }}
                  />{" "}
                </IonItem>

                <LocationSearch location={location} setLocation={setLocation} />

                <br />
                <IonCard
                  style={{
                    backgroundColor: "var(--ion-color-light-tint)",
                  }}
                >
                  <IonCardHeader>
                    <IonCardTitle>Education</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonList>
                      <IonItem>
                        <IonInput
                          value={tempInstitution}
                          name="Institution"
                          label="Institution"
                          labelPlacement="floating"
                          onIonChange={(e: any) => {
                            setTempInstitution(e.detail.value);
                          }}
                        />
                      </IonItem>
                      <IonItem>
                        <IonInput
                          value={tempQualification}
                          name="Qualification"
                          label="Qualification"
                          labelPlacement="floating"
                          onIonChange={(e: any) => {
                            setTempQualification(e.detail.value);
                          }}
                        />
                      </IonItem>
                    </IonList>
                    <br />
                    <IonButton
                      disabled={
                        tempInstitution === "" || tempQualification === ""
                      }
                      onClick={() => [
                        setEducation([
                          ...education,
                          {
                            institution: tempInstitution,
                            qualification: tempQualification,
                          },
                        ]),
                        setTempInstitution(""),
                        setTempQualification(""),
                      ]}
                      expand="block"
                    >
                      Add
                    </IonButton>
                    <br />

                    {education.length > 0 ? (
                      <IonList>
                        {education.map((item, index) => (
                          <IonItem key={index}>
                            <IonLabel>{item.institution}</IonLabel>
                            <IonLabel>{item.qualification}</IonLabel>
                            <IonButton
                              fill="clear"
                              style={{
                                color: "var(--ion-color-danger)",
                              }}
                              onClick={() => {
                                setEducation(
                                  education.filter(
                                    (education) =>
                                      education.institution !==
                                        item.institution &&
                                      education.qualification !==
                                        item.qualification
                                  )
                                );
                              }}
                            >
                              <IonIcon icon={trash} />
                            </IonButton>
                          </IonItem>
                        ))}
                      </IonList>
                    ) : null}
                  </IonCardContent>
                </IonCard>
                <br />
                <IonCard
                  style={{
                    backgroundColor: "var(--ion-color-light-tint)",
                  }}
                >
                  <IonCardHeader>
                    <IonCardTitle>Work Experience</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonList>
                      <IonItem>
                        <IonInput
                          name="Company"
                          label="Company"
                          labelPlacement="floating"
                          onIonChange={(e: any) => {
                            setTempCompany(e.detail.value);
                          }}
                          value={tempCompany}
                        />
                      </IonItem>
                      <IonItem>
                        <IonInput
                          name="Role"
                          label="Role"
                          labelPlacement="floating"
                          onIonChange={(e: any) => {
                            setTempRole(e.detail.value);
                          }}
                          value={tempRole}
                        />
                      </IonItem>
                      <IonItem>
                        <IonInput
                          name="Start Date"
                          label="Start Date"
                          labelPlacement="floating"
                          onIonChange={(e: any) => {
                            setTempStartDate(e.detail.value);
                          }}
                          value={tempStartDate}
                        />
                      </IonItem>
                      <IonItem>
                        <IonInput
                          name="End Date"
                          label="End Date"
                          labelPlacement="floating"
                          onIonChange={(e: any) => {
                            setTempEndDate(e.detail.value);
                          }}
                          value={tempEndDate}
                        />
                      </IonItem>
                      <IonItem>
                        <IonInput
                          name="Description"
                          label="Description"
                          labelPlacement="floating"
                          onIonChange={(e: any) => {
                            setTempDescription(e.detail.value);
                          }}
                          value={tempDescription}
                        />
                      </IonItem>
                    </IonList>
                    <br />
                    <IonButton
                      disabled={
                        tempCompany === "" ||
                        tempRole === "" ||
                        tempStartDate === "" ||
                        tempEndDate === "" ||
                        tempDescription === ""
                      }
                      onClick={() => [
                        setWorkExperience([
                          ...workExperience,
                          {
                            company: tempCompany,
                            role: tempRole,
                            startDate: tempStartDate,
                            endDate: tempEndDate,
                            description: tempDescription,
                          },
                        ]),
                        setTempCompany(""),
                        setTempRole(""),
                        setTempStartDate(""),
                        setTempEndDate(""),
                        setTempDescription(""),
                      ]}
                      expand="block"
                    >
                      Add
                    </IonButton>
                    <br />
                    {workExperience.length > 0 ? (
                      <IonList>
                        {workExperience.map((item, index) => (
                          <IonItem key={index}>
                            <IonLabel>{item.company}</IonLabel>
                            <IonLabel>{item.role}</IonLabel>
                            <IonLabel>{item.startDate}</IonLabel>
                            <IonLabel>{item.endDate}</IonLabel>
                            <IonLabel>{item.description}</IonLabel>
                            <IonButton
                              fill="clear"
                              style={{
                                color: "var(--ion-color-danger)",
                              }}
                              onClick={() => {
                                setWorkExperience(
                                  workExperience.filter(
                                    (workExperience) =>
                                      workExperience.company !== item.company &&
                                      workExperience.role !== item.role &&
                                      workExperience.startDate !==
                                        item.startDate &&
                                      workExperience.endDate !== item.endDate &&
                                      workExperience.description !==
                                        item.description
                                  )
                                );
                              }}
                            >
                              <IonIcon icon={trash} />
                            </IonButton>
                          </IonItem>
                        ))}
                      </IonList>
                    ) : null}
                  </IonCardContent>
                </IonCard>
                <br />

                <IonButton
                  expand="block"
                  disabled={
                    about === "" ||
                    phone === "" ||
                    FullName === "" ||
                    !location.name
                  }
                  onClick={() => {
                    present("editing Job Profile");
                    const formData = new FormData();
                    formData.append("about", about);
                    formData.append("phone", phone);
                    formData.append("name", FullName);
                    formData.append("location", JSON.stringify(location));
                    formData.append("education", JSON.stringify(education));
                    formData.append(
                      "experience",
                      JSON.stringify(workExperience)
                    );
                    formData.append("occupation", occupation);

                    axios({
                      method: "POST",
                      withCredentials: true,
                      url: `${environment.backendURL}/open/jobs/editJobProfile/${id}`,
                      data: formData,
                      headers: {
                        "Content-Type": "multipart/form-data",
                      },
                    })
                      .then((res) => {
                        dismiss();
                        window.location.href = `/jobboard/profile/${id}`;
                      })
                      .catch((err) => {
                        dismiss();
                        console.log(err);
                        show({
                          message: "Error editing Profile",
                          header: "Error",
                          buttons: ["OK"],
                        });
                      });
                  }}
                >
                  Save Details
                </IonButton>
              </IonGrid>
            </IonCardContent>
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default EditJobProfile;
