import {
  IonButton,
  IonButtons,
  IonChip,
  IonContent,
  IonIcon,
  IonLabel,
  IonModal,
  IonRow,
  IonToolbar,
  useIonAlert,
  useIonRouter,
} from "@ionic/react";
import { chevronBackSharp, closeSharp } from "ionicons/icons";
import { useContext, useState } from "react";
import { EnvContext } from "../../../lib/envcontext";
import UserSearch from "../../sitewide/UserSearch";
import { emailDisplayname } from "../../../lib/types";
interface AddUsersToConversationModalProps {
  ModalOpen: boolean;
  setModalOpen: (value: boolean) => void;
  id: string;
}

const AddUsersToConversationModal: React.FC<
  AddUsersToConversationModalProps
> = ({ ModalOpen, setModalOpen, id }) => {
  const router = useIonRouter();
  const { environment } = useContext(EnvContext);
  const [recipients, setRecipients] = useState([] as emailDisplayname[]);
  const [present] = useIonAlert();
  return (
    <IonModal
      onDidDismiss={() => {
        setModalOpen(false);
      }}
      isOpen={ModalOpen}
    >
      <IonToolbar>
        <IonButtons slot="start">
          <IonButton
            onClick={() => {
              setModalOpen(false);
            }}
          >
            <IonIcon icon={chevronBackSharp} />
          </IonButton>
        </IonButtons>
      </IonToolbar>
      <IonContent className="ion-padding">
        {/* {fullscreen} */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "50%",
            }}
          >
            <IonRow>
              {recipients &&
                recipients.map((recipient: emailDisplayname, index: number) => {
                  return (
                    <IonChip
                      key={index}
                      style={{
                        cursor: "default",
                      }}
                    >
                      <IonLabel>{recipient.displayname}</IonLabel>
                      <IonIcon
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setRecipients(
                            recipients.filter(
                              (r: emailDisplayname) =>
                                r.email !== recipient.email
                            )
                          );
                        }}
                        icon={closeSharp}
                      />
                    </IonChip>
                  );
                })}
            </IonRow>
            <IonButton
              id="createChat"
              disabled={recipients.length === 0}
              onClick={() => {
                for (let i = 0; i < recipients.length; i++) {
                  fetch(`${environment.backendURL}/open/user/addUserToChat`, {
                    method: "POST",
                    credentials: "include",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      email: recipients[i].email,
                      chatID: id,
                    }),
                  })
                    .then((res) => res.json())
                    .then((data) => {
                      if (data.errors) {
                        present({
                          message: data.errors[0].msg,
                          buttons: ["close"],
                        });
                      } else {
                        setModalOpen(false);
                        router.canGoBack()
                          ? router.goBack()
                          : router.push("/", "root");
                      }
                    });
                }
              }}
              fill="solid"
              style={{
                width: "100%",
              }}
            >
              Next
            </IonButton>
          </div>
          <UserSearch setUsers={setRecipients} users={recipients} />
        </div>
      </IonContent>
    </IonModal>
  );
};

export default AddUsersToConversationModal;
