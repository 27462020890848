import {
  IonAvatar,
  IonButton,
  IonCard,
  IonCardContent,
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonSkeletonText,
  IonCardHeader,
  IonIcon,
  useIonRouter,
  useIonViewDidEnter,
  useIonViewDidLeave,
  useIonAlert,
  IonProgressBar,
  useIonViewWillEnter,
  useIonViewWillLeave,
  IonBadge,
  IonTextarea,
} from "@ionic/react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import GenericBackToolbar from "../../components/sitewide/GenericBackToolbar";
import { EnvContext } from "../../lib/envcontext";
import { UserContext } from "../../lib/usercontext";
import UserItem from "../../components/sitewide/UserItem";
import {
  checkmarkSharp,
  closeSharp,
  ellipseSharp,
  ellipsisVerticalSharp,
} from "ionicons/icons";
import { ChatData, Participant } from "../../lib/types";
import RichMessage from "../../components/user/RichMessage";
import PhotoUpload from "../../components/sitewide/PhotoUpload";
import axios from "axios";
import "../../theme/standard.css";
import { NavBarVisibilityContext } from "../../lib/navbarvisibilitycontext";

const Conversation: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [messages, setMessages] = useState([] as Array<Number>);
  const messageRef = useRef(""); // Use a ref to store the message value
  const [participants, setParticipants] = useState([] as Array<Participant>);
  const { environment } = useContext(EnvContext);
  const { setNavbarVisibility } = useContext(NavBarVisibilityContext);
  const [chatData, setChatData] = useState({} as ChatData);
  const { user } = useContext(UserContext);
  const [photo, setPhoto] = useState([]);
  const [presentAlert] = useIonAlert();
  const [progress, setProgress] = useState<number>();
  const [isTyping, setIsTyping] = useState([] as Array<String>);
  const socketUrl = `${
    environment.backendURL.startsWith("https")
      ? environment.backendURL.replace("https", "wss")
      : environment.backendURL.replace("http", "ws")
  }/open/user/listenOnConversation/${id}`;
  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl);
  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];
  const router = useIonRouter();

  useEffect(() => {
    if (lastMessage) {
      const lastMessageData = JSON.parse(lastMessage?.data);
      console.log(lastMessageData);
      switch (lastMessageData.action) {
        case "message":
          fetch(`${environment.backendURL}/open/user/getchat/${id}`, {
            credentials: "include",
          })
            .then((res) => res.json())
            .then((data) => {
              setMessages(data.chatlog);
              setParticipants(data.participants);
              setChatData(data.chat);
            });
          break;
        case "typing":
          console.log(`
          ${lastMessageData.user} is typing
          `);
          // if the user is already in the list, don't add them again and set the timeout to 5 seconds, if typing again, reset the timeout
          if (lastMessageData.user !== user.user.email) {
            const displayname = participants?.filter(
              (person) => person.email === lastMessageData.user
            )[0].displayname;
            if (isTyping.includes(displayname)) {
              setIsTyping(
                isTyping.map((user) => {
                  if (user === displayname) {
                    return user;
                  } else {
                    return user;
                  }
                })
              );
            } else {
              setIsTyping([...isTyping, displayname]);
              setTimeout(() => {
                setIsTyping(isTyping.filter((user) => user !== displayname));
              }, 4000);
            }
          }
          break;
        case "participants":
          //if last message.participants contains the email of any of the participants set online to true
          const newParticipants = participants.map((participant) => {
            if (lastMessageData.participants.includes(participant.email)) {
              return { ...participant, online: true };
            } else {
              return { ...participant, online: false };
            }
          });
          setParticipants(newParticipants);
          break;
        case "disconnect":
          const newParticipants2 = participants.map((participant) => {
            if (lastMessageData.user === participant.email) {
              return { ...participant, online: false };
            } else {
              return { ...participant };
            }
          });
          setParticipants(newParticipants2);
          break;
        default:
          break;
      }
    }
  }, [id, lastMessage]);

  useEffect(() => {
    const func = () => {
      sendMessage(JSON.stringify({ action: "participants" }));
    };

    // Run func once after a 1-second delay
    const initialTimeout = setTimeout(() => {
      func();
    }, 1000);

    // Run func on an interval after the initial 1-second delay
    const interval = setInterval(() => {
      func();
    }, 10000);

    // Cleanup function to clear the interval when the component is unmounted
    return () => {
      clearTimeout(initialTimeout);
      clearInterval(interval);
    };
  }, []);

  useIonViewDidEnter(() => {
    fetch(`${environment.backendURL}/open/user/getchat/${id}`, {
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setMessages(data.chatlog);
        setParticipants(data.participants);
        setChatData(data.chat);
      });
  }, [id]);

  useEffect(() => {
    if (messageRef.current !== "") {
      sendMessage(JSON.stringify({ action: "typing", user: user.user.email }));
    }
  }, [messageRef.current]);

  useIonViewDidLeave(() => {
    setParticipants([]);
    setMessages([]);
    setChatData({} as ChatData);
  }, []);

  useIonViewWillEnter(() => {
    setNavbarVisibility(false);
  }, [setNavbarVisibility]);

  useIonViewWillLeave(() => {
    setNavbarVisibility(true);
  }, [setNavbarVisibility]);

  const handleMessageChange = (e: any) => {
    const value = e.detail.value!;
    messageRef.current = value;
  };

  return (
    <IonPage>
      <GenericBackToolbar
        // if more than 2 participants, show group chat, else show the other person's name (if there is one) or "Loading..."
        name={
          (chatData?.name
            ? chatData?.name
            : participants?.length > 2
            ? "Group Chat"
            : participants?.length === 2
            ? participants?.filter(
                (person: Participant) => person.email !== user.user.email
              )[0].displayname
            : "Loading...") + " "
        }
        button={
          <IonButton
            id="settings"
            onClick={() => {
              router.push(`/home/chat/${id}/settings`);
            }}
          >
            <IonIcon size="large" icon={ellipsisVerticalSharp} />
          </IonButton>
        }
      />
      <IonContent>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {participants !== null && (
            <IonCard
              className="mobileResize"
              style={{
                height: "85vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <IonCardHeader>
                <IonIcon
                  icon={ellipseSharp}
                  style={{
                    color: connectionStatus === "Open" ? "green" : "red",
                  }}
                />

                {participants !== null && (
                  <>
                    {participants && participants.length > 2 ? (
                      <IonRow>
                        {participants &&
                          participants
                            .filter(
                              (person: Participant) =>
                                person.email !== user.user.email
                            )
                            .map((user: Participant, index: number) => (
                              <div key={index}>
                                <IonAvatar>
                                  <img
                                    src={`${environment.storageURL}/photos/profile/${user.email}.png`}
                                    alt="avatar"
                                  />
                                </IonAvatar>
                              </div>
                            ))}
                      </IonRow>
                    ) : (
                      <div>
                        {participants &&
                          participants
                            .filter(
                              (person: Participant) =>
                                person.email !== user.user.email
                            )
                            .map((user: Participant, index: number) => (
                              <div key={index}>
                                <UserItem
                                  badge={
                                    user.online ? (
                                      <IonBadge color="success">
                                        <IonIcon icon={checkmarkSharp} />
                                      </IonBadge>
                                    ) : (
                                      <IonBadge color="light">
                                        <IonIcon icon={closeSharp}></IonIcon>
                                      </IonBadge>
                                    )
                                  }
                                  link={true}
                                  email={user.email}
                                />
                              </div>
                            ))}
                      </div>
                    )}
                  </>
                )}
              </IonCardHeader>
              <IonCardContent
                style={{
                  height: "70%",
                  display: "flex",
                }}
              >
                {messages === null ? (
                  <IonList
                    style={{
                      height: "100%",
                    }}
                  >
                    {" "}
                    {[...Array(5)].map((data, index) => (
                      <IonItem
                        key={index}
                        style={{
                          textAlign: "left",
                        }}
                      >
                        <IonAvatar slot="start">
                          <IonSkeletonText animated={true}></IonSkeletonText>
                        </IonAvatar>
                        <IonLabel>
                          <IonSkeletonText animated={true}></IonSkeletonText>
                        </IonLabel>
                      </IonItem>
                    ))}
                  </IonList>
                ) : (
                  <div
                    style={{
                      height: "100%",
                      overflowY: "scroll",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column-reverse",
                      overflow: "auto",
                    }}
                  >
                    <IonList
                      style={{
                        width: "100%",
                      }}
                    >
                      {messages?.length > 0 ? (
                        messages.map((message: any, index: number) =>
                          message === null ? (
                            <div key={index}></div>
                          ) : (
                            <RichMessage
                              key={index}
                              senderEmail={message.senderEmail}
                              messageID={message.messageID}
                              message={message.message}
                              timestamp={message.timestamp}
                              photo={message.photo}
                              reactions={message.reactions}
                            />
                          )
                        )
                      ) : (
                        <IonLabel>No messages</IonLabel>
                      )}
                    </IonList>
                  </div>
                )}
              </IonCardContent>
              {/* 
                  is typing
                */}
              {isTyping.length > 0 && (
                <IonRow
                  style={{
                    postion: "absolute",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <IonLabel>
                    {isTyping.length > 1
                      ? `${isTyping[0]} and ${
                          isTyping.length - 1
                        } others are typing...`
                      : `${isTyping[0]} is typing...`}
                  </IonLabel>
                </IonRow>
              )}
              <IonRow>
                <IonItem
                  style={{
                    width: "100%",
                  }}
                >
                  <IonTextarea
                    fill="solid"
                    value={messageRef.current}
                    onIonChange={handleMessageChange}
                    placeholder="Type a message"
                  />

                  <PhotoUpload
                    photo={photo}
                    setPhoto={setPhoto}
                    mode="anyMedia"
                    multiple={true}
                    iconOnly={true}
                  />
                  {(messageRef.current.length > 0 || photo.length > 0) && (
                    <IonButton
                      id="sendButton"
                      onClick={() => {
                        const formData = new FormData();
                        formData.append("message", messageRef.current);
                        formData.append("senderEmail", user.user.email);
                        photo.forEach((photo) => {
                          formData.append("sampleFile", photo);
                        });

                        axios
                          .post(
                            `${environment.backendURL}/open/user/sendmessage/${id}`,
                            formData,
                            {
                              withCredentials: true,
                              headers: {
                                "Content-Type": "multipart/form-data",
                              },

                              onUploadProgress: (progressEvent: any) => {
                                setProgress(
                                  Math.round(
                                    (progressEvent.loaded /
                                      progressEvent.total) *
                                      100
                                  )
                                );
                              },
                            }
                          )
                          .then((res) => {
                            if (res.status !== 200) {
                              presentAlert({
                                header: "Error",
                                message: "Something went wrong",
                                buttons: ["OK"],
                              });
                              console.log(res);
                            } else {
                              messageRef.current = ""; // Clear the message ref
                              setPhoto([]);
                              setProgress(undefined);
                              sendMessage(
                                JSON.stringify({
                                  action: "message",
                                })
                              );
                            }
                          });
                      }}
                    >
                      Send
                    </IonButton>
                  )}
                </IonItem>
              </IonRow>
              <IonRow>
                {progress && (
                  <>
                    <IonProgressBar value={progress / 100}></IonProgressBar>
                  </>
                )}
              </IonRow>
            </IonCard>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Conversation;
