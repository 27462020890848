import {
  IonButton,
  IonButtons,
  IonContent,
  IonIcon,
  IonItem,
  IonModal,
  IonText,
  IonToolbar,
  useIonAlert,
  useIonRouter,
} from "@ionic/react";
import { chevronBackSharp } from "ionicons/icons";
import { useContext } from "react";
import { EnvContext } from "../../../lib/envcontext";

interface OwnerPostModalProps {
  setOwnerModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setEditModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setInvisible: React.Dispatch<React.SetStateAction<boolean>>;
  ownerModalOpen: boolean;
  postID: string;
}

const OwnerPostModal: React.FC<OwnerPostModalProps> = ({
  setOwnerModalOpen,
  setEditModalOpen,
  setInvisible,
  ownerModalOpen,
  postID,
}) => {
  const router = useIonRouter();
  const [presentAlert] = useIonAlert();
  const { environment } = useContext(EnvContext);

  return (
    <IonModal
      onDidDismiss={() => {
        setOwnerModalOpen(false);
      }}
      isOpen={ownerModalOpen}
    >
      <IonToolbar>
        <IonButtons slot="start">
          <IonButton
            onClick={() => {
              setOwnerModalOpen(false);
            }}
          >
            <IonIcon icon={chevronBackSharp} />
          </IonButton>
        </IonButtons>
      </IonToolbar>
      <IonContent className="ion-padding">
        {/* {fullscreen} */}
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <IonItem
            id="menuModalEdit"
            onClick={() => {
              setEditModalOpen(true);
              setOwnerModalOpen(false);
            }}
            button
          >
            <IonText>Edit</IonText>
          </IonItem>
          <IonItem
            id="menuModalGoToPost"
            onClick={() => {
              setOwnerModalOpen(false);
              router.push(`/post/${postID}`);
            }}
            button
          >
            <IonText>Go to post</IonText>
          </IonItem>

          <IonItem
            id="menuModalDelete"
            button
            onClick={() => {
              presentAlert({
                header: "Delete post",
                message: "Are you sure you want to delete this post?",
                buttons: [
                  {
                    text: "Cancel",
                    role: "cancel",
                    cssClass: "secondary",
                  },
                  {
                    text: "Okay",
                    handler: () => {
                      fetch(
                        `${environment.backendURL}/open/socialmedia/deletePost/${postID}`,
                        {
                          method: "DELETE",
                          credentials: "include",
                        }
                      )
                        .then((res) => res.json())
                        .then(() => {
                          setInvisible(true);
                          setOwnerModalOpen(false);
                        });
                    },
                  },
                ],
              });
            }}
          >
            <IonText color={"danger"}>Delete</IonText>
          </IonItem>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default OwnerPostModal;
