import {
  IonButton,
  IonCard,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonItemDivider,
  IonPage,
  IonProgressBar,
  IonRow,
  IonText,
  useIonAlert,
  useIonRouter,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import GenericBackToolbar from "../../components/sitewide/GenericBackToolbar";
import { NavBarVisibilityContext } from "../../lib/navbarvisibilitycontext";
import { useContext, useState } from "react";
import PhotoUpload from "../../components/sitewide/PhotoUpload";
import TagSelector from "../../components/sitewide/TagSelector";
import { UserContext } from "../../lib/usercontext";
import { EnvContext } from "../../lib/envcontext";
import SmartTextField from "../../components/sitewide/SmartTextField";
import axios from "axios";

const NewAdCampaign: React.FC = () => {
  interface form {
    postTitle: string;
    description: string;
    photoURL: string;
    tags: string[];
    link: string;
  }
  interface errors {
    postTitle: boolean;
    description: boolean;
    tags: boolean;
    link: boolean;
  }
  const router = useIonRouter();
  const [presentAlert] = useIonAlert();
  const { environment } = useContext(EnvContext);
  const [form, setForm] = useState({} as form);
  const [photo, setPhoto] = useState([]);
  const { user } = useContext(UserContext);
  const [progress, setProgress] = useState<number>();
  const [errors, setErrors] = useState({} as errors);
  const { setNavbarVisibility } = useContext(NavBarVisibilityContext);
  useIonViewWillEnter(() => {
    setNavbarVisibility(false);
  }, [setNavbarVisibility]);
  useIonViewWillLeave(() => {
    setNavbarVisibility(true);
  }, [setNavbarVisibility]);
  return (
    <IonPage>
      <IonContent fullscreen>
        <GenericBackToolbar name="" />
        <>
          <IonCard
            style={{
              margin: "10px",
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <IonGrid>
              <IonRow>
                <IonText>
                  <h1>New Campaign</h1>
                  <p>lets collect some basic information about your campaign</p>
                </IonText>
              </IonRow>
              <IonRow>
                <IonItem>
                  <IonInput
                    className={`${!errors.postTitle && "ion-valid"} ${
                      errors.postTitle && "ion-invalid"
                    } ${"ion-touched"}`}
                    debounce={500}
                    name="postTitle"
                    onIonChange={(e: any) => {
                      if (
                        (e.detail.value.length > 3 &&
                          e.detail.value.length < 100) ||
                        e.detail.value === ""
                      ) {
                        setErrors({ ...errors, postTitle: false });
                        setForm({ ...form, postTitle: e.detail.value });
                      } else {
                        setErrors({ ...errors, postTitle: true });
                        setForm({ ...form, postTitle: "" });
                      }
                    }}
                    label="Campaign Title"
                    labelPlacement="floating"
                    errorText="Must have more than 3 characters"
                  />
                </IonItem>
              </IonRow>
              <br />
              <IonRow>
                <IonItem>
                  <SmartTextField
                    errorText="Must have more than 3 characters"
                    text={form.description}
                    setText={(text) => {
                      setForm({ ...form, description: text });
                    }}
                    setError={(error) => {
                      setErrors({ ...errors, description: error });
                      setForm({ ...form, description: "" });
                    }}
                    error={errors.description}
                    rules={/^(?=.{4,999}$)([\s\S]*)|^$/}
                    debounce={500}
                    placeholder="Campaign Description"
                    name="description"
                  />
                </IonItem>
              </IonRow>
              <IonItemDivider />
              <IonText>
                <p>
                  You can optionaly set a link that users will be directed to
                  when they click on your ad format must be www.example.com
                </p>
              </IonText>
              <IonItem>
                <IonInput
                  className={`${!errors.link && "ion-valid"} ${
                    errors.link && "ion-invalid"
                  } ${"ion-touched"}`}
                  debounce={500}
                  label="Link"
                  labelPlacement="floating"
                  errorText="Must be a valid URL (https://example.com)"
                  name="link"
                  onIonChange={(e: any) => {
                    const absoluteHttpHttpsUrlRegex =
                      /^https?:\/\/(?:[^./?#]+\.)?[^./?#]+(?:\.[^./?#]+)*(?:\/[^./?#]*(?:\.[^./?#]+)*(?:[?#][^./?#]+)?)?$/i;

                    if (
                      e.detail.value.match(absoluteHttpHttpsUrlRegex) ||
                      e.detail.value === ""
                    ) {
                      setErrors({ ...errors, link: false });
                      setForm({ ...form, link: e.detail.value });
                    } else {
                      setErrors({ ...errors, link: true });
                      setForm({ ...form, link: "" });
                    }
                  }}
                />
              </IonItem>
              <IonItemDivider />

              <IonText>
                <p>Tags help people find your campaign</p>
                <p>
                  we show your campaign to people who are interested in your
                  tags
                </p>
              </IonText>
              <TagSelector
                form={true}
                tags={form.tags}
                setTags={(tags) => {
                  setForm({ ...form, tags: tags });
                }}
                activeOnly={true}
              />
              <IonItemDivider />
              {!progress && (
                <>
                  <IonText>
                    <p>Photos are the most important part of your campaign</p>
                    <p>
                      we recommend you upload at least 3 photos, but you can
                      upload as many as you want
                    </p>
                  </IonText>
                  <PhotoUpload
                    photo={photo}
                    setPhoto={setPhoto}
                    mode="anyMedia"
                    multiple={true}
                  />
                  <IonItemDivider />
                  {/* t&c */} <br />
                  <IonText>
                    <>by publishing this campaign, you agree to our </>
                    <a
                      style={{
                        cursor: "pointer",
                      }}
                      href="/Terms"
                      target="_blank"
                    >
                      terms and conditions
                    </a>
                    <> also available as a </>
                    <a
                      style={{
                        cursor: "pointer",
                      }}
                      href="assets/website-advertising-agreementV2.pdf"
                      target="_blank"
                    >
                      pdf
                    </a>
                  </IonText>
                  <br />
                  <br />
                  <IonButton
                    disabled={
                      !form.postTitle ||
                      !form.description ||
                      !form.tags ||
                      !photo ||
                      errors.postTitle ||
                      errors.description ||
                      errors.link ||
                      photo.length === 0
                    }
                    onClick={async () => {
                      const formData = new FormData();
                      // for each photo, append it to the form data
                      photo.forEach((photo) => {
                        formData.append("sampleFile", photo);
                      });
                      formData.append("email", user.user.email);
                      formData.append("title", form.postTitle);
                      formData.append("description", form.description);
                      formData.append("link", form.link);
                      formData.append(
                        "tags",
                        form.tags ? JSON.stringify(form.tags) : "[]"
                      );

                      axios
                        .post(
                          `${environment.backendURL}/open/socialmedia/publishadpost`,
                          formData,
                          {
                            withCredentials: true,
                            headers: {
                              "Content-Type": "multipart/form-data",
                            },

                            onUploadProgress: (progressEvent: any) => {
                              console.log(
                                "Upload Progress: " +
                                  Math.round(
                                    (progressEvent.loaded /
                                      progressEvent.total) *
                                      100
                                  ) +
                                  "%"
                              );
                              setProgress(
                                Math.round(
                                  (progressEvent.loaded / progressEvent.total) *
                                    100
                                )
                              );
                            },
                          }
                        )
                        .then((res) => {
                          // dismiss();
                          if (res.status !== 200) {
                            presentAlert({
                              header: "Error",
                              message: "Something went wrong",
                              buttons: ["OK"],
                            });
                            console.log(res);
                          } else {
                            //push to campaign manager
                            router.push(
                              "/Advertising/ManageCampaigns/" + res.data.postID
                            );
                          }
                        });
                    }}
                  >
                    Create Campaign
                  </IonButton>
                  <IonText>
                    <p>
                      this will not be visible to anyone until you have sent it
                      live in the campaign manager
                    </p>
                  </IonText>
                </>
              )}
              {progress && (
                <>
                  <br />
                  <IonProgressBar value={progress / 100}></IonProgressBar>
                </>
              )}
            </IonGrid>
          </IonCard>{" "}
        </>
      </IonContent>
    </IonPage>
  );
};

export default NewAdCampaign;
