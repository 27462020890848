import { IonButton, IonCol, IonText, useIonRouter } from "@ionic/react";
import { useState } from "react";
import { LinkIt, LinkItUrl } from "react-linkify-it";

interface RichTextProps {
  text: string;
  truncate?: boolean;
}

const RichText: React.FC<RichTextProps> = ({ text, truncate }) => {
  const router = useIonRouter();
  const [showMore, setShowMore] = useState(false);
  return (
    <>
      <LinkIt
        component={(match, key) => (
          <a
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              router.push(`/home/profile/${match.slice(1)}`);
            }}
            key={key}
          >
            {match}
          </a>
        )}
        // match @ then a username
        regex={/@\w+/g}
      >
        <LinkItUrl>
          {text?.length > 80 && truncate ? (
            //implement a show more button
            <>
              {showMore ? (
                <IonCol>
                  <IonText color={"dark"}>{text}</IonText>
                  <br />
                  <IonButton
                    fill="clear"
                    size="small"
                    color={"dark"}
                    onClick={() => setShowMore(false)}
                  >
                    Show Less
                  </IonButton>
                </IonCol>
              ) : (
                <IonCol>
                  <IonText color={"dark"}>{text.slice(0, 80)}...</IonText>
                  <br />
                  <IonButton
                    fill="clear"
                    size="small"
                    color={"dark"}
                    onClick={() => setShowMore(true)}
                  >
                    Show More
                  </IonButton>
                </IonCol>
              )}
            </>
          ) : (
            <IonText color={"dark"}>{text}</IonText>
          )}
        </LinkItUrl>
      </LinkIt>
    </>
  );
};

export default RichText;
