import {
  IonButton,
  IonButtons,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonToolbar,
  useIonRouter,
  useIonViewWillEnter,
} from "@ionic/react";
import { chevronBackSharp } from "ionicons/icons";
import { useContext, useState } from "react";
import { UserContext } from "../../lib/usercontext";
import { EnvContext } from "../../lib/envcontext";
import AddUsersToConversationModal from "../../components/user/Modal/AddUsersToConversationModal";
import UserItem from "../../components/sitewide/UserItem";
import { useParams } from "react-router";
import { Participant } from "../../lib/types";
const ConversationSettings = () => {
  const { user } = useContext(UserContext);
  const router = useIonRouter();
  const { environment } = useContext(EnvContext);
  const { id } = useParams<{ id: string }>();
  const [addUsersModalOpen, setAddUsersModalOpen] = useState(false);
  const [participants, setParticipants] = useState([] as Array<Participant>);

  useIonViewWillEnter(() => {
    fetch(`${environment.backendURL}/open/user/getChat/${id}`, {
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        setParticipants(data.participants);
      });
  }, []);
  return (
    <>
      <AddUsersToConversationModal
        ModalOpen={addUsersModalOpen}
        setModalOpen={setAddUsersModalOpen}
        id={id}
      />
      <IonPage>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              onClick={() => {
                router.canGoBack() ? router.goBack() : router.push("/Home");
              }}
            >
              <IonIcon icon={chevronBackSharp} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonContent className="ion-padding">
          {/* {fullscreen} */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {participants?.length > 2 ? (
              <div>
                <IonRow
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <h2>Members</h2>
                  <IonButton
                    onClick={() => {
                      setAddUsersModalOpen(true);
                    }}
                    fill="clear"
                  >
                    Add People
                  </IonButton>
                </IonRow>
                {participants !== null &&
                  participants.map((user: Participant, index: number) => {
                    return (
                      <UserItem key={index} email={user.email} link={true} />
                    );
                  })}
                <br />
                <IonItem
                  onClick={() => {
                    fetch(
                      `${environment.backendURL}/open/user/removeUserFromChat`,
                      {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        credentials: "include",
                        body: JSON.stringify({
                          email: user.user.email,
                          chatID: id,
                        }),
                      }
                    )
                      .then((res) => res.json())
                      .then((data) => {
                        if (data.error === false) {
                          router.push("/home/messages");
                        }
                      });
                  }}
                  button
                  color={"danger"}
                >
                  <IonLabel>Leave Conversation</IonLabel>
                </IonItem>
              </div>
            ) : (
              <IonItem
                onClick={() => {
                  fetch(
                    `${environment.backendURL}/open/user/removeUserFromChat`,
                    {
                      method: "POST",
                      credentials: "include",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({
                        email: user.user.email,
                        chatID: id,
                      }),
                    }
                  )
                    .then((res) => res.json())
                    .then((data) => {
                      if (data.error === false) {
                        router.push("/home/messages");
                      }
                    });
                }}
                button
                color={"danger"}
              >
                <IonLabel>Leave Conversation</IonLabel>
              </IonItem>
            )}
          </div>
        </IonContent>
      </IonPage>
    </>
  );
};

export default ConversationSettings;
