import {
  IonContent,
  IonLoading,
  IonPage,
  IonText,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import { useContext } from "react";
import PostMinInfin from "../../components/socialMedia/PostMinInfin";
import GenericBackToolbar from "../../components/sitewide/GenericBackToolbar";
import { SavedPostContext } from "../../lib/savedpostcontext";
import { NavBarVisibilityContext } from "../../lib/navbarvisibilitycontext";
import "../../theme/standard.css";
const SavedPosts: React.FC = () => {
  const { savedPosts } = useContext(SavedPostContext);
  const { setNavbarVisibility } = useContext(NavBarVisibilityContext);

  useIonViewWillEnter(() => {
    setNavbarVisibility(false);
  }, [setNavbarVisibility]);
  useIonViewWillLeave(() => {
    setNavbarVisibility(true);
  }, [setNavbarVisibility]);
  return (
    <IonPage>
      <IonContent fullscreen>
        <GenericBackToolbar name="Saved Posts" />
        {savedPosts === null ? (
          <IonLoading isOpen={true} />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "1em",
            }}
          >
            <div className="mobileResize">
              {savedPosts?.length > 0 ? (
                <PostMinInfin posts={savedPosts} />
              ) : (
                <IonText>
                  <IonText>
                    <h2>
                      you haven't saved anything yet, click the little bookmark
                      below a post you like and it will show up here
                    </h2>
                  </IonText>
                </IonText>
              )}
            </div>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default SavedPosts;
