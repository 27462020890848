import { Redirect, Route } from "react-router-dom";
import {
  IonApp,
  IonIcon,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import {
  storefrontSharp,
  searchSharp,
  peopleCircleSharp,
  businessSharp,
  briefcaseSharp,
} from "ionicons/icons";
import Home from "./pages/socialMedia/Home";
import Search from "./pages/sitewide/Search";
import Marketplace from "./pages/marketplace/Marketplace";
import RealEstate from "./pages/realEstate/RealEstate";
import JobBoard from "./pages/jobs/JobBoard";
import { UserContext } from "./lib/usercontext";
import { EnvContext } from "./lib/envcontext";
import { SavedPostContext } from "./lib/savedpostcontext";
import { NavBarVisibilityContext } from "./lib/navbarvisibilitycontext";
import { BlockingsContext } from "./lib/blockingscontextprovider";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import Profile from "./pages/user/Profile";
import { useEffect, useState } from "react";
import CreatePost from "./pages/socialMedia/CreatePost";
import Login from "./pages/sitewide/Login";
import SignUp from "./pages/sitewide/SignUp";
import CreateBusinessAccount from "./pages/user/CreateBusinessAccount";
import EditProfile from "./pages/user/EditProfile";
import PrivateRoute from "./components/sitewide/PrivateRoute";
import EditBusiness from "./pages/user/EditBusiness";
import Post from "./pages/socialMedia/Post";
import Relationships from "./pages/socialMedia/Relationships";
import SavedPosts from "./pages/socialMedia/SavedPosts";
import MyAccounts from "./pages/user/MyAccounts";
import Conversation from "./pages/user/Conversation";
import CreateConversation from "./pages/user/CreateConversation";
import MyMessages from "./pages/user/MyMessages";
import ConversationSettings from "./pages/user/ConversationSettings";
import SharePost from "./pages/socialMedia/SharePost";
import Tag from "./pages/sitewide/Tag";
import AdvertisingOnboard from "./pages/socialMedia/AdvertisingOnboard";
import NewAdCampaign from "./pages/socialMedia/NewAdCampaign";
import ManageCampaigns from "./pages/socialMedia/ManageCampaigns";
import Campaign from "./pages/socialMedia/Campaign";
import { UserContextInterface, EnvContextInterface } from "./lib/types";
import Splash from "./pages/sitewide/Splash";
import BusinessLogin from "./pages/user/BusinessLogin";
import Moderation from "./pages/sitewide/Moderation";
import TagManagement from "./pages/sitewide/TagManagement";
import PasswordReset from "./pages/sitewide/PasswordReset";
import Privacy from "./pages/sitewide/Privacy";
import Cookies from "./pages/sitewide/Cookies";
import Terms from "./pages/sitewide/Terms";
import AdvertisingTerms from "./pages/sitewide/AdvertisingTerms";
import HomeToolbar from "./components/sitewide/HomeToolbar";
import { ToolBarVisibilityContext } from "./lib/toolbarvisibilitycontext";
import JobProfile from "./pages/jobs/JobProfile";
import CreateJobProfile from "./pages/jobs/CreateJobProfile";
import EditJobProfile from "./pages/jobs/EditJobProfile";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import EULA from "./pages/sitewide/EULA";
import Contact from "./pages/sitewide/Contact";

setupIonicReact();

const App = () => {
  //this is here because in the website build process we change the localhost values to the production values somewhere in the docker process,
  // this doesn't happen in the app build process so we need to do it here
  //change it back to the localhost values for local testing or after the ios build process

  const [environment, setEnvironment] = useState<EnvContextInterface>({
    backendURL: "https://backend.renosandrebuilds.com",
    storageURL: "https://storage.renosandrebuilds.com",
  });
  // const [environment, setEnvironment] = useState<EnvContextInterface>({
  //   backendURL: "https://backend.renosandrebuilds.com",
  //   storageURL: "https://storage.renosandrebuilds.com",
  // });

  //stopgap !!!bad some strange quirk of the build process was including the # in the backendURL, this is a stopgap until I can figure out why
  // useEffect(() => {
  //   if (
  //     environment.backendURL === "https://test-storage.renosandrebuilds.com#"
  //   ) {
  //     setEnvironment({
  //       backendURL: "https://test-backend.renosandrebuilds.com",
  //       storageURL: "https://test-storage.renosandrebuilds.com",
  //     });
  //   }
  // }, [environment]);
  const [user, setUser] = useState({} as UserContextInterface);
  const [navbarVisibility, setNavbarVisibility] = useState<boolean>(true);
  const [toolbarVisibility, setToolbarVisibility] = useState<boolean>(false);
  const [savedPosts, setSavedPosts] = useState<string[]>([]);
  const [blockings, setBlockings] = useState<string[]>([]);

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      fetch(`${environment.backendURL}/open/socialmedia/getsavedposts`, {
        credentials: "include",
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.error) {
            console.log(data);
          } else {
            setSavedPosts(data);
          }
        });
    }
  }, [user, environment]);
  useEffect(() => {
    if (Object.keys(user).length > 0) {
      fetch(`${environment.backendURL}/open/user/retrieveProfile/blockings`, {
        credentials: "include",
      })
        .then((res) => res.json())
        .then((data) => {
          setBlockings(data);
        });
    }
  }, [user, environment]);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
      }}
    >
      <EnvContext.Provider value={{ environment, setEnvironment }}>
        <SavedPostContext.Provider value={{ savedPosts, setSavedPosts }}>
          <NavBarVisibilityContext.Provider
            value={{
              navbarVisibility,
              setNavbarVisibility,
            }}
          >
            <ToolBarVisibilityContext.Provider
              value={{
                toolbarVisibility,
                setToolbarVisibility,
              }}
            >
              <BlockingsContext.Provider
                value={{
                  blockings,
                  setBlockings,
                }}
              >
                <IonApp className="app">
                  <IonReactRouter>
                    {toolbarVisibility ? <HomeToolbar /> : null}

                    <IonTabs>
                      <IonRouterOutlet>
                        <Route exact path="/account/createBusinessAccount/:id">
                          <PrivateRoute component={<CreateBusinessAccount />} />
                        </Route>
                        <Route exact path="/Account/editProfile">
                          <PrivateRoute component={<EditProfile />} />
                        </Route>
                        <Route exact path="/Account/editBusiness">
                          <PrivateRoute component={<EditBusiness />} />
                        </Route>
                        <Route exact path="/Login">
                          <Login />
                        </Route>
                        <Route exact path="/Signup/:id">
                          <SignUp />
                        </Route>
                        <Route exact path="/resetPassword">
                          <PasswordReset />
                        </Route>
                        <Route exact path="/Home/messages">
                          <PrivateRoute component={<MyMessages />} />
                        </Route>
                        <Route exact path="/Moderation">
                          <PrivateRoute component={<Moderation />} />
                        </Route>
                        <Route exact path="/TagManagement">
                          <PrivateRoute component={<TagManagement />} />
                        </Route>
                        <Route exact path="/Home/messages/newchat">
                          <PrivateRoute component={<CreateConversation />} />
                        </Route>
                        <Route exact path="/Home/chat/:id">
                          <PrivateRoute component={<Conversation />} />
                        </Route>
                        <Route exact path="/Home/chat/:id/settings">
                          <PrivateRoute component={<ConversationSettings />} />
                        </Route>
                        <Route exact path="/Home/Profile/:id">
                          <PrivateRoute component={<Profile />} />
                        </Route>
                        <Route exact path="/Home/Profile/:id/relationships">
                          <PrivateRoute component={<Relationships />} />
                        </Route>
                        <Route exact path="/Home/Profile/:id/myaccounts">
                          <PrivateRoute component={<MyAccounts />} />
                        </Route>
                        <Route exact path="/businessLogin/:id">
                          <PrivateRoute component={<BusinessLogin />} />
                        </Route>
                        <Route exact path="/Home/Profile/:id/saved">
                          <PrivateRoute component={<SavedPosts />} />
                        </Route>
                        <Route exact path="/Home">
                          <PrivateRoute component={<Home />} />
                        </Route>
                        <Route exact path="/Home/CreatePost">
                          <PrivateRoute component={<CreatePost />} />
                        </Route>
                        <Route exact path="/Advertising/onboard">
                          <PrivateRoute component={<AdvertisingOnboard />} />
                        </Route>
                        <Route exact path="/Advertising/NewCampaign">
                          <PrivateRoute component={<NewAdCampaign />} />
                        </Route>
                        <Route exact path="/Advertising/ManageCampaigns">
                          <PrivateRoute component={<ManageCampaigns />} />
                        </Route>
                        <Route exact path="/Advertising/ManageCampaigns/:id">
                          <PrivateRoute component={<Campaign />} />
                        </Route>
                        {/* <Route exact path="/Advertising/ManageCampaigns/:id/edit"> */}

                        <Route exact path="/post/:id">
                          <PrivateRoute component={<Post />} />
                        </Route>
                        <Route exact path="/post/:id/share">
                          <PrivateRoute component={<SharePost />} />
                        </Route>
                        <Route exact path="/Search">
                          <PrivateRoute component={<Search />} />
                        </Route>
                        <Route exact path="/Tag/:id">
                          <PrivateRoute component={<Tag />} />
                        </Route>
                        <Route exact path="/Marketplace">
                          <PrivateRoute component={<Marketplace />} />
                        </Route>
                        <Route exact path="/RealEstate">
                          <PrivateRoute component={<RealEstate />} />
                        </Route>
                        <Route path="/JobBoard">
                          <PrivateRoute component={<JobBoard />} />
                        </Route>
                        <Route exact path="/JobBoard/Profile/:id">
                          <PrivateRoute component={<JobProfile />} />
                        </Route>
                        <Route
                          exact
                          path="/JobBoard/Profile/:id/editjobProfile"
                        >
                          <PrivateRoute component={<EditJobProfile />} />
                        </Route>
                        <Route exact path="/JobBoard/createProfile">
                          <PrivateRoute component={<CreateJobProfile />} />
                        </Route>
                        <Route exact path="/">
                          <Splash />
                        </Route>
                        <Route exact path="/Privacy">
                          <Privacy />
                        </Route>
                        <Route exact path="/Terms">
                          <Terms />
                        </Route>
                        <Route exact path="/Cookies">
                          <Cookies />
                        </Route>
                        <Route exact path="/EULA">
                          <EULA />
                        </Route>
                        <Route exact path="/Contact">
                          <Contact />
                        </Route>
                        <Route exact path="/AdvertisingTerms">
                          <AdvertisingTerms />
                        </Route>
                        <Route>
                          <Redirect to="/Home" />
                        </Route>
                      </IonRouterOutlet>

                      <IonTabBar
                        style={{
                          display: navbarVisibility ? "" : "none",
                        }}
                        slot="bottom"
                        color={"light"}
                      >
                        <IonTabButton tab="Home" href="/Home">
                          <IonIcon
                            title="Social Media"
                            aria-label="Social Media"
                            icon={peopleCircleSharp}
                          />
                        </IonTabButton>
                        <IonTabButton tab="JobBoard" href="/JobBoard">
                          <IonIcon
                            title="JobBoard"
                            aria-label="JobBoard"
                            icon={briefcaseSharp}
                          />
                        </IonTabButton>{" "}
                        <IonTabButton tab="Marketplace" href="/Marketplace">
                          <IonIcon
                            title="Marketplace"
                            aria-label="Marketplace"
                            icon={storefrontSharp}
                          />
                        </IonTabButton>
                        <IonTabButton tab="RealEstate" href="/RealEstate">
                          <IonIcon
                            title="RealEstate"
                            aria-label="RealEstate"
                            icon={businessSharp}
                          />
                        </IonTabButton>
                        <IonTabButton tab="Search" href="/Search">
                          <IonIcon
                            title="Search"
                            aria-label="Search"
                            icon={searchSharp}
                          />
                        </IonTabButton>
                      </IonTabBar>
                    </IonTabs>
                  </IonReactRouter>
                </IonApp>
              </BlockingsContext.Provider>
            </ToolBarVisibilityContext.Provider>
          </NavBarVisibilityContext.Provider>
        </SavedPostContext.Provider>
      </EnvContext.Provider>
    </UserContext.Provider>
  );
};
serviceWorkerRegistration.register();

export default App;
